import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { SedcardFormComponent } from "src/app/components/sedcard-form/sedcard-form.component";
import { AutocompleteModule } from "../autocomplete/autocomplete.module";

@NgModule({
    declarations: [SedcardFormComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        IonicModule,
        AutocompleteModule,
    ],
    exports: [SedcardFormComponent]
})
export class SedcardModule {}
