import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AutocompleteComponent } from "src/app/components/autocomplete/autocomplete.component";
import { IonicModule } from "@ionic/angular";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

@NgModule({
  declarations: [AutocompleteComponent],
  imports: [CommonModule, IonicModule, ReactiveFormsModule, FormsModule],
  exports: [AutocompleteComponent],
})
export class AutocompleteModule {}
