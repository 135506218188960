import { Component, OnInit, ViewChild } from "@angular/core";
import {
  Job,
  AdminService,
  Position,
  RealWorktime,
  Application,
  Timesheet,
  UserTimesheetDifference,
} from "src/app/services/admin/admin.service";
import { Router, ActivatedRoute } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { ToastService } from "src/app/services/toast/toast.service";
import * as moment from "moment";
import { NavController, LoadingController, Platform, AlertController } from "@ionic/angular";
import { Partner } from "src/app/classes/Partner";
import { ModalController } from '@ionic/angular';
import { SelectModalComponent } from "../../../components/select-modal/select-modal.component"

@Component({
  selector: "app-addjob",
  templateUrl: "./addjob.page.html",
  styleUrls: ["./addjob.page.scss"],
})
export class AddjobPage implements OnInit {
  @ViewChild("addresstext", { static: false }) set content(content: any) {
    if (content) {
      // initially setter gets called with undefined
      this.addresstext = content;
      this.getPlaceAutocomplete();
    }
  }
  jobeditForm: UntypedFormGroup;
  public fields: string[] = [];
  public posfields: string[] = [];
  public job: Job;
  public names = {};
  public answers: Map<string, string> = new Map();
  public canEdit: string = null;
  allPartners: Partner[] = [];
  isInMarket: boolean = false;
  loadingPartners = false;
  finallyAcceptedApplications: Application[] = [];
  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private navCtrl: NavController,
    public loadingCtrl: LoadingController,
    public platform: Platform,
    private alertController: AlertController,
    private modalController: ModalController
  ) {
    fetch("./assets/lang/de.json")
      .then((res) => res.json())
      .then((json) => {
        this.names = json;
        console.log("NAMESjson", this.names);
      });

    this.route.queryParams.subscribe((params) => {
      console.log("ADDJOB: ", params);
      console.log("ADDJOB: ", this.router.getCurrentNavigation().extras.state);
      if (this.router.getCurrentNavigation().extras.state) {
        this.job = this.router.getCurrentNavigation().extras.state.job;
        this.canEdit = this.router.getCurrentNavigation().extras.state.canEdit;
        this.isInMarket = this.router.getCurrentNavigation().extras.state.isInMarket;
        this.finallyAcceptedApplications = this.router.getCurrentNavigation().extras.state.finallyAcceptedApplications;
        console.log("I RECEIVED THIS JOB:", this.job);
        try {
          this.initJobEditForm();
        } catch (e) {}
        console.log("ADDJOBX: ", this.jobeditForm);
        this.jobeditForm.valueChanges.subscribe(console.log);
        this.initAllPositions();
      } else {
        this.router.navigateByUrl("jobtabs");
      }
    });
    let p = new Partner();
    p.contactname = "Florian Shopleiter - PArtner wurden noch nicht geladen.";
    p.contactphone = "+4917568789522";
    p.pname = "planetLan GmbH";
    this.allPartners.push(p);
  }
  selectDisabled = true;

  private addresstext: any;

  private async getPlaceAutocomplete() {
    let a = await this.addresstext.getInputElement();
    const autocomplete = new google.maps.places.Autocomplete(await a, {
      componentRestrictions: { country: "DEU" },
      types: ["geocode"], // 'establishment' / 'address' / 'geocode'
    });
    google.maps.event.addListener(autocomplete, "place_changed", () => {
      const place = autocomplete.getPlace();

      console.log("PLACE:", place);
      this.addresstext.value = place["formatted_address"];
    });
  }

  erikOrNico(e) {
    if (e.detail.value === "Erik") {
      this.internalcontact.setValue("Erik");
      this.internalcontactphone.setValue("+4917670743829");
    } else {
      this.internalcontact.setValue("Nico");
      this.internalcontactphone.setValue("+4917670744101");
    }
  }
  async presentPartnerSelectModal() {
    if (this.selectDisabled) {
      this.loadingPartners = true;
  
      await this.initAllPartners();
      this.loadingPartners = false; 
    }
  
    if (!this.selectDisabled && this.allPartners.length > 0) {
      const modal = await this.modalController.create({
        component: SelectModalComponent,
        componentProps: {
          title: 'Partner auswählen',
          items: this.allPartners.map(p => `${p.pname} - ${p.contactname}`),
                }
      });
  
      modal.onDidDismiss().then((data) => {
        if (data.data) {
          this.partnerPickHandler(data.data.selectedItem);
        }
      });
  
      return await modal.present();
    } else {
      console.log('Partner wurden noch nicht geladen.');
    }
  }
  
  partnerPickHandler(selectedItem: string) {
    const selectedPartner = this.allPartners.find(p => `${p.pname} - ${p.contactname}` === selectedItem);
    if (selectedPartner) {
      this.partner.setValue(selectedPartner.pname);
      this.contact.setValue(selectedPartner.contactname);
      this.contactphone.setValue(selectedPartner.contactphone);
      this.partneraddress.setValue(selectedPartner.partneraddress);
    }
  }  
  // partnerPickHandler(e) {
  //   console.log(e); //e.detail.value == partner
  //   this.partner.setValue(e.detail.value.pname);
  //   this.contact.setValue(e.detail.value.contactname);
  //   this.contactphone.setValue(e.detail.value.contactphone);
  //   this.partneraddress.setValue(e.detail.value.partneraddress);
  // }
  initAllPartners() {
    if (this.selectDisabled) {
      this.loadingPartners = true;
      this.adminService.getAllPartners().then((ps) => {
        this.loadingPartners = false;
        console.log(ps);
        this.allPartners = ps;
        this.selectDisabled = false;
      }).catch(error => {
        console.error('Error loading partners:', error);
        this.loadingPartners = false;
      });
    }
  }
  saveNewPartner() {
    let p: Partner = new Partner();
    p.init(this.partner.value, this.contact.value, this.contactphone.value, this.partneraddress.value);
    console.log("P", p);
    this.adminService
      .saveNewPartner(p)
      .then(() => {
        this.allPartners.push(p);
        ToastService.makeToast(`Partner ${this.partner.value} gespeichert.`);
      })
      .catch(() => {
        ToastService.makeToast(`Speichern nicht möglich`);
      });
  }
  saveStarttime(s) {
    this.starttime.setValue(s.split("T")[0]);
    console.log("ENDTIME", this.endtime.value);
    if (this.endtime.value === "") {
      this.endtime.setValue(s.split("T")[0]);
    }
  }
  saveEndtime(e) {
    this.endtime.setValue(e.split("T")[0]);
  }
  saveDate(i, j, e) {
    this.positionWorkTime(i, j).controls["date"].setValue(e.split("T")[0]);
  }
  saveStart(i, j, e) {
    this.positionWorkTime(i, j).controls["start"].setValue(
      e.split("T")[1].substring(0, 5)
    );
  }
  saveEnd(i, j, e) {
    this.positionWorkTime(i, j).controls["end"].setValue(
      e.split("T")[1].substring(0, 5)
    );
  }
  initJobEditForm() {
    this.jobeditForm = this.fb.group({
      name: [this.job.name, [Validators.required]],
      starttime: [
        this.job.starttime,
        [Validators.required, CustomValidators.isDateValid],
      ],
      endtime: [
        this.job.endtime,
        [Validators.required, CustomValidators.isDateValid],
      ],
      partner: [this.job.partner, [Validators.required]],
      location: [this.job.location, [Validators.required]],
      contact: [this.job.contact, [Validators.required]],
      contactphone: [this.job.contactphone, [Validators.required]],
      partneraddress: [this.job.partneraddress, [Validators.required]],
      internalcontact: [this.job.internalcontact, [Validators.required]],
      internalcontactphone: [
        this.job.internalcontactphone,
        [Validators.required],
      ],
      meetingpoint: [this.job.meetingpoint, [Validators.required]],
      dresscode: [this.job.dresscode, [Validators.required]],
      shortdescription: [this.job.shortdescription, [Validators.required]],
      description: [this.job.description, [Validators.required]],
      positions: this.fb.array([]),
      public: this.job.public,
      showLanHint: this.job.showLanHint,
    });
  }

  ngOnInit() {
    this.fields = Job.getFields();
    this.posfields = Position.getFields();

    console.log(this.job);
  }

  async saveJobDialog() {
    if(this.canEdit == "times" && !this.isInMarket) {
      let alert = await this.alertController.create({
        header: "Job speichern",
        message: "Job ist nicht mehr in den Jobangeboten vorhanden. Soll dieser erneut veröffentlicht werden?",
        buttons: [
          {
            text: 'Erneut veröffentlichen',
            cssClass: 'secondary',
            handler: () => {
              this.saveJob(true);
            }
          },
          {
            text: 'Nur Updaten',
            handler: () => {
              this.saveJob(false);
            }
          },
        ]
      });
      await alert.present();
    } else {
      this.saveJob(true);
    }
  }

  async saveJob(aPublishJob: boolean) {
    // console.log("SAVING JOB: ", this.positions);
    // console.log("SAVING JOB: ", this.jobeditForm.value);

    const jobBefore = new Job();
    Object.assign(jobBefore, this.job);
    this.initJobFromForm();

    if (this.job.id && this.job.id !== "") {
      if (this.canEdit === "times") {
        await this.adminService.updateJob(this.job)
        if(aPublishJob) {
          await this.adminService.putJobIntoMarket(this.job);
          ToastService.makeToast("Job in Jobangeboten geupdatet");
        } else {
          ToastService.makeToast("Job geupdatet");
        }

        const tsUpdates = await this.updateAffectedTimesheets(jobBefore);
        console.warn("changes:",tsUpdates);

        let alert = await this.alertController.create({
          header: "Arbeitszeiten geändert",
          message: "Die Arbeitszeiten wurden für einige Nutzer geändert. Sollen die Nutzer über die Änderungen informiert werden?",
          buttons: [
            {
              text: 'Benachrichtigung senden',
              cssClass: 'secondary',
              handler: async () => {
                this.adminService.sendTimesheetChangesNotification(tsUpdates);
              }
            },
            {
              text: 'Nein',
              handler: () => { }
            },
          ]
        });
        if(tsUpdates.length>0) {
          await alert.present();
        }


      } else if (this.canEdit === "future") {
        await this.adminService.updateJobFuture(this.job);
        ToastService.makeToast("Job geupdated");
      }
    } else {
      await this.adminService.setJob(this.job);
      ToastService.makeToast("Job erstellt");
    }

    this.navCtrl.navigateBack(["showjob"]); //stateless navigate back
  }

  //check whether some worktimes has changed to update timesheets of finallyAcceppted users
  private async updateAffectedTimesheets(jobBefore: Job): Promise<UserTimesheetDifference[]> {
    const updates: UserTimesheetDifference[] = [];

    if (this.finallyAcceptedApplications.length > 0) {
      let changedPositions = [];
      if (jobBefore.positions.length != this.job.positions.length) {
        // add all positions
        changedPositions = Array.from({ length: this.job.positions.length }, (_, index) => index);
      } else {
        for (let i = 0; i < this.job.positions.length; i++) {
          if (jobBefore.positions[i].worktimes.length != this.job.positions[i].worktimes.length) {
            changedPositions.push(i);
          } else {
            if (!Timesheet.areEqual(jobBefore.positions[i].worktimes, this.job.positions[i].worktimes)) {
              changedPositions.push(i);
            }
          }
        }
      }

      if (changedPositions.length) {
        console.log("saveJob() - times have been changed for following positions:", changedPositions);

        const jobTimesheets = await this.adminService.getTimesheetsForJob(this.job.id);
        console.log("jobTimesheets",jobTimesheets)
        console.log("this.finallyAcceptedApplications",this.finallyAcceptedApplications)
        for await (const appl of this.finallyAcceptedApplications) {
          if (changedPositions.includes(appl.positionindex) || changedPositions.includes(Number(appl.positionindex))) {
            const applTimesheet = jobTimesheets.find(uts => uts.userId == appl.userid && uts.posIndex == appl.positionindex);
            console.log("applTimesheet",applTimesheet)
            if (applTimesheet) {
              // if planned hours were already changed before do not overwrite them
              const differentPlannedHours = !Timesheet.areEqual(applTimesheet.timesheet.plannedTimesheet, jobBefore.positions[appl.positionindex].worktimes);
              if (differentPlannedHours) {
                console.log("saveJob() - planned hours were already changed before and won't be changed for", appl, "saved timesheet (planed):", applTimesheet.timesheet.plannedTimesheet, "unchanged job worktimes:", jobBefore.positions[appl.positionindex].worktimes);
              } else {
                if(applTimesheet.timesheet.isPending()) {
                  await this.adminService.saveTimeSheet(
                    this.job,
                    appl.positionindex,
                    appl.userid,
                    this.job.positions[appl.positionindex].worktimes,
                    "planned"
                  );
                  console.log("saveJob() - Timesheet changed for following application:", appl, "New worktimes are:", this.job.positions[appl.positionindex].worktimes);
                  updates.push({userId: appl.userid, jobId: appl.jobid, posIndex: appl.positionindex, tsDifferences: Timesheet.findDifferences(applTimesheet.timesheet.plannedTimesheet,this.job.positions[appl.positionindex].worktimes)})
                } else {
                  console.warn("saveJob() - planned timesheet won't be changed because timesheet is not pending anymore:",applTimesheet);
                }
              }
            }
          }
        }
      }
    }
    return updates;
  }

  checkJob() {
    this.initJobFromForm();
    this.answers = Job.isJobValid(this.job);
    ToastService.makeToast("Anzahl der Fehler: " + this.answers.size);
  }

  fillJob() {
    this.job = this.job.generateTestJob();

    this.initJobEditForm();
    this.initAllPositions();
  }

  validateWorkTime() {
    console.log(
      "WT:",
      Position.validateWorktime(this.job.positions[0].worktimes[0], 0, 0)
    );
  }

  initJobFromForm() {
    let id = this.job.id;
    const changedJob = Job.initFromObject(this.jobeditForm.value);
    Object.assign(this.job, changedJob);
    this.job.id = id;
  }

  deleteWorkTime(i: number, j: number) {
    const control = this.positions.value[i].get("worktimes");
    control.removeAt(j);
  }

  deleteExtraPay(i: number, j: number) {
    const control = this.positions.value[i].get("extrapay");
    control.removeAt(j);
  }

  deleteExtraTurnover(i: number, j: number) {
    const control = this.positions.value[i].get("extraturnover");
    control.removeAt(j);
  }

  getWorkTime(i: number, j: number) {
    const control = this.positions.value[i].get("worktimes")[j];
    return control;
  }

  getExtraPay(i: number, j: number) {
    const control = this.positions.value[i].get("extrapay")[j];
    return control;
  }

  getExtraTurnover(i: number, j: number) {
    const control = this.positions.value[i].get("extraturnover")[j];
    return control;
  }

  addPosition() {
    this.positions.push(this.newPosition());
  }

  initAllPositions() {
    this.job.positions.forEach((p) => {
      let newP = this.initSinglePosition(p);
      console.log("READ POSITION", p);
      console.log("WRITTEN POSITION", newP);

      this.positions.push(newP);
    });
    console.log("POSITIONS", this.positions);
  }

  initSinglePosition(p: Position) {
    return this.fb.group({
      amount: [p.amount, [Validators.required]],
      briefing: [p.briefing, [Validators.required]],
      name: [p.name, [Validators.required]],
      workname: [p.workname, [Validators.required]],
      pay: [p.pay, [CustomValidators.isValidPay]],
      paytype: [p.paytype, [Validators.required]],
      extrapay: this.initExtraPay(p.extrapay),
      hours: [p.hours, [Validators.required, CustomValidators.isValidPay]],
      turnover: [p.turnover, [CustomValidators.isValidPay]],
      turnovertype: [p.turnovertype, [Validators.required]],
      extraturnover: this.initExtraTurnover(p.extraturnover),
      comment: [p.comment, [Validators.required]],
      worktimes: this.initWorkTimes(p.worktimes),
    });
  }

  initExtraPay(
    ep: { epay: string; epaytype: string; ecomment: string }[]
  ): UntypedFormArray {
    let g = this.fb.array([]);
    if (ep == null) {
      g.push(
        this.fb.group({
          epay: ["", [Validators.required, CustomValidators.isValidPay]],
          epaytype: ["", [Validators.required]],
          ecomment: ["", [Validators.required]],
        })
      );
      return g;
    }
    ep.forEach((w) => {
      g.push(
        this.fb.group({
          epay: [w.epay, [Validators.required, CustomValidators.isValidPay]],
          epaytype: [w.epaytype, [Validators.required]],
          ecomment: [w.ecomment, [Validators.required]],
        })
      );
    });
    return g;
  }

  initExtraTurnover(
    ep: { eturnover: string; eturnovertype: string; ecomment: string }[]
  ): UntypedFormArray {
    let h = this.fb.array([]);
    console.log("HIERBINICH1", h);

    if (ep == null) {
      h.push(
        this.fb.group({
          eturnover: ["", [Validators.required, CustomValidators.isValidPay]],
          eturnovertype: ["", [Validators.required]],
          ecomment: ["", [Validators.required]],
        })
      );
      return h;
    }
    console.log("HIERBINICH2", h);

    ep.forEach((w) => {
      h.push(
        this.fb.group({
          eturnover: [
            w.eturnover,
            [Validators.required, CustomValidators.isValidPay],
          ],
          eturnovertype: [w.eturnovertype, [Validators.required]],
          ecomment: [w.ecomment, [Validators.required]],
        })
      );
    });
    console.log("HIERBINICH", h);
    return h;
  }

  initWorkTimes(wt: RealWorktime[]): UntypedFormArray {
    let g = this.fb.array([]);
    wt.forEach((w) => {
      g.push(
        this.fb.group({
          date: [
            w.date,
            Validators.compose([
              Validators.required,
              CustomValidators.isDateValid,
            ]),
          ],
          start: [
            w.start,
            Validators.compose([
              Validators.required,
              CustomValidators.timeValid,
            ]),
          ],
          end: [
            w.end,
            Validators.compose([
              Validators.required,
              CustomValidators.timeValid,
            ]),
          ],
          break: [
            w.break,
            Validators.compose([
              Validators.required,
              CustomValidators.isValidPay,
            ]),
          ],
          description: [
            w.description,
            Validators.compose([
              Validators.required,
              CustomValidators.isValidPay,
            ]),
          ],
        })
      );
    });
    return g;
  }

  newPosition() {
    let p = new Position();
    p.worktimes = [new RealWorktime()];
    return this.initSinglePosition(p);
  }

  positionWorkTimes(positionIndex: number): UntypedFormArray {
    return this.positions.at(positionIndex).get("worktimes") as UntypedFormArray;
  }

  getFullPosWorkHours(i: number): number {
    let wt = this.positionWorkTimes(i).controls;
    let h = 0;
    for (let j = 0; j < wt.length; j++) {
      h += this.getTimeInHours(i, j);
    }
    return h;
  }

  isPositionHoursValid(i: number): boolean {
    return (
      this.getFullPosWorkHours(i) / this.positionWorkTimes(i).controls.length >
      8
    );
  }

  positionWorkTime(pi, wi): UntypedFormArray {
    return this.positionWorkTimes(pi).controls[wi] as UntypedFormArray;
  }

  addPositionWorkTime(positionIndex: number, worktimeIndex ? : number) {
    let newWT = this.newWorkTime().controls[0] as UntypedFormGroup;
    let wt = this.positionWorkTimes(positionIndex);
    console.log("WT", wt);
    if (wt.length) {
      let wtl = wt.value.length - 1;
      if(worktimeIndex !== undefined) {
        wtl = worktimeIndex;
      }
      try {
        let d = moment(wt.value[wtl].date, "YYYY-MM-DD");
        if(worktimeIndex !== undefined) {
          d.subtract(1, "d");
        } else {
          d.add(1, "d");
        }
        let newD = d.format("YYYY-MM-DD");
        let next = new RealWorktime();
        next.date = newD;
        next.start = wt.value[wtl].start;
        next.end = wt.value[wtl].end;
        next.break = wt.value[wtl].break;
        next.description = wt.value[wtl].description;

        newWT = this.initWorkTimes([next]).controls[0] as UntypedFormGroup;
      } catch (e) {
        console.error("Failed to read Date");
      }
    }
    if(worktimeIndex !== undefined) {
      wt.insert(worktimeIndex, newWT);
    } else {
      wt.push(newWT);
    }
  }

  removePositionWorkTime(positionIndex, worktimeIndex) {
    this.positionWorkTimes(positionIndex).removeAt(worktimeIndex);
  }

  newWorkTime() {
    return this.initWorkTimes([new RealWorktime()]);
  }

  positionExtraPays(positionIndex: number): UntypedFormArray {
    return this.positions.at(positionIndex).get("extrapay") as UntypedFormArray;
  }

  positionExtraTurnovers(positionIndex: number): UntypedFormArray {
    return this.positions.at(positionIndex).get("extraturnover") as UntypedFormArray;
  }

  positionExtraPay(pi, wi): UntypedFormArray {
    return this.positionExtraPays(pi).controls[wi] as UntypedFormArray;
  }

  positionExtraTurnover(pi, wi): UntypedFormArray {
    return this.positionExtraTurnovers(pi).controls[wi] as UntypedFormArray;
  }

  addPositionExtraPay(positionIndex: number) {
    let newWT = this.newExtraPay().controls[0] as UntypedFormGroup;
    this.positionExtraPays(positionIndex).push(newWT);
  }

  addPositionExtraTurnover(positionIndex: number) {
    let newWT = this.newExtraTurnover().controls[0] as UntypedFormGroup;
    this.positionExtraTurnovers(positionIndex).push(newWT);
  }

  removePositionExtraPay(positionIndex, worktimeIndex) {
    this.positionExtraPays(positionIndex).removeAt(worktimeIndex);
  }
  removePositionExtraTurnover(positionIndex, worktimeIndex) {
    this.positionExtraTurnovers(positionIndex).removeAt(worktimeIndex);
  }

  newExtraPay() {
    return this.initExtraPay([{ epay: "", epaytype: "", ecomment: "" }]);
  }

  newExtraTurnover() {
    return this.initExtraTurnover([
      { eturnover: "", eturnovertype: "", ecomment: "" },
    ]);
  }

  isDateValid(pi, wi) {
    return (
      this.positionWorkTime(pi, wi).controls["date"].touched &&
      this.positionWorkTime(pi, wi).controls["date"].invalid
    );
  }

  isStartValid(pi, wi) {
    return (
      this.positionWorkTime(pi, wi).controls["start"].touched &&
      this.positionWorkTime(pi, wi).controls["start"].invalid
    );
  }
  isEndValid(pi, wi) {
    return (
      this.positionWorkTime(pi, wi).controls["end"].touched &&
      this.positionWorkTime(pi, wi).controls["end"].invalid
    );
  }
  isBreakValid(pi, wi) {
    return (
      this.positionWorkTime(pi, wi).controls["break"].touched &&
      this.positionWorkTime(pi, wi).controls["break"].invalid
    );
  }
  isTimeLong(i, j) {
    if (
      !(
        this.isDateValid(i, j) &&
        this.isStartValid(i, j) &&
        this.isEndValid(i, j)
      )
    ) {
    }
    return true;
  }

  getTimeInHours(i, j): number {
    return RealWorktime.getWorktimeNumber(
      this.positionWorkTime(i, j).controls["date"].value,
      this.positionWorkTime(i, j).controls["start"].value,
      this.positionWorkTime(i, j).controls["end"].value,
      this.positionWorkTime(i, j).controls["break"].value
    );
  }

  getTimeInHoursString(i, j): string {
    return RealWorktime.getWorktimeString(
      this.positionWorkTime(i, j).controls["date"].value,
      this.positionWorkTime(i, j).controls["start"].value,
      this.positionWorkTime(i, j).controls["end"].value,
      this.positionWorkTime(i, j).controls["break"].value
    );
  }

  deletePosition(i) {
    for(const appl of this.finallyAcceptedApplications) {
      if(appl.positionindex == i) {
        ToastService.makeToast("Zu dieser Position gibt es bereits finale Zusagen.")
        return;
      }
    }
    this.positions.removeAt(i);
  }

  numberOnlyValidation(event: any) {
    const pattern = /[0-9]|\,/;
    const fullPattern = /^[0-9]*(,((\d)?\d)?)?$/;
    let inputChar = String.fromCharCode(event.charCode);
    console.warn("FAIL", pattern.test(inputChar));
    console.warn("FAIL", event.path[0].value);

    if (
      !pattern.test(inputChar) ||
      !fullPattern.test(event.path[0].value + inputChar)
    ) {
      // invalid character, prevent input
      event.preventDefault();
      console.warn("FAIL", inputChar);
    } else {
      console.log("WIN", inputChar);
    }
  }

  get name() {
    return this.jobeditForm.get("name");
  }

  get starttime() {
    return this.jobeditForm.get("starttime");
  }
  get endtime() {
    return this.jobeditForm.get("endtime");
  }
  get partner() {
    return this.jobeditForm.get("partner");
  }
  get location() {
    return this.jobeditForm.get("location");
  }
  get contact() {
    return this.jobeditForm.get("contact");
  }
  get contactphone() {
    return this.jobeditForm.get("contactphone");
  }
  get partneraddress() {
    return this.jobeditForm.get("partneraddress");
  }
  get internalcontact() {
    return this.jobeditForm.get("internalcontact");
  }
  get internalcontactphone() {
    return this.jobeditForm.get("internalcontactphone");
  }
  get meetingpoint() {
    return this.jobeditForm.get("meetingpoint");
  }
  get dresscode() {
    return this.jobeditForm.get("dresscode");
  }
  get shortdescription() {
    return this.jobeditForm.get("shortdescription");
  }
  get description() {
    return this.jobeditForm.get("description");
  }
  get positions() {
    return this.jobeditForm.get("positions") as UntypedFormArray;
  }
}
export class CustomValidators {
  static isGermanPhone(control: UntypedFormControl) {
    let number = control.value;
    if (CustomValidators.isGermanPhoneValue(number)) {
      return null;
    }
    return {
      isGermanPhone: {
        valid: false,
      },
    };
  }

  static isGermanPhoneValue(value: any): boolean {
    if (value && /^\+(?:[0-9]⋅?){6,14}[0-9]$/.test(value)) {
      return true;
    }
    return false;
  }

  static isDateValid(control: UntypedFormControl) {
    let date = control.value;
  
    if (
      date &&
      //Web Format
      (/^\d\d\d\d\-\d\d\-\d\d$/.test(date) && moment(date, 'YYYY-MM-DD').isValid() ||
      //Mobile Format
      /^\d\d\.\d\d\.\d\d\d\d$/.test(date) && moment(date, 'DD.MM.YYYY').isValid())
    ) {
      return null;
    }
    return {
      isDateValid: {
        valid: false,
      },
    };
  }
  static isDateNotInFuture(control: UntypedFormControl) {
    let date = control.value;

    if (
      date &&
      /^\d\d\d\d\-\d\d\-\d\d$/.test(date) &&
      moment(date, "YYYY-MM-DD").isValid() &&
      moment(date, "YYYY-MM-DD").isBefore(moment())
    ) {
      return null;
    }
    return {
      isDateValid: {
        valid: false,
      },
    };
  }

  static validGermanNumber(control: UntypedFormControl) {
    let number = control.value;
    if (number && /^\d(\,)?\d$/.test(number)) {
      return null;
    }
    return {
      isNumberGerman: {
        valid: false,
      },
    };
  }

  static timeValid(control: UntypedFormControl) {
    let time = control.value;
    if (time && /^\d\d\:\d\d$/.test(time)) {
      return null;
    }
    return {
      timeValid: {
        valid: false,
      },
    };
  }

  static isValidPay(control: UntypedFormControl) {
    let time = control.value;
    if (time && /^\d(\d)*(\,\d\d)?$/.test(time)) {
      return null;
    }
    return {
      payValid: {
        valid: false,
      },
    };
  }
  static isBreakValid(control: UntypedFormControl) {
    const time = control.value;
    const validPattern = /^\d+(\,\d{1,2})?$/;
  
    if (time && validPattern.test(time)) {
      return null;
    }
  
    return {
      breakValid: {
        valid: false,
      },
    };
  }

  static isValidExtraPay(control: UntypedFormControl) {
    let time = control.value;
    if (time && /^(\d(\d)*(\,\d\d)?\/(h|d|a).*;)*$/.test(time)) {
      return null;
    }
    return {
      payValid: {
        valid: false,
      },
    };
  }

  static isIBAN(control: UntypedFormControl) {
    let input = control.value;
    /*
     * Returns 1 if the IBAN is valid
     * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
     * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
     */
    function isValidIBANNumber(input) {
      var CODE_LENGTHS = {
        AD: 24,
        AE: 23,
        AT: 20,
        AZ: 28,
        BA: 20,
        BE: 16,
        BG: 22,
        BH: 22,
        BR: 29,
        CH: 21,
        CR: 21,
        CY: 28,
        CZ: 24,
        DE: 22,
        DK: 18,
        DO: 28,
        EE: 20,
        ES: 24,
        FI: 18,
        FO: 18,
        FR: 27,
        GB: 22,
        GI: 23,
        GL: 18,
        GR: 27,
        GT: 28,
        HR: 21,
        HU: 28,
        IE: 22,
        IL: 23,
        IS: 26,
        IT: 27,
        JO: 30,
        KW: 30,
        KZ: 20,
        LB: 28,
        LI: 21,
        LT: 20,
        LU: 20,
        LV: 21,
        MC: 27,
        MD: 24,
        ME: 22,
        MK: 19,
        MR: 27,
        MT: 31,
        MU: 30,
        NL: 18,
        NO: 15,
        PK: 24,
        PL: 28,
        PS: 29,
        PT: 25,
        QA: 29,
        RO: 24,
        RS: 22,
        SA: 24,
        SE: 24,
        SI: 19,
        SK: 24,
        SM: 27,
        TN: 24,
        TR: 26,
      };
      var iban = String(input)
          .toUpperCase()
          .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
        code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
        digits;
      // check syntax and length
      if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
      }
      // rearrange country code and check digits, and convert chars to ints
      digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) =>
        (letter.charCodeAt(0) - 55).toString()
      );
      // final check
      return mod97(digits);
    }
    function mod97(string) {
      var checksum = string.slice(0, 2),
        fragment;
      for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
      }
      return checksum;
    }

    if (isValidIBANNumber(input) == 1) {
      return null;
    }
    return {
      isIBAN: {
        valid: false,
      },
    };
  }

  static isBIC(control: UntypedFormControl) {
    let bic = control.value;

    if (
      bic &&
      /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(bic)
    ) {
      return null;
    }
    return {
      bicValid: {
        valid: false,
      },
    };
  }

  static isIDNR(control: UntypedFormControl) {
    let idnr = control.value;
    idnr = idnr.replace(/\s/g, "");
    if (idnr === "") {
      console.log("TESTIDNR", idnr);

      return null;
    } else {
      console.log("TESTIDNR", idnr);
    }
    let idnra = idnr.toString().substring(0, idnr.length - 1);

    function checkcifs(id) {
      var ca = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
      var r = id.length;
      for (i = 0; i < r; i++) {
        ca[1 * id.substr(i, 1)]++;
      }
      var ng1 = 0;
      var ng3 = 0;
      for (var i = 0; i < r; i++) {
        var n = ca[i];
        if (n > 1) {
          ng1++;
          if (n > 3) {
            ng3++;
          }
        }
      }
      var err = 0;
      if (ng1 < 1) err += 1;
      if (ng1 > 1) err += 2;
      if (ng3 > 0) err += 4;
      return err;
    }

    var t = idnra;
    var r = t.length;
    var chk = checkcifs(t);
    if (r > 9) {
      let sum = 0;
      let prod = 10;
      for (let i = 0; i < 10; i++) {
        sum = (1 * t.substr(i, 1) + prod) % 10;
        if (sum < 1) sum = 10;
        prod = (sum * 2) % 11;
      }
      let tcl = (11 - prod) % 10;
      let fb = "";
      if (chk == 1) {
        fb = "Es dürfen nicht alle Ziffern 0-9 vorkommen.";
      } else if (chk > 3) {
        fb = "Keine Ziffer darf mehr als 3-mal vorkommen.";
      } else if (chk == 2) {
        fb = "Nur eine Ziffer darf mehrfach vorkommen.";
      }
      if (fb == "") {
        console.log("IDNR:", tcl);
        console.log("IDNR:", idnr[idnr.length - 1]);
        if (tcl == idnr[idnr.length - 1]) {
          return null;
        } else {
          fb = "Die Identifikationsnummer ist ungültig.";
        }
      }

      return {
        isValidIDNR: {
          err: fb,
        },
      };
    } else {
      let fb = "Weitere Ziffern eingeben!";
      if (chk == 2) {
        fb = "Nur eine Ziffer darf mehrfach vorkommen.";
      } else if (chk > 3) {
        fb = "Keine Ziffer darf mehr als 3-mal vorkommen.";
      }
      if (r == 0) {
        fb = "IdNr bitte oben eingeben!";
      }
      return {
        isValidIDNR: {
          err: fb,
        },
      };
    }
  }

  static isGermanNumber(control: UntypedFormControl) {
    let number = control.value;
    if (number && /^\d*(\d)*(\,(\d)*)?$/.test(number)) {
      return null;
    }
    return {
      numberValid: {
        valid: false,
      },
    };
  }

  static isPLZ(control: UntypedFormControl) {
    let number = control.value;
    if (number && /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/.test(number)) {
      return null;
    }
    return {
      numberValid: {
        valid: false,
      },
    };
  }

  static isCharNumberOrSpecial(control: UntypedFormControl) {
    let input = control.value;
    if (
      input &&
      /^[\,\;\-\/\_\+\.\u00BF-\u1FFF\u2C00-\uD7FF\w\ ]*$/.test(input)
    ) {
      return null;
    }
    return {
      inputValid: {
        valid: false,
      },
    };
  }
}
