import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  NgZone,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormArray,
  Validators,
  FormControl,
} from "@angular/forms";
import { SedCard } from "src/app/services/user/profile.service";
import { CustomValidators } from "src/app/pages/dbjobs/addjob/addjob.page";
import { AlertController, IonicSlides } from "@ionic/angular";

@Component({
  selector: "app-sedcard-form",
  templateUrl: "./sedcard-form.component.html",
  styleUrls: ["./sedcard-form.component.scss"],
})
export class SedcardFormComponent implements OnInit {
  swiperModules = [IonicSlides]
  sedcardForm: UntypedFormGroup = null;
  @Input() sedcard: SedCard;
  @Output() formSubmitted = new EventEmitter<any>();

  address: Object;
  establishmentAddress: Object;

  formattedAddress: string;
  formattedEstablishmentAddress: string;

  phone2: string;

  constructor(
    private fb: UntypedFormBuilder,
    public zone: NgZone,
    private alertController: AlertController
  ) {}
  names = {};
  ngOnInit() {
    fetch("./assets/lang/de.json")
      .then((res) => res.json())
      .then((json) => {
        this.names = json;
      });

    this.sedcardForm = this.fb.group({
      street: [this.sedcard.street],
      number: [this.sedcard.number],
      zip: [this.sedcard.zip],
      city: [this.sedcard.city],
      bigcity: [this.sedcard.bigcity],
      phone: [this.sedcard.phone, [CustomValidators.isGermanPhone]],
      email: [this.sedcard.email, [Validators.email]],
      haircolor: [this.sedcard.haircolor],
      size: [this.sedcard.size],
      jeans: [this.sedcard.jeans],
      piercing: [this.sedcard.piercing],
      tattoo: [this.sedcard.tattoo],
      confectionsize: [this.sedcard.confectionsize],
      shoes: [this.sedcard.shoes],
      car: [this.sedcard.car],
      driverslicense: [this.sedcard.driverslicense],
      classe: [this.sedcard.classe],
      sleepcities: [this.sedcard.sleepcities],
      school: [this.sedcard.school],
      uni: [this.sedcard.uni],
      ausbildung: [this.sedcard.ausbildung],
      job: [this.sedcard.job],
      specialskills: [this.sedcard.specialskills],
    });

    this.sedcardForm.valueChanges.subscribe(console.log);
  }

  get street() {
    return this.sedcardForm.get("street");
  }
  get number() {
    return this.sedcardForm.get("number");
  }
  get zip() {
    return this.sedcardForm.get("zip");
  }
  get city() {
    return this.sedcardForm.get("city");
  }
  get bigcity() {
    return this.sedcardForm.get("bigcity");
  }
  get phone() {
    return this.sedcardForm.get("phone");
  }
  get email() {
    return this.sedcardForm.get("email");
  }
  get haircolor() {
    return this.sedcardForm.get("haircolor");
  }
  get size() {
    return this.sedcardForm.get("size");
  }
  get jeans() {
    return this.sedcardForm.get("jeans");
  }
  get piercing() {
    return this.sedcardForm.get("piercing");
  }
  get confectionsize() {
    return this.sedcardForm.get("confectionsize");
  }
  get shoes() {
    return this.sedcardForm.get("shoes");
  }
  get car() {
    return this.sedcardForm.get("car");
  }
  get driverslicense() {
    return this.sedcardForm.get("driverslicense");
  }
  get classe() {
    return this.sedcardForm.get("classe");
  }
  get sleepcities() {
    return this.sedcardForm.get("sleepcities");
  }
  get school() {
    return this.sedcardForm.get("school");
  }
  get uni() {
    return this.sedcardForm.get("uni");
  }
  get ausbildung() {
    return this.sedcardForm.get("ausbildung");
  }
  get job() {
    return this.sedcardForm.get("job");
  }
  get specialskills() {
    return this.sedcardForm.get("specialskills");
  }

  submitSedcard(sedcardForm: UntypedFormGroup): void {
    this.sedcard.initWithObject(sedcardForm.value);
    this.formSubmitted.emit(this.sedcard);
  }

  formatPhoneNumber(event: CustomEvent) {
    console.log(event);
    event.detail.value = "XXXX";
  }

  getAddress(place: object) {
    console.log("PLACE:", place);
    let adComp = place["address_components"];
    adComp.forEach((a) => {
      if (a["types"])
        a["types"].forEach((t) => {
          if (t === "route") {
            console.log("STRAßE", a.long_name);
            this.street.setValue(a.long_name);
          }
          if (t === "street_number") {
            console.log("nummer", a.long_name);
            this.number.setValue(a.long_name);
          }
          if (t === "postal_code") {
            console.log("postal_code", a.long_name);
            this.zip.setValue(a.long_name);
          }
          if (t === "locality") {
            console.log("STADT", a.long_name);
            this.city.setValue(a.long_name);
          }
        });
    });
    this.address = place["formatted_address"];
    this.formattedAddress = place["formatted_address"];
    this.zone.run(() => (this.formattedAddress = place["formatted_address"]));
  }

  async presentAlertCheckbox() {
    console.log("AAAA");
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Checkbox",
      inputs: [
        {
          name: "checkbox1",
          type: "checkbox",
          label: "Checkbox 1",
          value: "value1",
          checked: true,
        },

        {
          name: "checkbox2",
          type: "checkbox",
          label: "Checkbox 2",
          value: "value2",
        },

        {
          name: "checkbox3",
          type: "checkbox",
          label: "Checkbox 3",
          value: "value3",
        },

        {
          name: "checkbox4",
          type: "checkbox",
          label: "Checkbox 4",
          value: "value4",
        },

        {
          name: "checkbox5",
          type: "checkbox",
          label: "Checkbox 5",
          value: "value5",
        },

        {
          name: "checkbox6",
          type: "checkbox",
          label:
            "Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6",
          value: "value6",
        },
      ],
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: "Ok",
          handler: () => {
            console.log("Confirm Ok");
          },
        },
      ],
    });

    await alert.present();
  }
}
