import { initializeApp, getApps, getApp, FirebaseApp } from "@firebase/app";
import { Firestore, getFirestore, connectFirestoreEmulator } from "@firebase/firestore";
import { Auth, getAuth } from "@firebase/auth/cordova";
import { FirebaseStorage, getStorage } from "firebase/storage";
import { environment } from "src/environments/environment";

export const firebaseApp: FirebaseApp = getApps().length === 0 ? initializeApp(environment.firebase) : getApp();
export const fireDb: Firestore = getFirestore(firebaseApp);
export const fireAuth: Auth = getAuth(firebaseApp);
export const fireStorage: FirebaseStorage = getStorage(firebaseApp);

//connectFirestoreEmulator(fireDb, '127.0.0.1', 8080);