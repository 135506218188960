export class Partner {
  pname: string = "-1";
  contactname: string = "-1";
  contactphone: string = "-1";
  partneraddress: string = "-1";
  id: string = "";
  constructor() {}
  init(name, contactname, phone, address) {
    this.pname = name;
    this.contactname = contactname;
    this.contactphone = phone;
    this.partneraddress = address;
  }
  initFromFireBase(o: any): void {
    this.pname = o["pname"];
    this.contactname = o["contactname"];
    this.contactphone = o["contactphone"];
    this.partneraddress = o["partneraddress"];
  }
  getFireBaseData(): any {
    return {
      pname: this.pname,
      contactname: this.contactname,
      contactphone: this.contactphone,
      partneraddress: this.partneraddress
    };
  }
  isFilled(): boolean {
    if (
      this.pname === "" ||
      this.contactname === "" ||
      this.contactphone === "" ||
      this.partneraddress === ""
    )
      return false;
    return true;
  }
}
