import { Injectable } from "@angular/core";
import { cloudFunctionsUrlPrefix } from 'src/environments/environment';
import { fireDb, fireAuth, fireStorage, firebaseApp } from "src/app/shared-modules/initFirebase";
import { 
  CollectionReference, DocumentReference, QuerySnapshot,
  collection, doc,
  getDoc, setDoc, deleteDoc, addDoc, getDocs,updateDoc,
  query, orderBy, limit, where, writeBatch, onSnapshot, deleteField
} from "@firebase/firestore";
import { ref, listAll, getMetadata, getDownloadURL, ListResult } from "firebase/storage";
import { getAnalytics, setAnalyticsCollectionEnabled, logEvent } from "firebase/analytics";
import {
  UserProfile,
  SedCard,
  TaxDetails,
  UserComment,
} from "../user/profile.service";
import {
  AlertController,
  IonicSafeString,
  LoadingController
} from "@ionic/angular";
import { ToastService } from "../toast/toast.service";
import * as moment from "moment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Rating } from "src/app/classes/Rating";
import { Partner } from "src/app/classes/Partner";
import { Contract, AgreementStatusType, UserContract, ContractChangeReason, ContractChange, JobDetail } from "src/app/classes/Contract";
import { environment } from "src/environments/environment";
import { DocumentData } from "firebase/firestore";
import { BehaviorSubject } from "rxjs";
import { Unsubscribe } from "@firebase/util";
declare let cordova: any;

export interface StorageFileData {
  filename: string;
  fileurl: string;
  createdDate: string;
}

export interface MonthlyWorkingTime {
  month: string,
  workingHours: number,
  paidHours: number,
  unpaidHours: number
}

export interface MonthlyContractWorkingTime {
  month: string,
  workingHours: number
}
export type IndividualPay = {
  name: string;
  amount: string;
}

@Injectable({
  providedIn: "root",
})
export class AdminService {
  public jobsRef: CollectionReference;
  public allUsers: CollectionReference;
  public allUserProfiles: UserProfile[] = [];
  public allJobs: Job[] = [];
  public applicationsRef: CollectionReference;
  public applicationRef: DocumentReference;
  private users: UserProfile[] = [];
  private applications: Application[] = [];
  public userRef: CollectionReference;
  public taxRef: CollectionReference;
  public applicationsChanged: boolean = false;
  private names = {};
  public fcmToken = "";
  private newJobsSubject = new BehaviorSubject<string[]>([]);
  private changedContractSubject = new BehaviorSubject<Contract>(new Contract());
  private unsubscribeContractChange: Unsubscribe = undefined;

  constructor(
      public http: HttpClient,
      private alertController: AlertController,
      public loadingCtrl: LoadingController) {

    if(!environment.production) {
      const fireAnalytics = getAnalytics(firebaseApp);
      setAnalyticsCollectionEnabled(fireAnalytics, true);
      logEvent(fireAnalytics, 'admin_event');
    }

    fetch("./assets/lang/de.json")
      .then((res) => res.json())
      .then((json) => {
        this.names = json;
      });

    this.initApplications();
    this.userRef = collection(fireDb,`userProfile`);
    this.taxRef = collection(fireDb,`taxDetails`);
  }

  async logAuthError(mail: string, error: any): Promise<boolean> {
    // const url = `${cloudFunctionsUrlPrefix.euWest}/logAuthError`;
    const url = `${cloudFunctionsUrlPrefix.euWest}/logAuthErrorV2`;

    const body = { mail: mail, error: error };
    try {
      await this.http.post(url, body).toPromise();
      return true;
    } catch (error) {
      console.error("Error adding authError: ", error);
      return false;
    }
  }

  requestJobPublish(jobid): Promise<Map<string, string>> {
    // const url = `${cloudFunctionsUrlPrefix.euWest}/jobPublish`;
    const url = `${cloudFunctionsUrlPrefix.euWest}/jobPublishV2`;
    return fireAuth
      .currentUser.getIdToken()
      .then((authToken) => {
        const headers = new HttpHeaders({
          Authorization: "Bearer " + authToken,
        });
        const body = { uid: fireAuth.currentUser.uid, jobid: jobid };
        return this.http.post(url, body, { headers: headers }).toPromise();
      })
      .then((res) => {
        console.log("requestJobPublish Error RES", res);
        return new Map(JSON.parse(res["res"]));
      });
  }

  async setUnderUtilizedForMonth(aMonth: string): Promise<boolean> {
    //const url = `${cloudFunctionsUrlPrefix.euWest}/setUnderUtilizedForMonth`;
    const url = `${cloudFunctionsUrlPrefix.euWest}/setUnderUtilizedForMonthV2`;

    const authToken = await fireAuth
      .currentUser.getIdToken();
    const headers = new HttpHeaders({
      Authorization: "Bearer " + authToken,
    });
    const body = {
      month: aMonth // MM/YYYY
    };
    const res = await this.http.post(url, body, { headers: headers }).toPromise();

    console.log("RES", res);
    let success = false;
    try {
      if(res["res"]) {
        success = true;
      } else if (res["error"]) {
        console.error("Error setUnderUtilizedForMonth(): ", res["error"]);
      }
    } catch(error) {
      console.error("Error setUnderUtilizedForMonth(): ", error)
    }
    return success;
  }

  /**
   * Returns the Errors of the Tax Detaisl in a Map => If the map is size 0 The Taxdetails are valid
   * @param userid If
   */
  requestTaxDetailsCheck(userid, aCheckTaxOnly: boolean, aForceWriteTaxDetails: boolean): Promise<Map<string, string>> {
    // const url = `${cloudFunctionsUrlPrefix.euWest}/validateTaxdetails`;
    const url = `${cloudFunctionsUrlPrefix.euWest}/validateTaxdetailsV2`;

    if(aCheckTaxOnly)
      aForceWriteTaxDetails = false;

    return fireAuth
      .currentUser.getIdToken()
      .then((authToken) => {
        const headers = new HttpHeaders({
          Authorization: "Bearer " + authToken,
        });
        const body = {
          uid: fireAuth.currentUser.uid,
          checkuserid: userid,
          checkTaxOnly: aCheckTaxOnly,
          forceWriteTaxDetails: aForceWriteTaxDetails // secureCode will be ignored
        };
        return this.http.post(url, body, { headers: headers }).toPromise();
      })
      .then((res) => {
        console.log("RES", res);
        let out: Map<string, string> = new Map();
        try {
          out = new Map(JSON.parse(res["res"]));
        } catch {
          out.set("error", res["res"]);
        }
        return out;
      });
  }

  getTaxDetailsSecureCode(userId: string): Promise<string> {
    let taxDetailsRef = doc(this.taxRef, userId);
    return getDoc(taxDetailsRef)
    .then((snap) => {
      let secureCode = snap.data().secureCode;
      return secureCode;
    })
    .catch((e) => {
      console.error(e);
      return "";
    });
  }

  async getTaxDetailsChanges(userId: string): Promise<number> {
    let taxDetailsRef = doc(this.taxRef, userId);
    let changes = 0;
    try {
      const taxSnap = await getDoc(taxDetailsRef);
      if(taxSnap.data().changes)
        changes = taxSnap.data().changes;
    } catch (error) {
      console.error("Error getting TaxDetailsChanges",error);
    }
    return changes;
  }
  setTaxDetailsChanges(userId: string, changes: number): Promise<void> {
    let taxDetailsRef = doc(this.taxRef, userId);
    return setDoc(taxDetailsRef, {changes}, { merge: true });
  }

  async checkTaxDetails(userId: string, overwritable?: boolean): Promise<boolean> {
    let taxValidResult = await this.requestTaxDetailsCheck(userId,true,false);
    
    if(taxValidResult.size > 0) {
      let errorMsg: string;
      if(fireAuth.currentUser.uid == userId) {
        errorMsg = "Du hast noch ungültige Einträge in den Abrechnungsdaten. Bitte korrigiere diese bevor Du fortfährst.";
      } else {
        errorMsg = "Der User hat ungültige Einträge in den Abrechnungsdaten:";
        console.warn("Following userProfile/taxDetails are not ok: ", taxValidResult);
      }
      let missingData: any[] = Array.from(taxValidResult.keys());
      errorMsg += "<ul>";

      missingData.forEach(element => {
        // Check for furtherJobObjects
        if (element.startsWith("furtherJobObjects")) {
          // Extract the job index from the element name
          const jobIndexMatch = element.match(/furtherJobObjects(\d+)/);
          const jobIndex = jobIndexMatch ? parseInt(jobIndexMatch[1]) + 1 : 0; 
  
          if (element.endsWith("employer")) {
            errorMsg += `<li>Arbeitgeber ${jobIndex}</li>`;
          } else if (element.endsWith("weeklyWorkHours")) {
            errorMsg += `<li>Wöchentliche Arbeitszeit in Stunden ${jobIndex}</li>`; 
          } else if (element.endsWith("from")) {
            errorMsg += `<li>Startdatum ${jobIndex}</li>`;
          } else if (element.endsWith("to")) {
            errorMsg += `<li>Enddatum ${jobIndex}</li>`; 
          } else if (element.endsWith("kindOfJob")) {
            errorMsg += `<li>Art der Beschäftigung ${jobIndex}</li>`;
          } else {
            errorMsg += `<li>Unbekanntes Feld (${element})</li>`;
          }
        } else if (this.names[element]) {
          errorMsg += `<li>${this.names[element]}</li>`;
        } else {
          console.error("Missing key in this.names for element:", element);
        }
        console.log("logging errorMsg:", errorMsg);
      });
      
      errorMsg += "</ul>";

      return new Promise<boolean> (async (resolve) => {
        let popupButtons: any = [
          { text: "OK",
          handler: () => {
            resolve(false);
          }
        }
        ];
        if(overwritable) {
          popupButtons = [
            {
              text: 'Abbrechen',
              role: 'cancel',
              handler: () => {
                resolve(false);
              }
            },
            {
              text: 'In die Personalliste',
              handler: () => {
                resolve(true);
              }
            },
          ];
        } else {

        }

        const alert = await this.alertController.create({
          header: "Abrechnungsdaten unvollständig",
          message: new IonicSafeString (errorMsg),
          buttons: popupButtons,
        });
        await alert.present();

      });

      // return false;
    } else {
      return true;
    }
  }

  async updateTaxDetailsForUser(userId: string, taxDetails: TaxDetails): Promise<void> {
    let userProfileRef = doc(this.userRef, userId);
    return setDoc(userProfileRef, { taxDetails: taxDetails.getTaxDetailsData() }, { merge: true });
  }

  async getSettings(): Promise<Settings | undefined> {
    let settingsRef = doc(fireDb, `misc/settings/`);
    try {
      const snap = await getDoc(settingsRef);
      let settings = undefined;
      if (snap.data()) {
        settings = Settings.initFromObject(snap.data());
      }
      return settings;
    } catch (e) {
      console.error("Error getting Settings", e);
      return undefined;
    }
  }

  setDevMailsEnabled(aEnabled: boolean): Promise<void> {
    let settingsRef = doc(fireDb, `misc/settings/`);
    return setDoc(settingsRef, {devMailsEnabled: aEnabled}, { merge: true });
  }
  setApplicationNotificationValues(values: string[]): Promise<void> {
    let settingsRef = doc(fireDb, `misc/settings/`);
    return setDoc(settingsRef, {applicationNotification: values}, { merge: true });
  }
  setGenerateAvEnabled(aEnabled: boolean): Promise<void> {
    let settingsRef = doc(fireDb, `misc/settings/`);
    return setDoc(settingsRef, {generateAvEnabled: aEnabled}, { merge: true });
  }

  async getContract(userId: string): Promise<Contract | undefined> {
    const contractRef = doc(fireDb, `contracts/${userId}`);
    const snap = await getDoc(contractRef);
    if(snap.data() && snap.data().contract) {
      return Contract.initContractFromObject(snap.data().contract);
    } else {
      console.error("Error getting Contract for UserId", userId);
      return undefined;
    }
  }

  async getUserContractsByStatus(...statuses: AgreementStatusType[]): Promise<UserContract[]> {
    const userContracts: UserContract[] = []
      try {
        const contractsQuery = query(collection(fireDb, 'contracts'), where('contract.overallStatus', 'in', statuses));
        const contractsSnap = await getDocs(contractsQuery);
        for await (const doc of contractsSnap.docs) {
          if(doc.data() && doc.data().contract) {
            const userContract: UserContract = {
              contract: Contract.initContractFromObject(doc.data().contract),
              userId: doc.id
            }
            userContracts.push(userContract);
          }
        }
      } catch (error) {
        console.error("Error while querying contracts",error);
      }
      return userContracts;
  }
  async getUserContractsByValidaty(month: string): Promise<UserContract[]> {
    const userContracts: UserContract[] = []
      try {
        const contractsQuery = query(collection(fireDb, 'contracts'), where('contract.overallValidity', 'array-contains', month));
        const contractsSnap = await getDocs(contractsQuery);
        for await (const doc of contractsSnap.docs) {
          if(doc.data() && doc.data().contract) {
            const userContract: UserContract = {
              contract: Contract.initContractFromObject(doc.data().contract),
              userId: doc.id
            }
            userContracts.push(userContract);
          }
        }
      } catch (error) {
        console.error("Error while querying contracts",error);
      }
      return userContracts;
  }
  async getAllUserContracts(): Promise<UserContract[]> {
    const userContracts: UserContract[] = []
      try {
        const contractsCollection = collection(fireDb, 'contracts');
        const contractsSnap = await getDocs(contractsCollection);
        for await (const doc of contractsSnap.docs) {
          if(doc.data() && doc.data().contract) {
            const userContract: UserContract = {
              contract: Contract.initContractFromObject(doc.data().contract),
              userId: doc.id
            }
            userContracts.push(userContract);
          }
        }

      } catch (error) {
        console.error("Error while querying contracts",error);
      }
      return userContracts;
  }

  async setContractData(userId: string, contract: Contract, agreementChange = false): Promise<void> {
    const nowDateString = moment().format();
    const contractRef = doc(fireDb, `contracts/${userId}`);
    const contractChangesRef = doc(fireDb, `contracts/${userId}/changes/${nowDateString}`);
    await setDoc(contractRef, {contract: contract.getDataObject()}, {merge: true});

    if(!agreementChange) { //no need to log agreementChanges as Contract Change
      const contrChange = new ContractChange(contract,"contractManagementChange");
      await setDoc(contractChangesRef, contrChange.getDataObject());
    }
  }

  async getInvitationAuthCode(): Promise<string> {
    let invitationRef = doc(fireDb, `misc/invitation/`);
    try {
      const snap = await getDoc(invitationRef);
      let authCode = snap.data().authCode;
      return authCode;
    } catch (e) {
      console.error("Error getting Invitation Auth Code", e);
      return "";
    }
  }

  setInvitationAuthCode(aValue: string): Promise<void> {
    let invitationRef = doc(fireDb, `misc/invitation/`);
    return setDoc(invitationRef, {authCode: aValue});
  }

  async getAuPermitDate(): Promise<string> {
    let settingsRef = doc(fireDb, `misc/settings/`);
    try {
      const snap = await getDoc(settingsRef);
      let auPermitDate = "";
      if (snap.data() && snap.data().auPermitDate) {
        auPermitDate = snap.data().auPermitDate;
      }
      return auPermitDate;
    } catch (e) {
      console.error("Error getting AuPermitDate", e);
      return "";
    }
  }

  setAuPermitDate(aValue: string): Promise<void> {
    let settingsRef = doc(fireDb, `misc/settings/`);
    return setDoc(settingsRef, {auPermitDate: aValue}, { merge: true });
  }

  requestPhoneAppointment(king: string, app: string): Promise<string> {
    console.log("REQPHONEAPPOINTMENT", king, app);
    // const url = `${cloudFunctionsUrlPrefix.euWest}/makePhoneAppointment`;
    const url = `${cloudFunctionsUrlPrefix.euWest}/makePhoneAppointmentV2`;

    return fireAuth
      .currentUser.getIdToken()
      .then((authToken) => {
        const headers = new HttpHeaders({
          Authorization: "Bearer " + authToken,
        });
        const body = {
          uid: fireAuth.currentUser.uid,
          app: app,
          king: king,
        };
        return this.http.post(url, body, { headers: headers }).toPromise();
      })
      .then((res: { res: string }) => {
        console.log("PhoneRes:", res);
        return res.res;
      });
  }

  requestPhoneAppointmentReset(king: string, app: string): Promise<string> {
    // const url = `${cloudFunctionsUrlPrefix.euWest}/resetPhoneAppointment`;
    const url = `${cloudFunctionsUrlPrefix.euWest}/resetPhoneAppointmentV2`;

    return fireAuth
      .currentUser.getIdToken()
      .then((authToken) => {
        const headers = new HttpHeaders({
          Authorization: "Bearer " + authToken,
        });
        const body = {
          uid: fireAuth.currentUser.uid,
          app: app,
          king: king,
        };
        return this.http.post(url, body, { headers: headers }).toPromise();
      })
      .then((res: { res: string }) => {
        console.log("PhoneRes:", res);
        return res.res;
      });
  }

  async getJobHelper(ref): Promise<Job[]> {
    let allJobs: Job[] = [];
    this.jobsRef = collection(fireDb, ref);
    return getDocs(this.jobsRef).then((jobCollection) => {
      jobCollection.forEach((jobSnap) => {
        let jobSnapData = jobSnap.data();
        if(Object.keys(jobSnapData).length !== 0) {
          let job = Job.initFromObject(jobSnapData);
          if(job)
            allJobs.push(job);
        }
      });

      this.allJobs = allJobs;
      console.log("ALLJOBS: ", this.allJobs);
      return allJobs;
    });
  }

  /**
   * Checks whether all positions of a job were set to readyToPay
   * @param aJobId 
   * @returns 
   */
  async isJobReadyToBePaidCalculation(aJob: Job): Promise<boolean> {
    let success = false;
    try {
      const jobUserTimesheets = await this.getTimesheetsForJob(aJob.id);
      const readyToPayTimeSheets = jobUserTimesheets.filter(jobUts => jobUts.timesheet.isReadyToPay())
  
      let posAmount = 0;
      for(const pos of aJob.positions) {
        posAmount += Number.parseInt(pos.amount);
      }
      console.log("paidTimesheets Amount:", readyToPayTimeSheets.length);
      console.log("Job positions Amount:", posAmount);
  
      if (readyToPayTimeSheets.length === posAmount) {
        success = true;
      }
      return success;
    } catch (error) {
      console.error("isJobReadyToBePaidCalculation - Error:",error);
      return success;
    }
  }

  /**
   * Checks whether all positions of a job were paid
   * @param aJobId 
   * @returns 
   */
  async isJobPaidCalculation(aJob: Job): Promise<boolean> {
    let success = false;
    try {
      const jobUserTimesheets = await this.getTimesheetsForJob(aJob.id);
      const paidTimeSheets = jobUserTimesheets.filter(jobUts => jobUts.timesheet.isPaid())
  
      let posAmount = 0;
      for(const pos of aJob.positions) {
        posAmount += Number.parseInt(pos.amount);
      }
      console.log("paidTimesheets Amount:", paidTimeSheets.length);
      console.log("Job positions Amount:", posAmount);
  
      if (paidTimeSheets.length === posAmount) {
        success = true;
      }
      return success;
    } catch (error) {
      console.error("isJobPaidCalculation - Error:",error);
      return success;
    }
  }

  async getJobs(): Promise<Job[]> {
    return this.getJobHelper("jobs");
  }

  async getJobsFuture(): Promise<Job[]> {
    return this.getJobHelper("jobsfuture");
  }

  async getJobById(id: string): Promise<Job | undefined> {
    let jobRef = doc(fireDb, `jobs/${id}`);
    return getDoc(jobRef)
      .then((jobSnap) => {
        const job = Job.initFromObject(jobSnap.data());
        if(job) {
          return job;
        } else {
          console.error("Error getting Job with id",id,"Job may be deleted.")
          return undefined;
        }
      })
      .catch((e) => {
        console.error("Error getting Job with id " + id, e);
        return undefined;
      });
  }
  async getJobCreationTime(id: string): Promise<string> {
    let jobRef = doc(fireDb, `jobs/${id}`);
    return getDoc(jobRef)
      .then((jobSnap) => {
        if(jobSnap) {
          // console.warn("jobSnap",jobSnap);
          const timestamp = (jobSnap as any)._document.createTime.timestamp; // Use TypeScript assertion to avoid type errors
          // console.warn("jobSnap timestamp",timestamp);
          // Convert the Firestore timestamp to milliseconds
          const dateInMilliseconds = timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1000000);
          const formattedDate = moment(dateInMilliseconds).format('DD.MM.YYYY HH:mm:ss');
          return formattedDate;
        }
      })
      .catch((e) => {
        console.error("getJobCreationTime() - Error getting Job with id " + id, e);
        return undefined;
      });
  }

  getUserComm(uid: string): Promise<UserComment> {
    try {
      let userCommRef = doc(fireDb, `userComm/${uid}`);
      return getDoc(userCommRef).then((jsnap) => {
        let userComm: UserComment = new UserComment();
        if(jsnap.data()) {
          userComm.init(jsnap.data());
        }
        return userComm;
      });
    } catch (e) {
      console.error("Error getting UserComm",e);
      return Promise.resolve(new UserComment());
    }
  }

  async setUserComm(uid: string, userComm: UserComment): Promise<void> {
    let userCommRef = doc(fireDb, `userComm/${uid}`);
    return setDoc(userCommRef, userComm.getData(), { merge: true });
  }

  async deleteJob(job: Job): Promise<void> {
    let jobRef = doc(fireDb, `jobs/${job.id}`);
    this.allJobs = this.allJobs.filter((j) => {
      return j.id !== job.id;
    });
    console.log("JOB DELETED", job.id, this.allJobs);
    await deleteDoc(jobRef);

    await this.changeNewJobsCount(job.id,"remove");

    const marketRef = doc(fireDb, `market/${job.id}`);
    const marketSnap = await getDoc(marketRef);
    if (marketSnap.exists()) {
      console.log("Document data in market collection:", marketSnap.data());
      await deleteDoc(marketRef);
    }
    else {
      console.log("No such document in market Collection!");
    }

    const marketArchiveRef = doc(fireDb, `marketArchive/${job.id}`);
    const marketArchiveSnap = await getDoc(marketArchiveRef);
    if (marketArchiveSnap.exists()) {
      console.log("Document data in marketArchive collection:", marketArchiveSnap.data());
      await deleteDoc(marketArchiveRef);
    }
    else {
      console.log("No such document in marketArchive Collection!");
    }

    const applicationListQuery = query(collection(fireDb,'applicationList'), where("jobid", '==', job.id));
    // const applicationListSnap = await firebase.firestore().collection('applicationList').where(firebase.firestore.FieldPath.documentId(), '>=', job.id).where(firebase.firestore.FieldPath.documentId(), '<=', job.id+ '\uf8ff').get();
    const applicationListSnap = await getDocs(applicationListQuery);
    if (!applicationListSnap.empty) {
      console.log("Jobs found in applicationList collection");
      for await(const doc of applicationListSnap.docs) {
        console.log(doc.id, '=>', doc.data());
        await deleteDoc(doc.ref);
      }
    }
    else {
      console.log('No matching jobs in applicationList collection');
    }

    const timesheetsQuery = query(collection(fireDb,'timesheets'), where("jobId", '==', job.id));
    const timesheetsSnap = await getDocs(timesheetsQuery);
    if (!timesheetsSnap.empty) {
      console.log("Jobs found in timesheets collection");
      for await(const doc of timesheetsSnap.docs) {
        console.log(doc.id, '=>', doc.data());
        const uts = UserTimesheet.initFromObject(doc.data());
        await deleteDoc(doc.ref);
      }
    }
    else {
      console.log('No matching jobs in timesheets collection');
    }

    const contractsQuery = query(collection(fireDb,'contracts'), where("contract.firstJob.id", '==', job.id));
    const contractsSnap = await getDocs(contractsQuery);
    if (!contractsSnap.empty) {
      console.log("Firstjob entries found in contracts collection");
      for await(const doc of contractsSnap.docs) {
        console.log(doc.id, '=>', doc.data());
        const contract = Contract.initContractFromObject(doc.data());
        contract.firstJob.id = "";
        contract.firstJob.posIndex = -1;
        await this.setContractData(doc.data().userId,contract,true);
      }
    }
    else {
      console.log('No firstJob entries in contracts collection');
    }

  }

  deleteJobFuture(job: Job): Promise<void> {
    let jobRef = doc(fireDb, `jobsfuture/${job.id}`);
    return deleteDoc(jobRef);
  }

  //Moves a job from DBJobs/future to DBJobs/Aktuell
  async moveJobToOverview(job: Job): Promise<void> {
    let err = Job.isJobValid(job);
    if (err.size !== 0) {
      console.error("ERR", err);
      return Promise.reject();
    }
    //DBJobs/Aktuell
    let jobListRef = doc(fireDb, `jobs/${job.id}`);
    let j = job.getJobData();
    return setDoc(jobListRef, j).then(() => {
      return this.deleteJobFuture(job).then(() => {
        return this.putJobIntoMarket(job);
      });
    });
  }

  async changeNewJobsCount(aJobId: string, aAction: "add" | "remove"): Promise<boolean> {
    //used for app badges
    // const url = `${cloudFunctionsUrlPrefix.euWest}/changeNewJobsCount`;
    const url = `${cloudFunctionsUrlPrefix.euWest}/changeNewJobsCountV2`;

    const authToken = await fireAuth
      .currentUser.getIdToken();
    const headers = new HttpHeaders({
      Authorization: "Bearer " + authToken,
    });
    const body = {
      jobId: aJobId,
      action: aAction
    };
    const res = await this.http.post(url, body, { headers: headers }).toPromise();

    console.log("RES", res);
    let success = false;
    try {
      if(res["res"]) {
        success = true;
      } else if (res["error"]) {
        console.error("Error changeNewJobsCount(): ", res["error"]);
      }
    } catch(error) {
      console.error("Error changeNewJobsCount(): ", error)
    }
    return success;
  }

  setJobPaymentStatus(jobid: string, paymentStatus: "pending" | "readyToPay" | "readyToPayPartly" | "partlyPaid" | "paid"): Promise<void> {
    let jobListRef = doc(fireDb, `jobs/${jobid}`);
    return updateDoc(jobListRef, { "paymentStatus": paymentStatus });
  }

  async setJob(job: Job): Promise<void> {
    console.log("SET JOB: ", job);

    const newFutureJobRef = doc(collection(fireDb, "jobsfuture"));
    job.id = newFutureJobRef.id;
    return setDoc(newFutureJobRef, job.getJobData()).then(() => {
      this.allJobs.push(job);
    });

    // let jobListRef = collection(fireDb, `jobsfuture`);
    // let nextJobId = this.jobsRef.id;
    // job.id = nextJobId;
    // let j = job.getJobData();
    // return addDoc(jobListRef,j).then((jd) => {
    //   let jobRef = doc(jobListRef, jd.id);
    //   j.id = jd.id;
    //   job.id = jd.id;
    //   this.allJobs.push(job);
    //   return jobRef.set(j, { merge: true });
    // }); //job.getJobData());
  }

  /**
   * 
   * @param aPlainJobName Job Name without any iterator e.g. Jobname (2)
   * @returns 
   */
  async getNextSimilarJobCount(aPlainJobName: string): Promise<number> {
    return new Promise<number>(async (resolve) => {
      let maxCount: number = 0;

      //check jobsfuture collection for similar jobName
      let futureJobListRef = collection(fireDb, `jobsfuture`);
      let futureJobListSnap = await getDocs(futureJobListRef);
      futureJobListSnap.forEach((s) => {
        let dataSnap = s.data();
        if(dataSnap && dataSnap.name) {
          if(dataSnap.name.includes(aPlainJobName + " (")) {
            let countString = dataSnap.name.split('(').pop().slice(0, -1);
            if(Number(countString) > maxCount) {
              maxCount = Number(countString);
            }
          }
        }
      });
      
      //check jobs collection for similar jobName
      let jobListRef = collection(fireDb, `jobs`);
      let jobListSnap = await getDocs(jobListRef);
      jobListSnap.forEach((s) => {
        let dataSnap = s.data();
        if(dataSnap && dataSnap.name) {
          if(dataSnap.name.includes(aPlainJobName + " (")) {
            let countString = dataSnap.name.split('(').pop().slice(0, -1);
            if(Number(countString) > maxCount) {
              maxCount = Number(countString);
            }
          }
        }
      });

      resolve(++maxCount);
    });
  }

  async updateJob(job: Job): Promise<any> {
    console.log("UPDATE JOB: ", job);

    const docRef = doc(fireDb, `jobs/${job.id}`);
    return setDoc(docRef, job.getJobData(), { merge: true });
  }

  async updateJobFuture(job: Job): Promise<any> {
    console.log("UPDATE JOB: ", job);

    const docRef = doc(fireDb, `jobsfuture/${job.id}`);
    return setDoc(docRef, job.getJobData(), { merge: true });
  }

  async moveUserToRelevant(user: UserProfile): Promise<void> {
    let relRef = doc(fireDb, `relevant/${user.id}`);
    let rolesRef = doc(fireDb, `roles/${user.id}`);
    try {
      return getDoc(rolesRef).then((rolesSnap) => {
        if(rolesSnap.data().role == "noob") {
          // console.log("USER:", user.getUserProfileData());
          setDoc(rolesRef,{ role: "beginner" });
          return setDoc(relRef,{ relevant: true }, { merge: true }).finally(() => {
              let userRef = doc(fireDb, `userProfile/${user.id}`);
              return setDoc(userRef,
                { phoneAppointment: "accepted" },
                { merge: true }
              );
          });
        }
      });
    } catch (error) {
      console.error("Error moving User to Relevant",error);
      return;
    }
  }
  async moveUserToRelevantBackend(): Promise<boolean> {
    // const url = `${cloudFunctionsUrlPrefix.euWest}/moveUserToRelevant`;
    const url = `${cloudFunctionsUrlPrefix.euWest}/moveUserToRelevantV2`;

    const authToken = await fireAuth
      .currentUser.getIdToken();
    const headers = new HttpHeaders({
      Authorization: "Bearer " + authToken,
    });
    const body = {
      uid: fireAuth.currentUser.uid
    };
    const res = await this.http.post(url, body, { headers: headers }).toPromise();

    console.log("RES", res);
    let success = false;
    try {
      if(res["res"]) {
        success = true;
      } else if (res["error"]) {
        console.error("Error moveUserToRelevantBackend(): ", res["error"]);
      }
    } catch(error) {
      console.error("Error moveUserToRelevantBackend(): ", error)
    }
    return success;
  }


  async createAgreement(formData: FormData): Promise<boolean> {
    // const url = `${cloudFunctionsUrlPrefix.euWest}/createAgreement`;
    const url = `${cloudFunctionsUrlPrefix.euWest}/createAgreementV2`;

    const authToken = await fireAuth
      .currentUser.getIdToken();
    const headers = new HttpHeaders({
      Authorization: "Bearer " + authToken,
    });
    const res = await this.http.post(url, formData, { headers: headers }).toPromise();
    
    console.log("RES", res);
    let out: string;
    try {
      if(res["res"]) {
        out = res["res"];
      } else if (res["error"]) {
        console.error("Error createAgreement(): ", res["error"]);
        out = res["errorType"];
      }
    } catch(error) {
      console.error("Error createAgreement(): ", error)
      out = "error";
    }
    return out == "SUCCESS";
  }

  async cancelAgreement(userId: string, agreementId: string, reason: string): Promise<string> {
    // const url = `${cloudFunctionsUrlPrefix.euWest}/cancelAgreement`;
    const url = `${cloudFunctionsUrlPrefix.euWest}/cancelAgreementV2`;

    const authToken = await fireAuth
      .currentUser.getIdToken();
    const headers = new HttpHeaders({
      Authorization: "Bearer " + authToken
    });

    const paramsData = new HttpParams().set("userId", userId).set("agreementId", agreementId).set("reason", reason);

    let out: string
    try {
    const res = await this.http.get(url, { headers: headers, params: paramsData }).toPromise();
  
    console.log("RES cancelAgreement", res);
      if(res["res"]) {
        out = res["res"];
      } else if (res["error"]){
        console.error("Error cancelAgreement: ", res["error"]);
        out = res["errorType"];
      }
    } catch(error) {
      console.error("Error cancelAgreement: ", error)
      out = "error";
    }
    return out;

  }
  /**
   * modifies the document by writing "Annulliert" across all pages
   */
  async annulDocument(userId: string, agreementId: "mainAgreement" | string, fileUrl: string, fileName: string): Promise<string> {
    //const url = `${cloudFunctionsUrlPrefix.euWest}/annulDocument`;
    const url = `${cloudFunctionsUrlPrefix.euWest}/annulDocumentV2`;
    const authToken = await fireAuth
      .currentUser.getIdToken();
    const headers = new HttpHeaders({
      Authorization: "Bearer " + authToken
    });

    const paramsData = new HttpParams().set("userId", userId).set("agreementId", agreementId).set("fileUrl", fileUrl).set("fileName",fileName);

    let out: string
    try {
    const res = await this.http.get(url, { headers: headers, params: paramsData }).toPromise();
  
    console.log("RES annulDocument", res);
      if(res["res"]) {
        out = res["res"];
      } else if (res["error"]){
        console.error("Error annulDocument: ", res["error"]);
        out = res["errorType"];
      }
    } catch(error) {
      console.error("Error annulDocument: ", error)
      out = "error";
    }
    return out;

  }


  async sendNewFileNotification(finalApplications: Application[], jobId: string, fileName: string): Promise<any> {
    //const url = `${cloudFunctionsUrlPrefix.euWest}/sendNewFileNotification`;
    const url = `${cloudFunctionsUrlPrefix.euWest}/sendNewFileNotificationV2`;
    const authToken = await fireAuth
      .currentUser.getIdToken();
    const headers = new HttpHeaders({
      Authorization: "Bearer " + authToken
    });

    let data = {
      finalApplications: finalApplications,
      jobId: jobId,
      fileName: fileName
    }

    let out: string
    try {
      const res = await this.http.post(url, data, { headers: headers }).toPromise();
  
    console.log("sendNewFileNotification RES", res);
      if(res["res"]) {
        out = res["res"];
      } else if (res["error"]){
        console.error("Error sendNewFileNotification(): ", res["error"]);
        out = res["errorType"];
      }
    } catch(error) {
      console.error("Error sendNewFileNotification: ", error)
      out = "error";
    }
    return out;
  }

  async sendTimesheetChangesNotification(userTimesheetChanges: UserTimesheetDifference[]): Promise<any> {
    // const url = `${cloudFunctionsUrlPrefix.euWest}/sendTimesheetChangesNotification`;
    const url = `${cloudFunctionsUrlPrefix.euWest}/sendTimesheetChangesNotificationV2`;

    const authToken = await fireAuth
      .currentUser.getIdToken();
    const headers = new HttpHeaders({
      Authorization: "Bearer " + authToken
    });

    let data = {
      userTimesheetChanges: userTimesheetChanges
    }

    let out: string
    try {
      const res = await this.http.post(url, data, { headers: headers }).toPromise();
  
    console.log("sendTimesheetChangesNotification RES", res);
      if(res["res"]) {
        out = res["res"];
      } else if (res["error"]){
        console.error("Error sendTimesheetChangesNotification(): ", res["error"]);
        out = res["errorType"];
      }
    } catch(error) {
      console.error("Error sendTimesheetChangesNotification: ", error)
      out = "error";
    }
    return out;
  }

  async getTaxDetailsPdf(aUserId: string): Promise<any> {
    let loading = await this.loadingCtrl.create({
      message:
        "<img src='/assets/icon/Ladeanimation.svg' alt='loading...'></img><p>Empfange Daten...<p>",
      translucent: true,
      spinner: null,
      cssClass: "chillload",
      backdropDismiss: true,
    });
    await loading.present();

    // const url = `${cloudFunctionsUrlPrefix.euWest}/getTaxDetailsPdf`;
    const url = `${cloudFunctionsUrlPrefix.euWest}/getTaxDetailsPdfV2`;

    const authToken = await fireAuth
      .currentUser.getIdToken();
    let headers = new HttpHeaders({
      Authorization: "Bearer " + authToken
    });
    const paramsData = new HttpParams().set("userId", aUserId);
    let out: any
    try {
    const res = await this.http.get(url, { headers: headers, params: paramsData}).toPromise();

    loading.dismiss();

    console.log("getTaxDetailsPdf RES", res);
      if(res["res"]) {
        out = res["pdf"];
      } else if (res["error"]){
        console.error("Error getTaxDetailsPdf(): ", res);
        if(res["errorType"] == "invalidTaxChanges") {
          out = await new Promise<any> (async (resolve) => {
            let errorMsg = res["error"];
            let missingData = res["invalidTaxData"];
            errorMsg += "<ul>";
            missingData.forEach(element => {
              errorMsg += "<li>" + this.names[element] + "</li>";
            });
            errorMsg += "</ul>";
            errorMsg += "Die lezten validierten Abrechnungsdaten werden runtergeladen.";

            const alert = await this.alertController.create({
              header: "Abrechnungsdaten unvollständig",
              message: new IonicSafeString (errorMsg),
              buttons: [
                { text: "OK",
                handler: () => {
                  resolve(res["pdf"]);
                }
              }
              ],
            });
            await alert.present();
          });
        } else if(res["errorType"] == "noTaxData") {
          ToastService.makeLowToast(res["error"]);
        }
      }
    } catch(error) {
      console.error("Error getTaxDetailsPdf: ", error)
      ToastService.makeToast(
        "Fehler in der Verarbeitung, checke die Logs."
      );
    }
    return out;
  }

  async getUserUtilization(userId: string): Promise<UserUtilization | undefined> {
    let userUtil = undefined;
    const userUtilRef = doc(fireDb,`userUtilization/${userId}`);
    const userUtilSnap = await getDoc(userUtilRef);
    if(userUtilSnap.data()) {
      userUtil = UserUtilization.initFromObject(userUtilSnap.data());
      if(!userUtil) {
        console.error("getUserUtilization() - Timesheet not available for userId:",userId)
      }
    }
    return userUtil;
  }
  async getUserUtilizationsForMonth(aMonth: string): Promise<UserUtilization[]> {
    let userUtils: UserUtilization[] = [];

    try {
      const userUtilsQuery = query(collection(fireDb, 'userUtilization'), where('months', 'array-contains', aMonth));
      const uuSnap = await getDocs(userUtilsQuery);
      for (const doc of uuSnap.docs) {
        if(doc.data()) {
          const userUtil = UserUtilization.initFromObject(doc.data());
          userUtils.push(userUtil);
        }
      }      
    } catch (e) {
      console.error("getUserUtilizationsForMonth() - Catch Error getting UserUtilizations for month:",e,aMonth);
    }

    return userUtils;
  }
  async setUserUtilization(userId: string, aUserUtil: UserUtilization): Promise<any> {
    const userUtilRef = doc(fireDb,`userUtilization/${userId}`);
    return setDoc(userUtilRef,aUserUtil.getData());
  }

  async sendLugExport(finalApplications: Application[], jobId: string): Promise<any> {
    // const url = `${cloudFunctionsUrlPrefix.euWest}/sendLugExport`;
    const url = `${cloudFunctionsUrlPrefix.euWest}/sendLugExportV2`;

    const authToken = await fireAuth
      .currentUser.getIdToken();
    const headers = new HttpHeaders({
      Authorization: "Bearer " + authToken
    });

    let data = {
      finalApplications: finalApplications,
      jobId: jobId,
    }

    let out: string = "";
    try {
      const res = await this.http.post(url, data, { headers: headers }).toPromise();
  
      console.log("sendLugExport RES", res);
      if(res["res"]) {
        out = res["res"];
        // out = res["file"];
      } else if (res["error"]){
        console.error("Error sendLugExport(): ", res["error"], res["errorType"]);
      }
    } catch(error) {
      console.error("Error sendLugExport: ", error)
    }
    return out;
  }

  async getUserProfile(userId: string): Promise<UserProfile> {
    let userRef = doc(fireDb, `userProfile/${userId}`);
    let userProfile = new UserProfile();

    return getDoc(userRef).then(async (snapShot) => {
      let dataSnap = snapShot.data();
      if (dataSnap) {
        userProfile = UserProfile.initFromObject(dataSnap);
      }
      return userProfile;
    });
  }
  async getUserProfiles(): Promise<QuerySnapshot<DocumentData>> {
    //userProfiles
    this.allUsers = collection(fireDb, `userProfile/`);
    return getDocs(this.allUsers);
  }

  async getWorkerProfiles(): Promise<QuerySnapshot<DocumentData>> {
    this.allUsers = collection(fireDb, `userProfile/`);
    const q = query(this.allUsers, orderBy("lastName"), limit(10));
    return getDocs(q);
  }

  async getUserProfileObjects(): Promise<UserProfile[]> {
    return this.getUserProfiles().then((snap) => {
      let users: UserProfile[] = [];
      console.log("UPS: ", snap);
      snap.forEach((s) => {
        let dataSnap = s.data();
        // console.log("USERDATA: ", dataSnap);
        let up = UserProfile.initFromObject(dataSnap);
        users.push(up);
      });
      this.allUserProfiles = users;
      // console.log("ALLUSERS: ", users);
      return users;
    });
  }

  async getWorkerProfileObjects(): Promise<UserProfile[]> {
    if (this.users) {
      return this.users;
    } else {
      return this.initWorkerProfileObjects();
    }
  }

  async initWorkerProfileObjects(): Promise<UserProfile[]> {
    return this.getWorkerProfiles().then((snap) => {
      let users: UserProfile[] = [];
      console.log("UPS: ", snap);
      snap.forEach((s) => {
        let dataSnap = s.data();
        console.log("USERDATA: ", dataSnap);

        let up = UserProfile.initFromObject(dataSnap);
        users.push(up);
      });
      this.allUserProfiles = users;
      console.log("ALLWORKERS: ", users);
      this.users = users;
      return users;
    });
  }

  async putJobIntoMarket(job: Job) {
    let marketRef = doc(fireDb, `market/${job.id}`);
    let j = job.getCensoredJobData();
    await this.changeNewJobsCount(job.id, "add");
    return setDoc(marketRef,j);
  }

  async removeJobFromMarket(job: Job): Promise<void> {
    //DBJobs/Archive
    let marketArchiveRef = doc(fireDb, `marketArchive/${job.id}`);
    //Jobangebote
    let marketRef = doc(fireDb, `market/${job.id}`);
    console.log("JOBPUT", job);
    await setDoc(marketArchiveRef,job.getCensoredJobData());
    await this.changeNewJobsCount(job.id,"remove");
    
    return deleteDoc(marketRef);
  }


  saveTimeSheet(
    job: Job,
    pi: number,
    uid: string,
    worktimes: RealWorktime[],
    mode: "planned" | "actual"
  ) {
    let timeSheetRef = doc(fireDb, `timesheets/${uid}-${job.id}-${pi}`);
    let wts: any[] = [];
    for (let i = 0; i < worktimes.length; i++) {
      const wt = worktimes[i];
      wts.push(RealWorktime.getWorkTimeData(wt));
    }
    let months = worktimes.map(item => moment(item.date,"YYYY-MM-DD").format("MM/YYYY"))
    months = [...new Set(months)]; // uniquifies duplicates

    if(mode=="planned") {
      return updateDoc(timeSheetRef, { "timesheet.plannedTimesheet": wts, "timesheet.months": months });
    } else {
      return updateDoc(timeSheetRef, { "timesheet.actualTimesheet": wts });
    }
  }
  setIndividualPay(
    uid: string,
    jobId: string,
    pi: number,
    individualPay: IndividualPay[]
  ) {
    let timeSheetRef = doc(fireDb, `timesheets/${uid}-${jobId}-${pi}`);
    return updateDoc(timeSheetRef, { "timesheet.individualPay": individualPay });
  }

  async getTimeSheet(a: Application): Promise<Timesheet | undefined> {
    let timeSheetRef = doc(fireDb, `timesheets/${a.userid}-${a.jobid}-${a.positionindex}`);

    try {
      const tsSnap = await getDoc(timeSheetRef);
      if(tsSnap.data()) {
        const uts = UserTimesheet.initFromObject(tsSnap.data())
        // console.log("GETTIMESHEET", ts);
        return uts.timesheet;
      } else {
        console.error("getTimeSheet() - Timesheet does not exist for application:",a);
        return undefined;
      }
      
    } catch (e) {
      console.error("getTimeSheet() - Catch Error getting timesheet for application:",e,a);
      return undefined;
    }
  }
  async getTimesheetsForMonth(aMonth: string): Promise<UserTimesheet[]> {
    const timesheets: UserTimesheet[] = [];
    
    try {
      const timesheetsQuery = query(collection(fireDb, 'timesheets'), where('timesheet.months', 'array-contains', aMonth));
      const tsSnap = await getDocs(timesheetsQuery);
      for (const doc of tsSnap.docs) {
        if(doc.data()) {
          const timesheet = UserTimesheet.initFromObject(doc.data());
          timesheets.push(timesheet);
        }
      }      
    } catch (e) {
      console.error("getTimesheetsForMonth() - Catch Error getting timesheets for month:",e,aMonth);
    }

    return timesheets;
  }
  async getTimesheetsForJob(aJobId: string): Promise<UserTimesheet[]> {
    const timesheets: UserTimesheet[] = [];
    
    try {
      const timesheetsQuery = query(collection(fireDb, 'timesheets'), where("jobId", "==", aJobId));
      const tsSnap = await getDocs(timesheetsQuery);
      for (const doc of tsSnap.docs) {
        if(doc.data()) {
          const timesheet = UserTimesheet.initFromObject(doc.data());
          timesheets.push(timesheet);
        }
      }      
    } catch (e) {
      console.error("getTimesheetsForJob() - Catch Error getting timesheets for jobId:",e,aJobId);
    }

    return timesheets;
  }

  

  async setMoneyPending(a: Application): Promise<any> {
    const timesheetRef = doc(fireDb, `timesheets/${a.userid}-${a.jobid}-${a.positionindex}`);
    return updateDoc(timesheetRef, { "timesheet.paymentStatus": "pending" });
  }
  async setReadyToPay(a: Application): Promise<any> {
    const timesheetRef = doc(fireDb, `timesheets/${a.userid}-${a.jobid}-${a.positionindex}`);
    return updateDoc(timesheetRef, { "timesheet.paymentStatus": "readyToPay" });
  }
  async setReadyToPayPartly(a: Application): Promise<any> {
    const timesheetRef = doc(fireDb, `timesheets/${a.userid}-${a.jobid}-${a.positionindex}`);
    return updateDoc(timesheetRef, { "timesheet.paymentStatus": "readyToPayPartly" });
  }
  // async setMoneyPaid(a: Application): Promise<any> {
  //   const timesheetRef = doc(fireDb, `timesheets/${a.userid}-${a.jobid}-${a.positionindex}`);
  //   return updateDoc(timesheetRef, { "timesheet.paymentStatus": "paid" });
  // }
  async setMoneyPaid(uts: UserTimesheet): Promise<any> {
    const timesheetRef = doc(fireDb, `timesheets/${uts.userId}-${uts.jobId}-${uts.posIndex}`);
    return updateDoc(timesheetRef, { "timesheet.paymentStatus": "paid" });
  }
  async setMoneyPaidPartly(uts: UserTimesheet): Promise<any> {
    const timesheetRef = doc(fireDb, `timesheets/${uts.userId}-${uts.jobId}-${uts.posIndex}`);
    return updateDoc(timesheetRef, { "timesheet.paymentStatus": "partlyPaid" });
  }

  async isReadyToPay(a: Application): Promise<boolean> {
    const timesheetRef = doc(fireDb, `timesheets/${a.userid}-${a.jobid}-${a.positionindex}`);
    try {
      const snap = await getDoc(timesheetRef);
      const userTimesheet = UserTimesheet.initFromObject(snap.data());
      return userTimesheet.timesheet.isReadyToPay();
    } catch (e) {
      return false;
    }
  }
  async isMoneyPaid(a): Promise<boolean> {
    const timesheetRef = doc(fireDb, `timesheets/${a.userid}-${a.jobid}-${a.positionindex}`);
    try {
      const snap = await getDoc(timesheetRef);
      const userTimesheet = UserTimesheet.initFromObject(snap.data());
      return userTimesheet.timesheet.isPaid();
    } catch (e) {
      return false;
    }
  }

  async getApplicationsByJobAndPosition(
    jobid: string,
    positionid: number
  ): Promise<Application[]> {
    console.log("COMPAREAPP:", this.applications);
    let positionapps = [];
    if (this.applicationsChanged) {
      console.log("APPLICATIONRELOAD");
      await this.initApplications();
      this.applicationsChanged = false;
    }

    console.log("POSAPPSPRO", this.applications);
    let jobidapps = this.applications.filter((a) => {
      return a.jobid === jobid;
    });
    console.log("POSAPPSPRO", jobidapps);

    positionapps = jobidapps.filter((a) => {
      console.log("COMPARE:", positionid === a.positionindex);
      return positionid === a.positionindex;
    });
    console.log("POSAPPSPRO", positionapps);

    return positionapps;
  }

  getUserById(id: string): Promise<UserProfile> {
    return this.queryForUser("id",id);
  }

  async getUserByIds(ids: string[]): Promise<UserProfile[]> { //max 10 Ids
    const userProfiles: UserProfile[] = [];

    try {
      const usersQuery = query(collection(fireDb, 'userProfile'), where('id', 'in', ids));
      const usersSnap = await getDocs(usersQuery);
      for (const doc of usersSnap.docs) {
        if(doc.data()) {
          const userProfile = UserProfile.initFromObject(doc.data());
          userProfiles.push(userProfile);
        }
      }
    } catch (error) {
      console.error("getUserByIds() - Error while querying userProfiles",error);
    }

    return userProfiles;
  }

  getUserByMail(mail: string): Promise<UserProfile> {
    return this.queryForUser("email",mail);
  }

  async getProfilePictureOfUser(id): Promise<string> {
    var pathReference = ref(fireStorage, "uploaddata/" + id + "/profilepicture.png");

    console.log("PROFILEPIC:", pathReference);
    let picUrl = "";
    try {
      picUrl = await getDownloadURL(pathReference);
    } catch (error) {
      console.error("Error getting ProfilePicture of userId: "+id,error);
    }
    return picUrl;
  }

  setUserApplication(
    jobid,
    positionindex,
    userid,
    status: string
  ): Promise<any> {
    const docRef = doc(fireDb, `applicationList/${jobid}-${positionindex}-${userid}`);
    return setDoc(docRef, { status: status }, { merge: true });
  }

  initApplications(): Promise<void> {
    this.applications = [];
    console.log("INITAPPLICATIONS1:");
    let appRef = collection(fireDb, "applicationList/");

    return getDocs(appRef).then((applicationCollection) => {
      applicationCollection.forEach((appSnap) => {
        let appdata = appSnap.data();
        const a = Application.initFromObject(appdata);
        this.applications.push(a);
      });
      return;
    });
  }

  getValidatedTaxDetails(uid: string): Promise<TaxDetails> {
    let tRef = doc(fireDb, "taxDetails/" + uid + "/");

    return getDoc(tRef)
      .then((tsnap) => {
        let tdata = tsnap.data();
        console.log("TDATA:", tdata);
        let td: TaxDetails = new TaxDetails();
        td.initWithObject(tdata.taxDetails);
        console.log("TDATA2:", td);

        return td;
      })
      .catch((e) => {
        return null;
      });
  }

  getApplications(jobid, positionindex): Array<Application> {
    if (this.applications) {
      return this.applications;
    }

    return [];
  }

  async getAllUserFiles(userid): Promise<StorageFileData[]> {
    const fileArray: StorageFileData[] = [];

    let listRef = ref(fireStorage, "uploaddata/" + userid + "/");
    console.log("LISTREF", listRef);
    const res = await listAll(listRef);
    for await (const itemRef of res.items) {
      console.log("ITEMREF", itemRef);
      const fileData: StorageFileData = {
        filename: "",
        fileurl: "",
        createdDate: ""
      };
      await getDownloadURL(itemRef).then((url) => {
        fileData.filename = itemRef.name;
        fileData.fileurl = url;
      });
      await getMetadata(itemRef).then((metadata) => {
        if ( metadata && metadata["timeCreated"]){
          const createdDate = moment(metadata["timeCreated"]).format();
          fileData.createdDate = createdDate;
        }
      });
      fileArray.push(fileData);
    }
    return fileArray;
  }

  async getUserFile(userid, type): Promise<StorageFileData[]> {
    const fileArray: StorageFileData[] = [];

    let listRef = ref(fireStorage, "uploaddata/" + userid + "/");
    console.log("LISTREF", listRef);
    const res = await listAll(listRef);
    for await (const itemRef of res.items) {
      console.log("ITEMREF", itemRef);
      if ((type === "body" && itemRef.name === "body.png") ||
      (type === "portrait" && itemRef.name === "profilepicture.png") ||
      (type === "imma" && itemRef.name === "imma.pdf") ||
      (type === "residencePermit" && itemRef.name === "aufenthalt.pdf")) {
        const fileData: StorageFileData = {
          filename: "",
          fileurl: "",
          createdDate: ""
        };
        await getDownloadURL(itemRef).then((url) => {
          fileData.filename = itemRef.name;
          fileData.fileurl = url;
        });
        await getMetadata(itemRef).then((metadata) => {
          if ( metadata && metadata["timeCreated"]){
            const createdDate = moment(metadata["timeCreated"]).format();
            fileData.createdDate = createdDate;
          }
        });
        fileArray.push(fileData);
      }
    }
    return fileArray;
  }
  async getAgreementFile(user: UserProfile, agreementId: string): Promise<StorageFileData[]> {
    const fileArray: StorageFileData[] = [];

    let listRef = ref(fireStorage, "uploaddata/" + user.id + "/agreements/" + agreementId);
    const agreementList = await listAll(listRef);
    for await (const fileRef of agreementList.items) { //base contract
      const fileData: StorageFileData = {
        filename: "",
        fileurl: "",
        createdDate: ""
      };
      await getDownloadURL(fileRef).then((url) => {
        fileData.filename = fileRef.name;
        fileData.fileurl = url;
      });
      await getMetadata(fileRef).then((metadata) => {
        if ( metadata && metadata["timeCreated"]){
          const createdDate = moment(metadata["timeCreated"]).format();
          fileData.createdDate = createdDate;
        }
      });
      fileArray.push(fileData);
    }
    return fileArray;
  }
  async getExtendedAgreementFile(user: UserProfile, baseAgreementId: string, extendedAgreementId: string): Promise<StorageFileData[]> {
    const fileArray: StorageFileData[] = [];

    let listRef = ref(fireStorage, "uploaddata/" + user.id + "/agreements/" + baseAgreementId + "/" + extendedAgreementId);
    const agreementList = await listAll(listRef);
    for await (const fileRef of agreementList.items) { //base contract
      const fileData: StorageFileData = {
        filename: "",
        fileurl: "",
        createdDate: ""
      };
      await getDownloadURL(fileRef).then((url) => {
        fileData.filename = fileRef.name;
        fileData.fileurl = url;
      });
      await getMetadata(fileRef).then((metadata) => {
        if ( metadata && metadata["timeCreated"]){
          const createdDate = moment(metadata["timeCreated"]).format();
          fileData.createdDate = createdDate;
        }
      });
      fileArray.push(fileData);
    }
    return fileArray;
  }
  async getExtendedAgreementFiles(user: UserProfile, agreementId: string): Promise<StorageFileData[]> {
    const fileArray: StorageFileData[] = [];

    let listRef = ref(fireStorage, "uploaddata/" + user.id + "/agreements/" + agreementId);
    const agreementList = await listAll(listRef);
    for await (const agreementSubRef of agreementList.prefixes) { //extended Contracts
      if(!agreementSubRef.name.includes("merged")) {
        const agreementFilesList = await listAll(agreementSubRef);

        for await (const fileRef of agreementFilesList.items) {
          const fileData: StorageFileData = {
            filename: "",
            fileurl: "",
            createdDate: ""
          };
          await getDownloadURL(fileRef).then((url) => {
            fileData.filename = fileRef.name;
            fileData.fileurl = url;
          });
          await getMetadata(fileRef).then((metadata) => {
            if ( metadata && metadata["timeCreated"]){
              const createdDate = moment(metadata["timeCreated"]).format();
              fileData.createdDate = createdDate;
            }
          });
          fileArray.push(fileData);
        }
      }
    }

    // Filter the fileArray to remove original files if annulled versions exist
    const filteredFileArray = fileArray.filter((file) => {
      // Check if a corresponding annulled file exists
      if (!file.filename.includes("_annulled.pdf")) {
        const annulledFileName = file.filename.replace(".pdf", "_annulled.pdf");
        return !fileArray.some(f => f.filename === annulledFileName); // Return false if annulled version exists
      }
      return true; // Keep annulled files
    });


    return filteredFileArray;
  }
  async getMergedAgreementFiles(user: UserProfile): Promise<StorageFileData[]> {
    const fileArray: StorageFileData[] = [];

    let contractsRef = ref(fireStorage, "uploaddata/" + user.id + "/agreements/");
    const agreementsList = await listAll(contractsRef);
    for await (const agreementRef of agreementsList.prefixes) {
      const agreementsSubList = await listAll(agreementRef);
      for await (const agreementSubRef of agreementsSubList.prefixes) {
        if(agreementSubRef.name.includes("merged") || agreementSubRef.name.includes("annulled")) {
          const agreementFilesList = await listAll(agreementSubRef);
  
          for await (const fileRef of agreementFilesList.items) {
            const fileData: StorageFileData = {
              filename: "",
              fileurl: "",
              createdDate: ""
            };
            await getDownloadURL(fileRef).then((url) => {
              fileData.filename = fileRef.name;
              fileData.fileurl = url;
            });
            await getMetadata(fileRef).then((metadata) => {
              if ( metadata && metadata["timeCreated"]){
                const createdDate = moment(metadata["timeCreated"]).format();
                fileData.createdDate = createdDate;
              }
            });
            fileArray.push(fileData);
          }
        }
      }
    }
    return fileArray;
  }
  /**
   * 
   * @param user 
   * @param month Payrolls for user and Month will be searched. Month format MM/YYYY
   * @returns 
   */
  async getPayrollFiles(user: UserProfile, aMonth: string = ""): Promise<StorageFileData[]> {
    let fileArray: StorageFileData[] = [];
    const month = moment(aMonth,"MM/YYYY")
    let formattedMonth = "";
    if(month.isValid()) {
      formattedMonth = month.format("YYYY-MM");
    }

    let folderRef = ref(fireStorage, "uploaddata/" + user.id + "/payrolls/" + formattedMonth);
    const dirFilesList = await listAll(folderRef);
    const dirFiles = await getFiles(dirFilesList);

    let subdirFiles: StorageFileData[] = [];
    if(!formattedMonth) { // search in subfolders for payrolls
      for await (const subDirRef of dirFilesList.prefixes) {
        const subdirFilesList = await listAll(subDirRef);
        const filesList = await getFiles(subdirFilesList)
        subdirFiles.push(...filesList);
      }
    }

    fileArray = dirFiles.concat(subdirFiles);
    return fileArray;

    async function getFiles(listResult: ListResult) {
      const files: StorageFileData[] = [];
      for await (const fileRef of listResult.items) {
        const fileData: StorageFileData = {
          filename: "",
          fileurl: "",
          createdDate: ""
        };
        await getDownloadURL(fileRef).then((url) => {
          fileData.filename = fileRef.name;
          fileData.fileurl = url;
        });
        await getMetadata(fileRef).then((metadata) => {
          if (metadata && metadata["timeCreated"]) {
            const createdDate = moment(metadata["timeCreated"]).format();
            fileData.createdDate = createdDate;
          }
        });
        files.push(fileData);
      }
      return files;
    }
  }
  async getOldContractFiles(user: UserProfile): Promise<StorageFileData[]> {
    const fileArray: StorageFileData[] = [];

    let contractsRef = ref(fireStorage, "uploaddata/" + user.id + "/contracts/");
    const folderList = await listAll(contractsRef);
    for await (const folderRef of folderList.prefixes) {
        const subdirFilesList = await listAll(folderRef);
        for await (const fileRef of subdirFilesList.items) {
          await getMetadata(fileRef).then(async (metadata) => {
            if ( metadata && metadata["timeCreated"]){
              const createdDate = moment(metadata["timeCreated"]);
              const filenameArr = fileRef.name.split(".");
              const fileSuffix = filenameArr.pop();
              const baseName = filenameArr.join(".") + "_" + createdDate.format("YYYYMMDD");
              let fileName = baseName + "." + fileSuffix;
              let i = 2; // handle duplicates
              while (fileArray.some(file => file.filename === fileName)) {
                fileName = baseName + "_" + i + "." + fileSuffix;
                i++;
              }

              await getDownloadURL(fileRef).then((url) => {
                fileArray.push({filename: fileName, fileurl: url, createdDate: createdDate.format()});
              });
            }
          });
        }
    }
    return fileArray;
  }

  async existsStorageFile(aUrl: string): Promise<boolean> {
    let listRef = ref(fireStorage,aUrl);

    return getDownloadURL(listRef)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  getAllJobFiles(jobid): Promise<StorageFileData[]> {
    const fileArray: StorageFileData[] = [];

    let listRef = ref(fireStorage, "jobuploaddata/" + jobid + "/");
    console.log("LISTREF", listRef);
    return listAll(listRef).then((res) => {
      res.items.forEach(async (itemRef) => {
        console.log("ITEMREF", itemRef);
        const fileData: StorageFileData = {
          filename: "",
          fileurl: "",
          createdDate: ""
        };
        await getDownloadURL(itemRef).then((url) => {
          fileData.filename = itemRef.name;
          fileData.fileurl = url;
        });
        await getMetadata(itemRef).then((metadata) => {
          if ( metadata && metadata["timeCreated"]){
            const createdDate = moment(metadata["timeCreated"]).format();
            fileData.createdDate = createdDate;
          }
        });
        fileArray.push(fileData);
      });
      return fileArray;
    });
  }

  private setApplicationStatus(a: Application, status: ApplicationStatus): Promise<any> {
    this.applicationRef = doc(fireDb, `applicationList/${a.jobid}-${a.positionindex}-${a.userid}`);
    this.applicationsChanged = true;
    return setDoc(this.applicationRef,
      {
        jobid: a.jobid,
        positionindex: a.positionindex,
        userid: a.userid,
        status: status,
      },
      { merge: true }
    );
  }

  applyToJobByData(jobid, positionindex, userid): Promise<any> {
    let a: Application = new Application();
    a.jobid = jobid;
    a.positionindex = positionindex;
    a.userid = userid;
    return this.applyToJob(a);
  }

  applyToJob(a: Application): Promise<any> {
    return this.canUserTakePosition(a).then((res) => {
      if (res === "") return this.setApplicationStatus(a, "pending");

      return Promise.reject(res);
    });
  }

  async acceptApplication(a: Application): Promise<any> {
    let errorMessage: string = await this.canUserTakePosition(a);
    console.log("acceptApplication() - canUserTakePosition:", errorMessage);
    if (errorMessage == "") return this.setApplicationStatus(a, "accepted");
    return Promise.reject(errorMessage);
  }
  declineApplication(a: Application): Promise<any> {
    return this.setApplicationStatus(a, "declined");
  }
  cancelApplication(a: Application): Promise<any> {
    return this.setApplicationStatus(a, "shortNoticeCancellation");
  }

  deleteApplication(a: Application): Promise<any> {
    this.applicationRef = doc(fireDb, `applicationList/${a.jobid}-${a.positionindex}-${a.userid}`);
    return deleteDoc(this.applicationRef);
  }

  async getAcceptedApplicationsForPosition(
    jobid,
    positionindex
  ): Promise<Application[]> {
    let applications = await this.getApplicationsByJob(jobid);
    let acceptedApplications = applications.filter((aa) => {
      return aa.positionindex == positionindex && aa.status == "accepted";
    });
    return acceptedApplications;
  }

  async getFinallyAcceptedApplicationsForPosition(
    jobid,
    positionindex
  ): Promise<Application[]> {
    let applications = await this.getApplicationsByJob(jobid);
    let acceptedApplications = applications.filter((aa) => {
      return (
        aa.positionindex == positionindex && aa.status == "finallyaccepted"
      );
    });
    return acceptedApplications;
  }

  async canUserTakePosition(a: Application): Promise<string> {
    let acceptedApplications: Application[] =
      await this.getFinallyAcceptedApplicationsForPosition(
        a.jobid,
        a.positionindex
      );
    let jobRef = doc(fireDb, `jobs/${a.jobid}`);

    return getDoc(jobRef)
      .then((docSnap) => {
        let dataBaseJob = Job.initFromObject(docSnap.data());

        if (dataBaseJob &&
          Number(dataBaseJob.positions[a.positionindex].amount) >
          acceptedApplications.length
        )
          return "";

        return `Keine Position mehr frei. (${acceptedApplications.length}/${
          dataBaseJob.positions[a.positionindex].amount
        }) eingestellt`;
      })
      .catch(() => {
        return "error";
      });
  }

  getProfilePic(id: string) {
    var pathReference = ref(fireStorage, "uploaddata/" + id + "/profilepicture.png");

    return getDownloadURL(pathReference)
      .then((url) => {
        return url;
      })
      .catch(() => {
        console.warn("No Profile Pic for userId:",id);
        return null;
      });
  }

  getBodyPic(id: string) {
    var pathReference = ref(fireStorage, "uploaddata/" + id + "/body.png");

    return getDownloadURL(pathReference)
      .then((url) => {
        return url;
      })
      .catch(() => {
        console.warn("No Body Pic for userId:",id);
        return null;
      });
  }

  getImmaState(id: string) {
    var pathReference = ref(fireStorage, "uploaddata/" + id + "/imma.pdf");
    let immaState = "";

    return getMetadata(pathReference)
    .then((metadata) => {
      if ( metadata && metadata["timeCreated"]){
        const createdDate = moment(metadata["timeCreated"]);
        //WS: von 01.09-28.02 (Sep-EndeFeb)
        //SS: von 01.03-31.08 (März-EndeAugust)
        const dateNow = moment();
        const currentYear = dateNow.year();
        //const isSs = dateNow.isBetween(currentYear+"-03-01",currentYear+"-08-31");
        let isWs: boolean;
        if (dateNow.month() < 2) {
          isWs = dateNow.isBetween(currentYear-1+"-09-01",currentYear+"-02-28");
        } else {
          isWs = dateNow.isBetween(currentYear+"-09-01",currentYear+1+"-02-28");
        }

        let isValid: boolean;
        if(isWs) {
          if (createdDate.month() < 2) {
            isValid = createdDate.isBetween(currentYear-1+"-09-01",currentYear+"-02-28");
          } else {
            isValid = createdDate.isBetween(currentYear+"-09-01",currentYear+1+"-02-28");
          }
        } else {
          isValid = createdDate.isBetween(currentYear+"-03-01",currentYear+"-08-31");
        }

        if(isValid) {
          immaState = "valid";
        } else {
          immaState = "expired";
        }
        return immaState;

      } else {
        return ""; 
      }

    })
    .catch(() => {
      return "";
    });

    /*return pathReference
    .getDownloadURL()
    .then((url) => {
      return url;
    })
    .catch(() => {
      return null;
    });*/

  }

  async setDeviceToken(userId: string, userDeviceToken: string) {
    if(this.fcmToken && this.fcmToken != userDeviceToken) {
      let userRef = doc(fireDb, `userProfile/${userId}`);
      await setDoc(userRef, {deviceToken: this.fcmToken}, { merge: true });
    }
  }

  async setUserUpdatesObservable(userId: string) {
    //Observable for Badge Changes
    const userUpdatesRef = doc(fireDb, `userUpdates/${userId}`);
    const unsub = onSnapshot(userUpdatesRef, (doc) => {
      if(doc.data() && doc.data().newJobs) {
        const newJobsArray: string[] = doc.data().newJobs;
        this.newJobsSubject.next(newJobsArray)
      } else {
        this.newJobsSubject.next([])
      }
      console.log(`setUserUpdatesObservable() - fireDb document userUpdates/${userId} changed: `, doc.data());
    });
  }

  getNewJobsSubject() {
    return this.newJobsSubject.asObservable();
  }
  getNewJobsValue() {
    return this.newJobsSubject.getValue();
  }

  async resetNewJobsCount(userId: string) {
    const userUpdatesRef = doc(fireDb, `userUpdates/${userId}`);
    await setDoc(userUpdatesRef, { newJobs: [] }, { merge: true });
  }
  async removeNewJobsCount(userId: string, jobId: string) {
    const userUpdatesRef = doc(fireDb, `userUpdates/${userId}`);

    const newJobsArray = this.getNewJobsValue();
    const jobIndex = newJobsArray.indexOf(jobId);
    if(jobIndex > -1) { // only splice array when item is found
      newJobsArray.splice(jobIndex,1);
      await setDoc(userUpdatesRef, { newJobs: newJobsArray }, { merge: true });
    }
  }

  setContractObservable(userId: string) {
    //Observable for Contract Changes
    const contractsRef = doc(fireDb, `contracts/${userId}`);
    if(!this.unsubscribeContractChange) {
      this.unsubscribeContractChange = onSnapshot(contractsRef, (doc) => {
        if(doc.data() && doc.data().contract) {
          const contract: Contract = Contract.initContractFromObject(doc.data().contract);
          this.changedContractSubject.next(contract)
        } else {
          console.warn(`setContractObservable() - fireDb document contracts/${userId} empty or invalid: `, doc.data());
          this.changedContractSubject.next(undefined)
        }
        console.log(`setContractObservable() - fireDb document contracts/${userId} changed: `, doc.data());
      },
      (error) => {
        console.warn(`setContractObservable() - error listening for contract changes `,error);
        this.changedContractSubject.next(undefined)
      });
    } else {
      console.log(`setContractObservable() - already subscribed`);
    }
  }
  async unsetUserUpdatesObservable() {
    //Unsubscribe ContractChanges Observale
    this.unsubscribeContractChange();
    this.unsubscribeContractChange = undefined;
    console.log(`unsetUserUpdatesObservable() - Contract changes unsubscribed`);
  }

  getContractChangedSubject() {
    return this.changedContractSubject.asObservable();
  }
  getContractChangedValue() {
    return this.changedContractSubject.getValue();
  }

  taxDetailsExists(uid): Promise<boolean> {
    let wref = doc(fireDb, `taxDetails/${uid}`);
    return getDoc(wref).then((doc) => {
      if (doc.exists()) return true;
      return false;
    });
  }

  updateUser(us: UserProfile) {
    if (us.id !== "") {
      let wref = doc(fireDb, `userProfile/${us.id}`);

      setDoc(wref, us.getUserProfileData(), { merge: true }).then(() => {
        ToastService.makeLowToast(
          `${
            us.firstName +
            " " +
            us.lastName +
            ", " +
            us.email +
            ", " +
            us.sedcard.phone +
            " "
          }Erfolgreich geupdated`
        );
      });
    } else {
      ToastService.makeLowToast("Fetter Fehler beim speichern");
    }
  }

  removeUser(user: UserProfile): Promise<void> {
    let wref = doc(fireDb, `workers/${user.id}`);
    this.users = this.users.filter((u) => {
      return u.id !== user.id;
    });
    return deleteDoc(wref);
  }

  /*createUser(us: UserProfile): Promise<void> {
    return this.userRef.add(us.getUserProfileData()).then((a) => {
      console.log(us.firstName, a.id);
      let wref = firebase.firestore().doc(`workers/${a.id}`);
      us.id = a.id;
      if (this.users) {
        this.users.push(us);
      }
      firebase
        .firestore()
        .doc(`backlog/${us.id}`)
        .set({ added: moment().format() }, { merge: true });
      return wref.set(us.getUserProfileData(), { merge: true });
    });
  }*/ //TODO: check if still in use

  async getPayrollMonths(): Promise<PayrollMonth[]> {
    let payrolls: PayrollMonth[] = [];
    let payrollsSnap = await getDocs(collection(fireDb, `payrollMonths/`));

    try {
      for (const payrollMonth of payrollsSnap.docs) {
        payrolls.push(PayrollMonth.initFromObject(payrollMonth.data()))
      }
    } catch (error) {
      console.error("getPayrollMonths() - ",error);
    }

    return payrolls;
  }
  async setPayrollMonth(aPayrollMonth: PayrollMonth): Promise<void> {
    const payrollRef = doc(fireDb, `payrollMonths/${moment(aPayrollMonth.month,"MM/YYYY").format("YYYY-MM")}`);
    return setDoc(payrollRef, aPayrollMonth.getData());
  }
  async addPayrollMonth(aPayrollMonth: PayrollMonth): Promise<void> {
    const payrollRef = doc(fireDb, `payrollMonths/${moment(aPayrollMonth.month,"MM/YYYY").format("YYYY-MM")}`);
    return setDoc(payrollRef, aPayrollMonth.getData());
  }

  getApplicationsByJob(jobid: string): Promise<Application[]> {
    let a: Application[] = [];
    let queryApplicationRef = query(collection(fireDb, `applicationList/`),
      where("jobid", "==", jobid));

    return getDocs(queryApplicationRef).then((snap) => {
      snap.forEach((doc) => {
        let newA = Application.initFromObject(doc.data());
        a.push(newA);
      });
      return a;
    });
  }

  queryForUser( searchKey: string, searchValue: string): Promise<UserProfile> {
    let up: UserProfile = null;

    let queryUserProfileRef = query(collection(fireDb, `userProfile/`),
      where(searchKey, "==", searchValue));

    return getDocs(queryUserProfileRef)
      .then((snap) => {
        // console.log("QUP!", snap);
        snap.forEach((doc) => {
          console.log("UD:", doc.data());
          up = UserProfile.initFromObject(doc.data());
          // console.log("UD:", up);
        });
        return up;
      })
      .catch((e) => {
        console.error("queryForUser() - ",e);
        return up;
      });
  }
  queryForUsers( searchKey: string, searchValue: string): Promise<UserProfile[]> {
    let ups: UserProfile[] = [];

    let queryUserProfileRef = query(collection(fireDb, `userProfile/`),
      where(searchKey, "==", searchValue));

    return getDocs(queryUserProfileRef)
      .then((snap) => {
        // console.log("QUP!", snap);
        snap.forEach((doc) => {
          console.log("UD:", doc.data());
          const up = UserProfile.initFromObject(doc.data());
          ups.push(up);
          // console.log("UD:", up);
        });
        return ups;
      })
      .catch((e) => {
        console.error("queryForUsers() - ",e);
        return ups;
      });
  }

  async jobIsInMarket(jobid): Promise<boolean> {
    let jref = doc(fireDb, `market/${jobid}`);

    return getDoc(jref).then((jSnap) => {
      if (jSnap.exists()) {
        return true;
      }
      return false;
    });
  }

  async getUserProfilesOrderedByLastname(
    aLimit: number,
    reverse: boolean = false
  ): Promise<UserProfile[]> {

    let ups: UserProfile[] = [];
    let queryUserProfileRef = query(collection(fireDb, "userProfile"), orderBy("lastName"), limit(aLimit));

    await getDocs(queryUserProfileRef)
      .then((snap) => {
        console.log("QUP!", snap);
        snap.forEach((doc) => {
          console.log("UD:", doc.data());

          let up = UserProfile.initFromObject(doc.data());
          ups.push(up);
        });
      });

    return ups;
  }

  async filterRelevant(
    ups: UserProfile[],
    reverse: boolean = false
  ): Promise<UserProfile[]> {
    let upsout: UserProfile[] = [];

    for (let i = 0; i < ups.length; i++) {
      // console.log("getRelevant", i);

      const u = ups[i];

      if (await this.isRelevant(u.id)) {
        console.log("YESRELEVANT HAHAHAAH", reverse);

        if (!reverse) upsout.push(u);
      } else {
        console.log("NOTRELEVANT HAHAHAAH", u);
        if (reverse) upsout.push(u);
      }
    }
    console.log("Relevant", upsout);
    return upsout;
  }

  async isNoob(id): Promise<boolean> {
    let r = await this.isRole("noob", id);
    return r;
  }
  async isRelevant(id): Promise<boolean> {
    let r = await this.isRole("beginner", id);

    if (r) return r;
    r = await this.isRole("admin", id);

    return r;
  }
  async isRole(str: string, id: string): Promise<boolean> {
    if (id) {
      let roleref = doc(fireDb, "roles/" + id + "/");
      return getDoc(roleref)
        .then((snap) => {
          let x = snap.data();
          if (x.role) return x.role === str;
          else return false;
        })
        .catch(() => {
          return false;
        });
    }
    return false;
  }
  async getAllNoobs(): Promise<string[]> {
    let ids: string[] = []
    const rolesQuery = query(collection(fireDb,'roles'), where("role", '==', "noob"));
    const rolesSnap = await getDocs(rolesQuery);
    for (const doc of rolesSnap.docs) {
      ids.push(doc.id);
    }
    return ids;
  }
  async getAllRelevants(): Promise<string[]> {
    let ids: string[] = []
    const rolesQuery = query(collection(fireDb,'roles'), where("role", '==', "beginner"));
    const rolesSnap = await getDocs(rolesQuery);
    for (const doc of rolesSnap.docs) {
      ids.push(doc.id);
    }
    return ids;
  }

  async searchUserProfiles(searchTerm: string, allowEmptyQuery = true): Promise<UserProfile[]> {
    let ups: UserProfile[] = [];
    console.log("searchterm:",searchTerm)
    if (searchTerm === "") {
      if(allowEmptyQuery) {
        ups = await this.getUserProfilesOrderedByLastname(1000);
      }
    } else {
      let queryUserProfileRef = query(collection(fireDb, "userProfile"), where("keywords", "array-contains", searchTerm), limit(1000));
  
      await getDocs(queryUserProfileRef)
        .then((snap) => {
          console.log("QUP!", snap);
          snap.forEach((doc) => {
            console.log("UD:", doc.data());
  
            let up = UserProfile.initFromObject(doc.data());
            ups.push(up);
          });
        });
    }
    return ups;
  }

  async searchNoobs(name: string): Promise<UserProfile[]> {
    if (name === "") {
      return [];
    }
    let ups: UserProfile[] = [];
    let queryUserProfileRef = collection(fireDb, "userProfile");
    let ups2: UserProfile[] = [];
    const q = query(queryUserProfileRef, where("keywords", "array-contains", name), limit(10));

    await getDocs(q)
      .then(async (snap) => {
        console.log("QUP!", snap);
        snap.forEach(async (doc) => {
          console.log("UD:", doc.data());
          let up = UserProfile.initFromObject(doc.data());
          console.log("SEARCHAAA");
          ups.push(up);
        });
        for (const us of ups) {
          if (!(await this.isRelevant(us.id))) ups2.push(us);
        }
      });

    return ups2;
  }

  createKeywords = (name) => {
    const arrName = [];
    let curName = "";
    name.split("").forEach((letter) => {
      curName += letter;
      arrName.push(curName.toLowerCase());
    });
    return arrName;
  };

  generateKeywords = (names) => {
    const [firstName, lastName] = names;

    const keywordFull = this.createKeywords(`${firstName} ${lastName}`);
    const keywordLastFirst = this.createKeywords(`${lastName} ${firstName}`);

    return [...new Set(["", ...keywordFull, ...keywordLastFirst])];
  };

  updateKeyWordsForAllUsers() {
    let counter = 0;
    this.getWorkerProfileObjects()
      .then((users) => {
        users.forEach((u) => {
          let keywords = this.generateKeywords([u.firstName, u.lastName]);
          u.keywords = keywords;

          let wref = doc(fireDb, `userProfile/${u.id}`);
          setDoc(wref, u.getUserProfileData(), { merge: true }).then(() => {
            counter++;
            console.log("ADDEDKEYWORDS: ", u);
            console.log("ADDEDKEYWORDS: ", keywords);
          });
        });
      })
      .catch((e) => {
        console.error("ERROR: ", e);
      })
      .finally(() => {
        console.log("ADDED KEYWORDS TO: ", counter);
      });
  }

  getAppointments(king: string): Promise<DocumentData> {
    let appointmentRef = doc(fireDb, `appointments/${king}`);
    return getDoc(appointmentRef)
      .then((snap) => {
        return snap.data();
      })
      .catch(() => {
        return {};
      });
  }
  deleteAppointment(king: string, id: string): Promise<any> {
    let appointmentRef = doc(fireDb, `appointments/${king}`);
    return getDoc(appointmentRef)
      .then((snap) => {
        let appointments = snap.data().appointments;
        console.log("BAMBAM", appointments);
        console.log("BAMBAM", id);
        console.log("BAMBAM", appointments[id]);
        console.log("BAMBAM", Object.entries(appointments));

        delete appointments[id];

        console.log("BAMBAM2", appointments);

        let appointmentRef2 = doc(fireDb, `appointments/${king}`);
        return setDoc(appointmentRef2,
          { appointments: appointments })
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      })
      .catch(() => {
        return false;
      });
  }

  getAppointmentsByDate(
    king: string,
    date: string
  ): Promise<{ date: string; time: string }[]> {
    let appointmentRef = doc(fireDb, `appointments/${king}`);

    return getDoc(appointmentRef).then((snap) => {
      let x = snap.data();
      if (x) {
        console.log("HI", Array.from(x.appointments));
        console.log("HI", x.appointments);
        let freeApp = [];

        Object.getOwnPropertyNames(x.appointments).forEach((p) => {
          if (x.appointments[p] === "") {
            let b = p.split(" - ")[0];
            let newp = {};
            newp["time"] = p.split(" - ")[1];
            newp["date"] = b;
            if (b === date) freeApp.push(newp);
          }
        });

        return freeApp;
      }
      return new Array<{ date: string; time: string }>(0);
    });
  }

  scheduleAppointment(king: string, app: string, uid: string) {
    let appointmentRef = doc(fireDb, `appointments/${king}/list/${app}`);

    setDoc(appointmentRef,
      { user: uid }, { merge: true })
      .then(async () => {
        let us = await this.getUserById(uid);
        us.phoneAppointment = app;
        await this.updateUser(us);
        ToastService.makeToast(
          "Dein Termin wurde gesetzt, Du kriegst eine Bestätigung per Mail"
        );
      })
      .catch((e) => {
        console.error("E", e);
        ToastService.makeToast(
          "Fehler beim Termin planen. Versuche es später nochmal"
        );
      });
  }

  setAppointments(appointments: Array<string>, king: string) {
    let appointmentRef = collection(fireDb, `appointments/${king}/list`);

    var batch = writeBatch(fireDb);

    appointments.forEach((app) => {
      var docRef = doc(appointmentRef, app); //automatically generate unique id
      batch.set(docRef, {
        user: "",
        date: moment(app, "DD.MM.YYYY - HH:mm").format("DD.MM.YYYY"),
        time: moment(app, "DD.MM.YYYY - HH:mm").format("HH:mm"),
      });
    });

    batch
      .commit()
      .then(() => {
        ToastService.makeToast("Alle Termine freigegeben");
      })
      .catch(() => {
        ToastService.makeToast("Fehler beim Erstellen");
      });
  }

  setAppointmentsEasy(appointments: object, king: string): Promise<boolean> {
    let appointmentRef = doc(fireDb, `appointments/${king}`);
    return setDoc(appointmentRef,
      { appointments: appointments })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  saveNewPartner(p: Partner): Promise<any> {
    let ratingRef = collection(fireDb, `partners`);
    if (p.isFilled()) return addDoc(ratingRef, p.getFireBaseData());
    return Promise.reject();
  }
  deletePartner(id: string): Promise<any> {
    let ratingRef = doc(fireDb, `partners/${id}`);
    return deleteDoc(ratingRef);
  }
  getAllPartners(): Promise<Partner[]> {
    let ratingRef = collection(fireDb, `partners`);
    let out: Partner[] = [];
    return getDocs(ratingRef)
      .then((snap) => {
        snap.forEach((s) => {
          let p = new Partner();
          p.initFromFireBase(s.data());
          p.id = s.id;
          out.push(p);
        });
        return out;
      })
      .finally(() => {
        return out;
      });
  }

  async saveUserRating(uid: string, jobid: string, rating: Rating, override: boolean = true): Promise<boolean> {
    let ratingRef = doc(fireDb, `ratings/${uid}/jobs/${jobid}`);
    if(!override) {
      const ratingData = await getDoc(ratingRef);
      if(ratingData.exists()) {
        console.log("saveUserRating() - Rating for user already exsist",uid,jobid);
        return false;
      }
    }
    return setDoc(ratingRef,
      {
        communication: rating.communication,
        teamskills: rating.teamskills,
        punctuality: rating.punctuality,
      })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  deleteuserRating(uid, jobid) {
    let ratingRef = doc(fireDb, `ratings/${uid}/jobs/${jobid}`);
    return deleteDoc(ratingRef);
  }
  getUserRating(uid, jobid): Promise<Rating> {
    let ratingRef = doc(fireDb, `ratings/${uid}/jobs/${jobid}`);
    return getDoc(ratingRef)
      .then((snap) => {
        let r: Rating = new Rating();
        let ratingData = snap.data();
        r.communication = ratingData.communication;
        r.teamskills = ratingData.teamskills;
        r.punctuality = ratingData.punctuality;
        return r;
      })
      .catch(() => {
        return new Rating();
      });
  }
  async getNumberOfJobs(uid: string): Promise<number> {
    let numberOfJobs = 0;
    try {
      const timesheetsQuery = query(collection(fireDb, 'timesheets'), where('userId', '==', uid));
      const timesheetsSnap = await getDocs(timesheetsQuery);
      numberOfJobs = timesheetsSnap.size;
    } catch (error) {
      console.error("getNumberOfJobs() - Error while querying data for user:",uid,error);
    }
    return numberOfJobs;
  }

  async getMeanUserRating(uid): Promise<Rating> {
    let r: Rating = new Rating();
    r.communication = 0;
    r.punctuality = 0;
    r.teamskills = 0;
    try {
      const ref = collection(fireDb, `ratings/${uid}/jobs/`);
      const snapshot = await getDocs(ref);

      let x = snapshot.docs.map((doc) => doc.data());
      for (let i = 0; i < x.length; i++) {
        const ra = x[i];
        r.communication += ra.communication;
        r.punctuality += ra.punctuality;
        r.teamskills += ra.teamskills;
      }
      let len = snapshot.docs.length;
      if (len < 1) {
        len = 1;
      }
      r.communication = Math.round((r.communication / len) * 100) / 100;
      r.punctuality = Math.round((r.punctuality / len) * 100) / 100;
      r.teamskills = Math.round((r.teamskills / len) * 100) / 100;
    } catch {}
    return r;
  }
}

export class Job {
  id: string = "";
  name: string = "";
  starttime: string = "";
  endtime: string = "";
  partner: string = "";
  location: string = "";
  contact: string = "";
  contactphone: string = "";
  partneraddress: string = "";
  internalcontact: string = "";
  internalcontactphone: string = "";
  meetingpoint: string = "";
  dresscode: string = "";
  shortdescription: string = "";
  description: string = "";
  positions: Position[] = [];
  public: boolean = false;
  showLanHint: boolean = true;
  paymentStatus: "pending" | "readyToPay" | "readyToPayPartly" | "partlyPaid" | "paid" = "pending";

  static createPaysText(job: Job, pi: number): string {
    let out = "";
    let p: Position = job.positions[pi];
    out = `${p.workname}: ${p.pay}€ ${Position.getTypeSimple(p.paytype)}`;
    p.extrapay.forEach((ep) => {
      out += `, ${ep.ecomment}: ${ep.epay}€ ${Position.getTypeSimple(
        ep.epaytype
      )}`;
    });

    return out;
  }

  static createSalaryPaysText(job: Job, pi: number): string {
    let out = "";
    let p: Position = job.positions[pi];
    out = `${p.workname}: ${p.pay}€ ${Position.getTypeSimple(p.paytype)}`;
    p.extrapay.forEach((ep) => {
      if(ep.epaytype !== "p") {
        out += `, ${ep.ecomment}: ${ep.epay}€ ${Position.getTypeSimple(ep.epaytype)}`;
      }
    });
    return out;
  }

  static createLumpSumPaysText(job: Job, pi: number): string {
    let out = "";
    let p: Position = job.positions[pi];
    p.extrapay.forEach((ep) => {
      if(ep.epaytype === "p") {
        if(out) {
          out += ", ";
        }
        out += `${ep.ecomment}: ${ep.epay}€ ${Position.getTypeSimple(ep.epaytype)}`;
      }
    });
    return out;
  }

  generateTestJob(): Job {
    let job: Job = new Job();
    job.name = "DEV Brettspielerklärer (m/w/d) " + Math.random().toString(36).substring(10);
    job.starttime = "2022-06-01";
    job.endtime = "2022-06-04";
    job.partner = "Musterfirma GmbH";
    job.location = "Frankfurt am Main";
    job.contact = "Max Musterpartner";
    job.contactphone = "+49154567865233";
    job.partneraddress = "Musterstraße 1A";
    job.internalcontact = "Erik";
    job.internalcontactphone = "+49154567865233";
    job.meetingpoint = "Messe Frankfurt, Ludwig-Erhard-Anlage 1, 60327 Frankfurt am Main";
    job.dresscode = "Bevorzugt blaue oder schwarze Jeans ohne Auffälligkeiten (Risse o. Ä.) und Sneaker - Oberteil wird vom Kunden gestellt.";
    job.shortdescription = "Brettspielerklärer:in für die örtliche Brettspielmesse gesucht!";
    job.description = "Im Rahmen der Brettspielmesse 2022 suchen wir für unseren Kunden Musterfirma sympathische Personen, die aktuelle Brettspielneuheiten vorstellen, erklären & Besucherfragen klären sollen. Du stehst auf Brettspiele? Dann bewirb Dich jetzt!";

    job.positions = Position.generateTestPosition();
    return job;
  }

  static isJobValid(job: Job) {
    //Definition of a valid job.
    //start time and endtime have valid format
    //start time is before end time
    //contact phone has the correct format +4917657906231
    //no field is empty
    let errors: Map<string, string> = new Map();

    if (this.isEmpty(job.name)) {
      errors.set("name", "Mindestens 1 Zeichen");
    }
    errors = new Map([...errors, ...this.validateStartAndEndTime(job)]);

    if (this.isEmpty(job.partner)) {
      errors.set("partner", "mindestens 1 Zeichen");
    }
    if (this.isEmpty(job.location)) {
      errors.set("location", "mindestens 1 Zeichen");
    }
    if (this.isEmpty(job.contact)) {
      errors.set("contact", "mindestens 1 Zeichen");
    }
    if (this.isEmpty(job.contactphone)) {
      errors.set("contactphone", "mindestens 1 Zeichen");
    }
    if (this.isEmpty(job.partneraddress)) {
      errors.set("partneraddress", "mindestens 1 Zeichen");
    }
    if (this.isEmpty(job.internalcontact)) {
      errors.set("internalcontact", "mindestens 1 Zeichen");
    }
    if (this.isEmpty(job.internalcontactphone)) {
      errors.set("internalcontactphone", "mindestens 1 Zeichen");
    }
    if (this.isEmpty(job.meetingpoint)) {
      errors.set("meetingpoint", "mindestens 1 Zeichen");
    }
    if (this.isEmpty(job.dresscode)) {
      errors.set("dresscode", "mindestens 1 Zeichen");
    }
    if (this.isEmpty(job.shortdescription)) {
      errors.set("shortdescription", "mindestens 1 Zeichen");
    }
    if (this.isEmpty(job.description)) {
      errors.set("description", "mindestens 1 Zeichen");
    }

    for (let i = 0; i < job.positions.length; i++) {
      const pos = job.positions[i];
      errors = new Map([...errors, ...Position.isValid(pos, i)]);
    }
    return errors;
  }

  static isEmpty(s): boolean {
    if (s.length < 1) {
      return true;
    }
    return false;
  }

  static validateStartAndEndTime(job: Job): Map<string, string> {
    let errors: Map<string, string> = new Map();

    if (this.dateIsValidFormat(job.starttime)) {
      let st = moment(job.starttime, "YYYY-MM-DD");

      if (st.isValid()) {
        if (this.dateIsValidFormat(job.endtime)) {
          let et = moment(job.endtime, "YYYY-MM-DD");
          if (et.isValid()) {
            if (et.isBefore(st)) {
              errors.set("endtime", "Die Endzeit liegt vor der Startzeit.");
            }
          } else {
            errors.set("endtime", "Die Endzeit ist ungültig.");
          }
        } else {
          errors.set("endtime", "Die Endzeit ist ungültig.");
        }
      } else {
        errors.set("starttime", "Die Startzeit ist ungültig.");
      }
    } else {
      errors.set("starttime", "Die Startzeit ist ungültig.");
    }

    if (!this.dateIsValidFormat(job.endtime)) {
      errors.set("endtime", "Die Endzeit ist ungültig.");
    }
    return errors;
  }

  static dateIsValidFormat(d): boolean {
    if (d && /^\d\d\d\d\-\d\d\-\d\d$/.test(d)) {
      return true;
    }
    return false;
  }

  static getFields() {
    let m: string[] = [];
    m.push("name");
    m.push("starttime");
    m.push("endtime");
    m.push("partner");
    m.push("location");
    m.push("contact");
    m.push("contactphone");
    m.push("partneraddress");
    m.push("internalcontact");
    m.push("internalcontactphone");
    m.push("meetingpoint");
    m.push("dresscode");
    m.push("shortdescription");
    m.push("description");
    return m;
  }

  static initFromObject(o: Object): Job | undefined {
    let job = new Job();
    if(o) {
      job.id = o["id"];
      job.name = o["name"];
      job.starttime = o["starttime"];
      job.endtime = o["endtime"];
      job.partner = o["partner"];
      job.location = o["location"];
      job.contact = o["contact"];
      job.internalcontact = o["internalcontact"];
      job.meetingpoint = o["meetingpoint"];
      job.dresscode = o["dresscode"];
      job.shortdescription = o["shortdescription"];
      job.description = o["description"];
      job.contactphone = o["contactphone"];
      job.partneraddress = o["partneraddress"] || "";
      job.internalcontactphone = o["internalcontactphone"];
      job.positions = Position.initPositions(o["positions"]);
      job.public = o["public"] || false; //public is a new property and may be undefined
      job.showLanHint = o["showLanHint"] || false;
      job.paymentStatus = o["paymentStatus"] ? o["paymentStatus"] : o["paid"] ? "paid" : "pending";
    } else {
      return undefined;
    }
    return job;
  }

  getJobData() {
    return {
      id: this.id,
      name: this.name,
      starttime: this.starttime,
      endtime: this.endtime,
      partner: this.partner,
      location: this.location,
      contact: this.contact,
      internalcontact: this.internalcontact,
      meetingpoint: this.meetingpoint,
      dresscode: this.dresscode,
      shortdescription: this.shortdescription,
      description: this.description,
      positions: this.getPositionsData(),
      contactphone: this.contactphone,
      partneraddress: this.partneraddress,
      internalcontactphone: this.internalcontactphone,
      public: this.public,
      showLanHint: this.showLanHint,
      paymentStatus: this.paymentStatus
    };
  }

  getCensoredJobData() {
    return {
      id: this.id,
      name: this.name,
      starttime: this.starttime,
      endtime: this.endtime,
      partner: this.partner,
      location: this.location,
      contact: this.contact,
      internalcontact: this.internalcontact,
      meetingpoint: this.meetingpoint,
      dresscode: this.dresscode,
      shortdescription: this.shortdescription,
      description: this.description,
      positions: this.getCensoredPositionsData(),
      contactphone: this.contactphone,
      partneraddress: this.partneraddress,
      internalcontactphone: this.internalcontactphone,
      public: this.public,
      showLanHint: this.showLanHint
    };
  }

  getPositionsData() {
    let positionsData: Array<any> = [];
    for (let p of this.positions) {
      console.log(p);
      positionsData.push(p.getPositionData());
    }
    return positionsData;
  }

  getCensoredPositionsData() {
    let positionsData: Array<any> = [];
    for (let p of this.positions) {
      positionsData.push(p.getCensoredPositionData());
    }
    return positionsData;
  }

  addPostion(position: Position) {
    this.positions.push(position);
  }

  isPending() {
    return this.paymentStatus == "pending";
  }
  isReadyToPay() {
    return this.paymentStatus == "readyToPay";
  }
  isReadyToPayPartly() {
    return this.paymentStatus == "readyToPayPartly";
  }
  isPartlyPaid() {
    return this.paymentStatus == "partlyPaid";
  }
  isPartlyReadyOrPartlyPaid() {
    return this.paymentStatus == "readyToPayPartly" || this.paymentStatus == "partlyPaid";
  }
  isPaid() {
    return this.paymentStatus == "paid";
  }
}
const h = "Stundensatz";
const d = "Tagessatz";
const p = "Pauschale";
export class Position {
  amount: string = "";
  briefing: string = "";
  name: string = "";
  workname: string = "";
  pay: string = "";
  paytype: string = "";
  extrapay: { epay: string; epaytype: string; ecomment: string }[] = [];
  hours: string = "";
  turnover: string = "";
  turnovertype: string = "";
  extraturnover: {
    eturnover: string;
    eturnovertype: string;
    ecomment: string;
  }[] = [];
  comment: string = "";
  worktimes: RealWorktime[] = [];

  /**
   * Calculates the main pay of this position (workname)
   */
  getPositionPay(): number {
    return this.getPay(this.paytype, this.pay, this.workname);
  }

  getExtraPay(): number {
    let extraPayOut = 0;
    for (let i = 0; i < this.extrapay.length; i++) {
      const ep = this.extrapay[i];
      extraPayOut += this.getPay(ep.epaytype, ep.epay, ep.ecomment);
    }

    return +extraPayOut.toFixed(0);
  }

  getPay(paytype: string, pay: string, payName: string): number {
    let normalpay = 0;
    let payAsNumber = parseFloat(pay.replace(/,/g, "."));
    if (paytype === "p") {
      normalpay = payAsNumber;
  } else { // for horly or daily paytypes
      let payNameWorkTimes: RealWorktime[] = [];
      for (const workTime of this.worktimes) { //only Worktimes that correspond to the Workname or extraPay-Comment should be calculated here
        if(workTime.description == payName) {
          payNameWorkTimes.push(workTime);
        }
      }

      if(paytype === "h") {
        normalpay = payAsNumber * RealWorktime.getFullWorkTimeByRWT(payNameWorkTimes);
      } else if(paytype === "d") {
        normalpay = payAsNumber * payNameWorkTimes.length;
      }
    }
    if(normalpay >= 1000) {
      return +normalpay.toFixed(0);
    } else {
      return +normalpay.toFixed(1);
    }
  }

  /**
   * Calculates the main salary for this job, including the positionPay and the additional hourly and daily based payments.
   * @returns salaryPay
   */
  getSalaryPay(): number {
    let salaryPay = this.getPositionPay();
    for (let i = 0; i < this.extrapay.length; i++) {
      const ep = this.extrapay[i];
      if(ep.epaytype !== "p") {
        salaryPay += this.getPay(ep.epaytype, ep.epay, ep.ecomment);
      }
    }
    return salaryPay;
  }

  /**
   * Calculates the lumpSum extra payment for this job only (payType == "p").
   * @returns lumpSumPay
   */
  getLumpSumPay(): number {
    let lumpSumPay = 0;
    for (let i = 0; i < this.extrapay.length; i++) {
      const ep = this.extrapay[i];
      if(ep.epaytype === "p") {
        lumpSumPay += this.getPay(ep.epaytype, ep.epay, ep.ecomment);
      }
    }
    return lumpSumPay;
  }

  static generateTestPosition(): Position[] {
    let p: Position = new Position();
    p.amount = "1";
    p.briefing = "Vor-Ort-Briefing";
    p.name = "Brettspielerklärer (m/w/d)";
    p.pay = "15";
    p.paytype = "h";
    p.extrapay = [
      { epay: "40", epaytype: "p", ecomment: "Zeitaufwand für An-/Abreise" },
      { epay: "70", epaytype: "p", ecomment: "Briefing"}
      ];
    p.hours = "20";
    p.turnover = "24,40";
    p.turnovertype = "h";
    p.extraturnover = [
      { eturnover: "120", eturnovertype: "p", ecomment: "Briefing" },
    ];
    p.comment =
      "Brettspieleneuheiten erklären, demonstrieren & Besucherfragen klären";
    p.workname = "Brettspielerklärer:in";

    p.worktimes = RealWorktime.generateTestWorktime();
    p.worktimes[0].description = "Briefing";
    p.worktimes[1].description = "Brettspielerklärer:in";
    p.worktimes[2].description = "Brettspielerklärer:in";
    p.worktimes[3].description = "Brettspielerklärer:in";

    return [p];
  }

  static dateIsValidFormat(d): boolean {
    if (d && /^\d\d\d\d\-\d\d\-\d\d$/.test(d)) {
      return true;
    }
    return false;
  }
  static isValid(p: Position, index: number): Map<string, string> {
    //Definition of a valid position
    //Amount is an integer > 0
    //pay is in the form of d+,dd
    //paytype is p,h or d
    //extrapay is valid: epay as pay, epaytype as paytype, ecomment not empty an mor than 3 characters. Can be length 0
    //hours is a german valid number usually integer or as pay
    //turnover is same as pay
    //turnovertype is same as paytype
    //extraturnover is the same as extrapay
    //comment is not empty and more than 10 characters
    //worktimes in not length 0
    //date is in format YYYY-MM-DD
    //start is in format HH:mm and HH and mm are valid hours and minutes
    let errors: Map<string, string> = new Map();

    if (this.isEmpty(p.name)) {
      errors.set(`${index}.name`, `Muss aufgefüllt werden`);
    }
    if (this.isEmpty(p.workname)) {
      errors.set(`${index}.workname`, `Muss aufgefüllt werden`);
    }
    //amount
    try {
      if (p.amount.length === 0) throw new Error();
      let a = parseInt(p.amount);
      if (a < 1 || isNaN(a)) throw new Error();
    } catch (e) {
      errors.set(`${index}.amount`, `ungültig`);
    }
    //pay
    if (!(p.pay && /^\d+(\,\d\d)?$/.test(p.pay))) {
      errors.set(
        `${index}.pay`,
        `Entweder ganze Zahl oder Zahl mit zwei Nachkommastellen`
      );
    }
    //paytype
    if (!["h", "d", "p"].includes(p.paytype)) {
      errors.set(`${index}.paytype`, `nicht gültig`);
    }

    //extrapay
    for (let i = 0; i < p.extrapay.length; i++) {
      const ep = p.extrapay[i];
      if (!(ep.epay && /^\d+(\,\d\d)?$/.test(ep.epay))) {
        errors.set(
          `${index}.extrapay[${i}].epay`,
          `Entweder ganze Zahl oder Zahl mit zwei Nachkommastellen.`
        );
      }

      if (!["h", "d", "p"].includes(ep.epaytype)) {
        errors.set(`${index}.extrapay[${i}].epaytype`, `ungültig`);
      }

      if (this.isEmpty(ep.ecomment)) {
        errors.set(`${index}.extrapay[${i}].comment`, `mindestens 5 Stellen`);
      }
    }

    //hours is a german valid number usually integer or as pay
    if (!(p.hours && /^\d+(\,\d\d)?$/.test(p.hours))) {
      errors.set(
        `${index}.hours`,
        `Entweder ganze Zahl oder Zahl mit zwei Nachkommastellen`
      );
    }
    //turnover is same as pay
    if (!(p.turnover && /^\d+(\,\d\d)?$/.test(p.turnover))) {
      errors.set(
        `${index}.turnover`,
        `Entweder ganze Zahl oder Zahl mit zwei Nachkommastellen`
      );
    }

    //turnovertype is same as paytype
    if (!["h", "d", "p"].includes(p.turnovertype)) {
      errors.set(`${index}.turnovertype`, `ungültig`);
    }

    //extraturnover is the same as extrapay
    for (let i = 0; i < p.extraturnover.length; i++) {
      const ep = p.extraturnover[i];
      if (!(ep.eturnover && /^\d+(\,\d\d)?$/.test(ep.eturnover))) {
        errors.set(
          `${index}.extraturnover[${i}].eturnover`,
          `Entweder ganze Zahl oder Zahl mit zwei Nachkommastellen.`
        );
      }

      if (!["h", "d", "p"].includes(ep.eturnovertype)) {
        errors.set(`${index}.extraturnover[${i}].eturnovertype`, ``);
      }

      if (this.isEmpty(ep.ecomment)) {
        errors.set(
          `${index}.extraturnover[${i}].ecomment`,
          `mindestens 5 stellen`
        );
      }
    }

    //brifing is not empty and more than 10 characters
    if (this.isEmpty(p.briefing)) {
      errors.set(`${index}.briefing`, `mindestens 5 Stellen.`);
    }
    //comment is not empty and more than 10 characters
    if (this.isEmpty(p.comment)) {
      errors.set(`${index}.comment`, `mindestens 5 Stellen.`);
    }
    //worktimes in not length 0 -wt.
    if (p.worktimes.length < 0) {
      errors.set(`${index}.worktimes`, `ungültiges Format.`);
    } else {
      for (let i = 0; i < p.worktimes.length; i++) {
        const wt = p.worktimes[i];
        errors = new Map([...errors, ...this.validateWorktime(wt, i, index)]);
      }
    }

    return errors;
  }

  static validateWorktime(
    wt: {
      date: string;
      start: string;
      end: string;
    },
    wti: any,
    i: any
  ): Map<string, string> {
    const errors: Map<string, string> = new Map();

    if (!this.dateIsValidFormat(wt.date)) {
      errors.set(`${i}.worktimes[${wti}].date`, `ungültig`);
    }
    if (wt.start && wt.start.length !== 5) {
      errors.set(`${i}.worktimes[${wti}].start`, `falsches Format`);
    }
    if (wt.start === null || wt.start === undefined || wt.start === "") {
      errors.set(`${i}.worktimes[${wti}].start`, `nicht gesetzt`);
    }
    if (wt.end && wt.end.length !== 5) {
      errors.set(`${i}.worktimes[${wti}].end`, `falsches Format`);
    }
    if (wt.end === null || wt.end === undefined || wt.end === "") {
      errors.set(`${i}.worktimes[${wti}].end`, `nicht gesetzt`);
    }
    if (wt.start && wt.start.length === 5 && wt.end && wt.end.length === 5) {
      const sv = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(
        wt.start
      );
      const ev = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(
        wt.end
      );
      if (!sv) {
        errors.set(
          `${i}.worktimes[${wti}].start`,
          `nicht gültig - falsches Format`
        );
      }
      if (!ev) {
        errors.set(
          `${i}.worktimes[${wti}].end`,
          `nicht gültig - falsches Format`
        );
      }
      console.log("SV:", sv, ev);
    }
    return errors;
  }

  static isEmpty(s): boolean {
    if (s.length < 5) {
      return true;
    }
    return false;
  }

  static getType(str) {
    switch (str) {
      case "h":
        return "einen Stundensatz";
      case "d":
        return "einen Tagessatz";
      case "p":
        return "eine Pauschale";
    }
  }

  static getTypeSimple(str) {
    switch (str) {
      case "h":
        return "Stundensatz";
      case "d":
        return "Tagessatz";
      case "p":
        return "Pauschale";
    }
  }

  static getFields() {
    let m: string[] = [];
    m.push("amount");
    m.push("briefing");
    m.push("name");
    m.push("pay");
    m.push("paytype");
    m.push("extrapay");
    m.push("hours");
    m.push("turnover");
    m.push("turnovertype");
    m.push("extraturnover");
    m.push("comment");

    return m;
  }

  init(o: Object) {
    this.amount = o["amount"];
    this.briefing = o["briefing"];
    this.name = o["name"];
    this.workname = o["workname"];
    this.pay = o["pay"];
    this.paytype = o["paytype"];
    this.extrapay = o["extrapay"];
    this.hours = o["hours"];
    this.turnover = o["turnover"];
    this.turnovertype = o["turnovertype"];
    this.extraturnover = o["extraturnover"];
    this.comment = o["comment"];
    this.worktimes = RealWorktime.initWorkTimes(o["worktimes"]);
  }

  static initPositions(positions: object[]): Position[] {
    let out: Position[] = [];
    for (let i = 0; i < positions.length; i++) {
      const curPos = positions[i];
      let p: Position = new Position();
      p.init(curPos);
      out.push(p);
    }
    return out;
  }

  getPositionData() {
    let wts = [];
    for (let i = 0; i < this.worktimes.length; i++) {
      const wt = this.worktimes[i];
      wts.push(RealWorktime.getWorkTimeData(wt));
    }
    return {
      amount: this.amount,
      briefing: this.briefing,
      name: this.name,
      workname: this.workname,
      pay: this.pay,
      paytype: this.paytype,
      extrapay: this.extrapay,
      hours: this.hours,
      turnover: this.turnover,
      turnovertype: this.turnovertype,
      extraturnover: this.extraturnover,
      comment: this.comment,
      worktimes: wts,
    };
  }

  getCensoredPositionData() {
    let wts = [];
    for (let i = 0; i < this.worktimes.length; i++) {
      const wt = this.worktimes[i];
      wts.push(RealWorktime.getWorkTimeData(wt));
    }
    return {
      amount: this.amount,
      briefing: this.briefing,
      name: this.name,
      workname: this.workname,
      pay: this.pay,
      paytype: this.paytype,
      extrapay: this.extrapay,
      hours: this.hours,
      comment: this.comment,
      worktimes: wts,
    };
  }

  static getWorkTime(pos: Position): number {
    let hours = 0;
    for (let i = 0; i < pos.worktimes.length; i++) {
      let e: RealWorktime = pos.worktimes[i];
      let res = RealWorktime.getWorktimeNumber(e.date, e.start, e.end, e.break);
      hours += res;
    }
    return hours;
  }
}

interface Worktime {
  date: string;
  start: string;
  end: string;
  break: string;
}

export class RealWorktime implements Worktime {
  date: string = "";
  start: string = "";
  end: string = "";
  break: string = "";
  description: string = "";
  source?: 'actual' | 'planned';

  static getWorkTimeData(wt: RealWorktime) {
    return {
      date: wt.date,
      start: wt.start,
      end: wt.end,
      break: wt.break,
      description: wt.description,
    };
  }
  static init(o: Object): RealWorktime {
    let wt: RealWorktime = new RealWorktime();
    if(o) {
      wt.date = o["date"];
      wt.start = o["start"];
      wt.end = o["end"];
      wt.break = o["break"];
      wt.description = o["description"];
    }
    return wt;
  }
  static initWorkTimes(worktimes: object[]): RealWorktime[] {
    let out: RealWorktime[] = [];
    if(worktimes && worktimes.length>0) {
      for (let i = 0; i < worktimes.length; i++) {
        const curWT = worktimes[i];
        let r: RealWorktime = RealWorktime.init(curWT);
        out.push(r);
      }
    }
    return out;
  }

  static generateTestWorktime(): RealWorktime[] {
    let wt: RealWorktime[] = [
      {
        date: "2022-06-01",
        start: "16:00",
        end: "18:00",
        break: "0",
        description: "Test",
      },
      {
        date: "2022-06-02",
        start: "09:45",
        end: "18:15",
        break: "0,75",
        description: "Test",
      },
      {
        date: "2022-06-03",
        start: "09:45",
        end: "18:15",
        break: "0,75",
        description: "Test",
      },
      {
        date: "2022-06-04",
        start: "09:45",
        end: "17:15",
        break: "0,5",
        description: "Test",
      },
    ];

    return wt;
  }

  static getWorktimeNumber(
    date: string,
    start: string,
    end: string,
    brek: string
  ): number {
    let d = moment(date + start, "YYYY-MM-DDHH:mm");
    let e = moment(date + end, "YYYY-MM-DDHH:mm");

    let brekNumber = 0;
    if (brek) {
      brekNumber = parseFloat(brek.replace(/,/g, "."));
    }

    if(e<d) {
      e.add(1,'d');
    }

    let res = e.diff(d, "m") / 60 - brekNumber;

    return res;
  }

  static getWorktimeNumberByRWT(rwt: RealWorktime): number {
    return this.getWorktimeNumber(rwt.date, rwt.start, rwt.end, rwt.break);
  }

  static getFullWorkTimeByRWT(rwt: RealWorktime[]): number {
    let res = 0;
    rwt.forEach((rw) => {
      res += RealWorktime.getWorktimeNumberByRWT(rw);
    });
    return res;
  }

  static getFullWorkTimeByRWTString(rwt: RealWorktime[]): string {
    return this.getFullWorkTimeByRWT(rwt).toString().replace(/\./g, ",");
  }

  static getWorktimeStringByRWT(rwt: RealWorktime): string {
    return this.getWorktimeString(rwt.date, rwt.start, rwt.end, rwt.break);
  }

  static getWorktimeString(date: string, start: string, end: string, brek: string): string {
    return this.getWorktimeNumber(date,start,end,brek).toString().replace(/\./g, ",");
  }

  static isEqual(firstTs: RealWorktime, secondTs: RealWorktime): boolean {
    return (
      firstTs.date === secondTs.date &&
      firstTs.start === secondTs.start &&
      firstTs.end === secondTs.end &&
      firstTs.break === secondTs.break &&
      firstTs.description === secondTs.description
    );
  }
}

export type UnderutilizedCauses = "" | "underutilized" | "selfInflicted-sent" | "selfInflicted-signed" | "selfInflicted-doubleSigned" | "selfDelayed-sent" | "selfDelayed-signed" | "selfDelayed-doubleSigned" | "guaranteed" | "ignored";
export class MonthlyWorkingTime {
  month: string;
  workingHours: number;
  paidHours: number;
  unpaidHours: number;
  underutilizedCause: UnderutilizedCauses
  constructor(aMonth: string = "", aWorkingHours: number = -1, aPaidHours: number = -1, aUnpaidHours: number = -1, aUnderutilizedCause: UnderutilizedCauses = "") {
    this.month = aMonth
    this.workingHours = aWorkingHours
    this.paidHours = aPaidHours
    this.unpaidHours = aUnpaidHours
    this.underutilizedCause = aUnderutilizedCause
  }

  static initFromObject(o: any): MonthlyWorkingTime | undefined {
    try {
      if(o) {
        const mwt = new MonthlyWorkingTime();
        mwt.month = o["month"];
        mwt.workingHours = o["workingHours"];
        mwt.paidHours = o["paidHours"];
        mwt.unpaidHours = o["unpaidHours"];
        mwt.underutilizedCause = o["underutilizedCause"] || "";
        return mwt;
      } else {
        return undefined;
      }
    } catch (err) {
      console.error("MonthlyWorkingTime::initFromObject() - Error initialazing object",o,err)
      return undefined;
    }
  }
  static initArrayFromObject(o: Object[]): MonthlyWorkingTime[] {
    let mwtArray: MonthlyWorkingTime[]  = [];
    if(o) {
      for(const monthUtil of o) {
        const mwt = MonthlyWorkingTime.initFromObject(monthUtil);
        if(mwt) {
          mwtArray.push(mwt);
        }
      }
    }
    return mwtArray;
  }

  getData() {
    return {
      month: this.month,
      workingHours: this.workingHours,
      paidHours: this.paidHours,
      unpaidHours: this.unpaidHours,
      underutilizedCause: this.underutilizedCause
    };
  }
}
export class UserUtilization {
  userId: string;
  utilization: MonthlyWorkingTime[];
  months: string[];
  constructor(aUserId: string = "", utilization: MonthlyWorkingTime[] = []) {
    this.userId = aUserId
    this.utilization = utilization
    this.months = this.getMonths();
  }

  static initFromObject(o: any): UserUtilization | undefined {
    if(o && o["userId"] && o["utilization"]) {
      const userUtil = new UserUtilization(o["userId"],MonthlyWorkingTime.initArrayFromObject(o["utilization"]));
      return userUtil;
    } else {
      return undefined;
    }
  }

  getData() {
    return {
      userId: this.userId,
      months: this.getMonths(),
      utilization: this.utilization.map(utilMonth => utilMonth.getData()),
    };
  }

  getUtilizationForMonth(aMonth: string): MonthlyWorkingTime | undefined {
    return this.utilization.find(utilItem => utilItem.month == aMonth);
  }

  private getMonths() {
    return this.utilization.map(item => item.month) 
  }
}

export type ApplicationStatus = "" | "pending" | "accepted" | "declined" | "finallyaccepted" | "shortNoticeCancellation";
export class Application {
  jobid: string = "";
  positionindex: number = -1;
  userid: string = "";
  status: ApplicationStatus = "";
  date: string = "";

  initApplication(jobid, positionindex, userid, status) {
    this.jobid = jobid;
    this.positionindex = positionindex;
    this.userid = userid;
    this.status = status;
  }

  static initFromObject(o: any): Application {
    let a = new Application();
    if(o) {
      a.jobid = o["jobid"];
      a.positionindex = o["positionindex"];
      a.userid = o["userid"];
      a.status = o["status"];
      a.date = o["date"];
    }
    return a;
  }

  isSame(a: Application): boolean {
    return (
      this.jobid == a.jobid &&
      this.positionindex == a.positionindex &&
      this.userid == a.userid
    );
  }
}

export type TimesheetDifference = {
  date: string;
  changes: {
      field: "Start" | "Ende" | "Pause" | "Beschreibung";
      previousValue: string;
      currentValue: string;
  }[];
};
export type UserTimesheetDifference = {
  userId: string;
  jobId: string;
  posIndex: number;
  tsDifferences: TimesheetDifference[];
}
export class Timesheet {
  plannedTimesheet: RealWorktime[] = [];
  actualTimesheet: RealWorktime[] = [];
  paymentStatus: "pending" | "readyToPay" | "readyToPayPartly" | "partlyPaid" | "paid" = "pending";
  months: string[];
  individualPay: IndividualPay[];
  constructor(plannedTimesheet: RealWorktime[] = [], actualTimesheet: RealWorktime[] = [], paymentStatus: "pending" | "readyToPay" | "partlyPaid" | "paid" = "pending" ) {
    this.plannedTimesheet = plannedTimesheet;
    this.actualTimesheet = actualTimesheet;
    this.paymentStatus = paymentStatus;
    this.months = this.getMonths();
    this.individualPay = [];
  }

  static initFromObject(o: any): Timesheet | undefined {
    let ts = new Timesheet();
    if(o && o["plannedTimesheet"]) {
      ts.plannedTimesheet = RealWorktime.initWorkTimes(o["plannedTimesheet"]);
      ts.actualTimesheet = o["actualTimesheet"] ? RealWorktime.initWorkTimes(o["actualTimesheet"]) : [];
      ts.paymentStatus = o["paymentStatus"] || "pending";
      ts.months = o["months"];
      ts.individualPay = o["individualPay"] ? this.initIndividualPay(o["individualPay"]) : [];
    } else {
      console.error("Timesheet::initFromObject() - timesheet object is corrupted:",o)
      return undefined;
    }
    return ts;
  }


  getData() {
    return {
      plannedTimesheet: this.plannedTimesheet.map(workday => RealWorktime.getWorkTimeData(workday)),
      actualTimesheet: this.actualTimesheet.map(workday => RealWorktime.getWorkTimeData(workday)),
      paymentStatus: this.paymentStatus,
      months: this.getMonths(),
      individualPay: this.individualPay
    };
  }

  private getMonths() {
    let monthsArr = [];
    if(this.isReadyToPay() || this.isPaid()) {
      monthsArr = this.actualTimesheet.map(item => moment(item.date,"YYYY-MM-DD").format("MM/YYYY")) 
    } else if(this.isReadyToPayPartly() || this.isPartlyPaid()) {
      monthsArr = this.actualTimesheet.map(item => moment(item.date,"YYYY-MM-DD").format("MM/YYYY")) 
      const plannedArr = this.plannedTimesheet.map(item => moment(item.date,"YYYY-MM-DD").format("MM/YYYY")) 
      monthsArr.push(...plannedArr)
    } else {
      monthsArr = this.plannedTimesheet.map(item => moment(item.date,"YYYY-MM-DD").format("MM/YYYY")) 
    }
    if(monthsArr.length>0) {
      monthsArr = [...new Set(monthsArr)]; // uniquifies duplicates
    }
    return monthsArr;
  }

  isPending() {
    return this.paymentStatus == "pending";
  }
  isPaid() {
    return this.paymentStatus == "paid";
  }
  isPartlyPaid() {
    return this.paymentStatus == "partlyPaid";
  }
  isReadyToPay() {
    return this.paymentStatus == "readyToPay";
  }
  isReadyToPayPartly() {
    return this.paymentStatus == "readyToPayPartly";
  }
  plannedCorrespondsToActual(): boolean {
    if (this.plannedTimesheet.length !== this.actualTimesheet.length) {
      return false;
    }

    for (let i = 0; i < this.plannedTimesheet.length; i++) {
      if (!RealWorktime.isEqual(this.plannedTimesheet[i],this.actualTimesheet[i])) {
        return false;
      }
    }

    return true;
  }
  static areEqual(firstTs: RealWorktime[], secondTs: RealWorktime[]): boolean {
    if (firstTs.length !== secondTs.length) {
      return false;
    }

    for (let i = 0; i < firstTs.length; i++) {
      if (!RealWorktime.isEqual(firstTs[i],secondTs[i])) {
        return false;
      }
    }

    return true;
  }

  static findDifferences(
    timesheetA: RealWorktime[],
    timesheetB: RealWorktime[]
): TimesheetDifference[] {
    const differences: TimesheetDifference[] = [];

    const mapA = new Map(timesheetA.map(item => [item.date, item]));
    const mapB = new Map(timesheetB.map(item => [item.date, item]));

    // Überprüfen, was in timesheetA nicht mehr in timesheetB ist (removed)
    for (const [date, workTimeA] of mapA) {
        if (!mapB.has(date)) {
          differences.push({
            date,
            changes: [
                {
                    field: "Start",
                    previousValue: workTimeA.start,
                    currentValue: "Nicht vorhanden",
                },
                {
                    field: "Ende",
                    previousValue: workTimeA.end,
                    currentValue: "Nicht vorhanden",
                },
                {
                    field: "Pause",
                    previousValue: workTimeA.break,
                    currentValue: "Nicht vorhanden",
                },
                {
                    field: "Beschreibung",
                    previousValue: workTimeA.description,
                    currentValue: "Nicht vorhanden",
                },
            ],
        });
            continue; // Element nicht mehr vorhanden, ignoriere
        }

        const workTimeB = mapB.get(date)!;
        const changeDetails: { field: "Start" | "Ende" | "Pause" | "Beschreibung"; previousValue: string; currentValue: string }[] = [];

        // Vergleiche die Zeiten
        if (workTimeA.start !== workTimeB.start) {
            changeDetails.push({
                field: "Start",
                previousValue: workTimeA.start,
                currentValue: workTimeB.start,
            });
        }
        if (workTimeA.end !== workTimeB.end) {
            changeDetails.push({
                field: "Ende",
                previousValue: workTimeA.end,
                currentValue: workTimeB.end,
            });
        }
        if (workTimeA.break !== workTimeB.break) {
            changeDetails.push({
                field: "Pause",
                previousValue: workTimeA.break,
                currentValue: workTimeB.break,
            });
        }
        if (workTimeA.description !== workTimeB.description) {
            changeDetails.push({
                field: "Beschreibung",
                previousValue: workTimeA.description,
                currentValue: workTimeB.description,
            });
        }

        // Wenn es Änderungen gibt, füge sie zum Unterschieds-Array hinzu
        if (changeDetails.length > 0) {
            differences.push({ date, changes: changeDetails });
        }
    }

    // Überprüfen, was in timesheetB hinzugefügt wurde (added)
    for (const [date, workTimeB] of mapB) {
        if (!mapA.has(date)) {
            differences.push({
                date,
                changes: [
                    {
                        field: "Start",
                        previousValue: "Nicht vorhanden",
                        currentValue: workTimeB.start,
                    },
                    {
                        field: "Ende",
                        previousValue: "Nicht vorhanden",
                        currentValue: workTimeB.end,
                    },
                    {
                        field: "Pause",
                        previousValue: "Nicht vorhanden",
                        currentValue: workTimeB.break,
                    },
                    {
                        field: "Beschreibung",
                        previousValue: "Nicht vorhanden",
                        currentValue: workTimeB.description,
                    },
                ],
            });
        }
    }

    return differences;
}

static initIndividualPay(aIndiPay: object[]): IndividualPay[] {
  let out: IndividualPay[] = [];
  if(aIndiPay && aIndiPay.length>0) {
    for (let i = 0; i < aIndiPay.length; i++) {
      const curIP: any = aIndiPay[i];
      if(curIP.name && curIP.amount) {
        const r: IndividualPay = {name: curIP.name, amount: curIP.amount};
        out.push(r);
      }
    }
  }
  return out;
}
}
export class UserTimesheet {
  timesheet: Timesheet | undefined;
  userId: string;
  jobId: string;
  posIndex: number;
  constructor(timesheet: Timesheet, userId: string, jobId: string, posIndex: number) {
    this.timesheet = timesheet;
    this.userId = userId;
    this.jobId = jobId;
    this.posIndex = posIndex;
  }

  static initFromObject(o: any): UserTimesheet | undefined {
    let uts = undefined;
    try {
      if(o) {
        const ts = Timesheet.initFromObject(o["timesheet"]);
        if(ts) {
          uts = new UserTimesheet(ts,o["userId"],o["jobId"],o["posIndex"]);
        }
      }
    } catch (err) {
      console.error("UserTimesheet::initFromObject() - Error getting uts for Object",o,err);
    }
    return uts;
  }

  getData() {
    return {
      timesheet: this.timesheet?.getData(),
      userId: this.userId,
      jobId: this.jobId,
      posIndex: this.posIndex,
    };
  }
}
export class PayrollMonth {
  month: string;
  status: "open" | "pending" | "confirming" | "concluded";
  constructor(month: string = "", status: "open" | "pending" | "confirming" | "concluded" = "open") {
    if(!month) {
      this.month = moment().format("MM/YYYY");
    } else {
      this.month = month;
    }
    this.status = status;
  }

  static initFromObject(o: any): PayrollMonth | undefined {
    let payrollMonth = undefined;
    try {
      if(o) {
        payrollMonth = new PayrollMonth(o["month"],o["status"]);
      }
    } catch (err) {
      console.error("PayrollMonth::initFromObject() - Error getting uts for Object",o,err);
    }
    return payrollMonth;
  }

  getData() {
    return {
      month: this.month,
      status: this.status
    };
  }

  getDocId(): string {
    return moment(this.month,"MM/YYYY").format("YYYY-MM");
    // const monthSplit = this.month.split("/"); // 07/2024
    // return `${monthSplit[1]}-${monthSplit[0]}`; // 2024-07
  }
}

export type ApplicationNotificationType = "email" | "push";
export class Settings {
  applicationNotification: ApplicationNotificationType[];
  auPermitDate: string;
  devMailsEnabled: boolean;
  generateAvEnabled: boolean;
  constructor(applicationNotification: ApplicationNotificationType[], auPermitDate: string, devMailsEnabled: boolean, generateAvEnabled: boolean) {
    this.applicationNotification = applicationNotification;
    this.auPermitDate = auPermitDate;
    this.devMailsEnabled = devMailsEnabled;
    this.generateAvEnabled = generateAvEnabled;
  }

  static initFromObject(o: any): Settings | undefined {
    let settings: Settings | undefined = undefined;
    try {
      if(o) {
        settings = new Settings(o["applicationNotification"],o["auPermitDate"],o["devMailsEnabled"],o["generateAvEnabled"]);
      }
    } catch (err) {
      console.error("Settings::initFromObject() - Error getting settings for Object",o,err);
    }
    return settings;
  }

  getData() {
    return {
      applicationNotification: this.applicationNotification,
      auPermitDate: this.auPermitDate,
      devMailsEnabled: this.devMailsEnabled,
      generateAvEnabled: this.generateAvEnabled
    };
  }
}