import { Injectable, NgModule } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { fireAuth } from "src/app/shared-modules/initFirebase";

import { MenuController } from "@ionic/angular";
import { ProfileService } from "./profile.service";
import { onAuthStateChanged, User } from "@firebase/auth";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService  {
  constructor(
    private router: Router,
    private menuCtrl: MenuController,
    public profileService: ProfileService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(fireAuth, async (user: User) => {
        console.log("NEXT", next);
        console.log("STATE", state);
        //beginnerpages
        //noob pages

        if (user) {
          console.log("MENUCANAC", this.menuCtrl);
          await this.profileService.initAllData();
          //adminpages
          if (
            [
              "useredit",
              "userdetail",
              "showjob",
              "addjob",
              "jobsoverview",
              "phoneappointment",
              "contractoverview",
              "payrolls"
            ].includes(next.routeConfig.path) &&
            !this.profileService.isAdmin()
          ) {
            this.router.navigate(["/profiletabs"]);
            resolve(false);
          }
          if (
            "scheduleappointment" === next.routeConfig.path &&
            this.profileService.userProfile.phoneAppointment !== "" &&
            !this.profileService.isAdmin()
          ) {
            this.router.navigate(["/profiletabs"]);
            resolve(false);
          }
          if (
            [
              "marketoverview",
              "jobdetail",
              "positiondetail",
              "chronicarichive",
              "chronicnow",
              "chronicfuture",
            ].includes(next.routeConfig.path) &&
            !(this.profileService.isAdmin() || this.profileService.isBeginner())
          ) {
            this.router.navigate(["/profiletabs"]);
            resolve(false);
          }
          resolve(true);
        } else {
          console.log("User is not logged in");
          this.router.navigate(["/login"]);
          resolve(false);
        }
      });
    });
  }
}
