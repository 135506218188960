import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  NgZone,
  ViewChild,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators } from "@angular/forms";
import {
  TaxDetails,
  TaxJob,
  ProfileService,
  UserProfile,
} from "src/app/services/user/profile.service";
import { CustomValidators } from "src/app/pages/dbjobs/addjob/addjob.page";
import { AdminService } from "src/app/services/admin/admin.service";
import { LoadingController, Platform, AlertController } from "@ionic/angular";
import { ToastService } from "src/app/services/toast/toast.service";
import { Router } from "@angular/router";
import moment from "moment";
import { SwshUtils } from "src/app/shared-modules/swshUtils";
import { SelectModalComponent } from "../select-modal/select-modal.component";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-taxdetails-form",
  templateUrl: "./taxdetails-form.component.html",
  styleUrls: ["./taxdetails-form.component.scss"],
})
export class TaxdetailsFormComponent implements OnInit, OnChanges {
  taxdetailsForm: UntypedFormGroup;
  @Input() taxdetails: TaxDetails;
  @Input() userid: string;
  @Output() formSubmitted = new EventEmitter<any>();
  @ViewChild("addresstext") addresstext: any;

  public answers: Map<string, string> = new Map();
  formattingInProgress: any;
  birthdateDisplayFormatMobile: any;
  fromDisplayFormatMobile: string[] = [];
  toDisplayFormatMobile: string[] = [];
  nationalitiesObjectArray: any[] = [];


  constructor(
    private fb: UntypedFormBuilder,
    public adminService: AdminService,
    public profileService: ProfileService,
    public loadingCtrl: LoadingController,
    public zone: NgZone,
    public platform: Platform,
    private router: Router,
    private alertCtrl: AlertController,
    private modalController: ModalController,
    public swshUtils: SwshUtils
  ) {
    console.log("CONSTRUCTOR");
  }

  ngOnChanges(changes: SimpleChanges) {
    this.taxdetails = changes.taxdetails.currentValue;
  }
  names = {};
  setNationality(event) {
    console.log("Nationality", event);
    this.nationality.setValue(event.item.name);
  }
  // setCountry(event) {
  //   console.log("Country", event);
  //   this.country.setValue(event.item);
  //   this.street.setValue("");
  //   this.street.markAsUntouched();
  //   this.number.setValue("");
  //   this.number.markAsUntouched();
  //   this.zip.setValue("");
  //   this.zip.markAsUntouched();
  //   this.city.setValue("");
  //   this.city.markAsUntouched();
  //   this.autocomplete.setComponentRestrictions({ country: this.european_country_codes[this.country.value] });
  // }
  setCountry(event: CustomEvent) {
    const selectedCountry = event.detail.value; 
  
    console.log("Selected country:", selectedCountry);
  
    if (selectedCountry) {
      this.country.setValue(selectedCountry);
      this.street.setValue("");
      this.street.markAsUntouched();
      this.number.setValue("");
      this.number.markAsUntouched();
      this.zip.setValue("");
      this.zip.markAsUntouched();
      this.city.setValue("");
      this.city.markAsUntouched();
  
      //Applying country restrictions using the selected country
      const countryCode = this.swshUtils.european_country_codes[selectedCountry];
      if (typeof countryCode === 'string' || Array.isArray(countryCode)) {
        console.log("Applying country restrictions:", countryCode);
        this.autocomplete.setComponentRestrictions({ country: countryCode });
      } else {
        console.error("Invalid country code:", countryCode);
      }
    } else {
      console.error("No country selected or event structure is incorrect.");
    }
  }
  ngOnInit() {
    let n = [];
    this.nationalitites.forEach((na, i) => {
      n.push({ id: i, name: na });
    });
    this.nationalitites = n;
    fetch("./assets/lang/de.json")
      .then((res) => res.json())
      .then((json) => {
        this.names = json;
      });
    console.log("TAXDETAILS_FORM:", this.taxdetails);
    this.nationalitiesObjectArray = n;
    try {
      this.taxdetailsForm = this.fb.group({
        street: [this.taxdetails.street, [Validators.required]],
        number: [this.taxdetails.number, [Validators.required]],
        zip: [
          this.taxdetails.zip,
          [Validators.required, CustomValidators.isPLZ],
        ],
        city: [this.taxdetails.city, [Validators.required]],
        birthDate: [
          //Original Code:
          this.taxdetails.birthDate || "",
          //Formatting birthdate here as well; Otherwise initially the input field displays wrong format.
          //moment(this.taxdetails.birthDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
          [Validators.required, CustomValidators.isDateValid],
        ],
        mobileBirthDAte: [moment(this.taxdetails.birthDate, 'YYYY-MM-DD').format('DD.MM.YYYY'), [Validators.required, CustomValidators.isDateValid]],
        birthplace: [this.taxdetails.birthplace, [Validators.required]],
        insuranceWork: [this.taxdetails.insuranceWork, [Validators.required]],
        insurancenr: [this.taxdetails.insurancenr, [Validators.required]],
        insuranceflag: ["", [Validators.required]],
        maritalStatus: [this.taxdetails.maritalStatus, [Validators.required]],
        handicap: [this.taxdetails.handicap, [Validators.required]],
        nationality: [this.taxdetails.nationality, [Validators.required]],
        country: [this.taxdetails.country ? this.taxdetails.country : "Deutschland", [Validators.required]],
        iban: [this.taxdetails.iban, [CustomValidators.isIBAN]],
        bic: [this.taxdetails.bic, [CustomValidators.isBIC]],
        bankname: [this.taxdetails.bankname, [Validators.required]],
        accountHolder: [this.taxdetails.accountHolder],
        highestSchool: [this.taxdetails.highestSchool, [Validators.required]],
        highestJobSchool: [
          this.taxdetails.highestJobSchool,
          [Validators.required],
        ],
        status: [this.taxdetails.status, [Validators.required]],
        idnr: [this.taxdetails.idnr, [Validators.required, CustomValidators.isIDNR]],
        idnrflag: ["", [Validators.required]],
        taxclass: [this.taxdetails.taxclass, [Validators.required]],
        factor: [this.taxdetails.factor],
        childrenTaxAllowance: [
          this.taxdetails.childrenTaxAllowance,
          ],
        confession: [this.taxdetails.confession, [Validators.required]],
        healthInsurance: [
          this.taxdetails.healthInsurance,
          [Validators.required],
        ],
        healthInsuranceName: [
          this.taxdetails.healthInsuranceName,
          [Validators.required],
        ],
        healthInsuranceLocation: [
          this.taxdetails.healthInsuranceLocation,
          [Validators.required],
        ],
        furtherJobs: [this.taxdetails.furtherJobs, [Validators.required]],
        furtherJobObjects: this.fb.array([]),
        entryDate: this.taxdetails.entryDate,
        firstEntryDate: this.taxdetails.firstEntryDate,
        jobTitle: this.taxdetails.jobTitle,
        personalNumber: this.taxdetails.personalNumber,
      });
      this.initFurtherJobObjectsForm(this.taxdetails.furtherJobObjects);
    } catch (e) {
      console.error("TAXERROR", e);
    }
    this.taxdetailsForm.valueChanges.subscribe(() =>
      console.log(this.taxdetailsForm)
    );
    console.log("INITFORM", this.taxdetailsForm.value);
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private autocomplete: google.maps.places.Autocomplete;
  private async getPlaceAutocomplete() {
    let a = await this.addresstext.getInputElement();
    this.autocomplete = new google.maps.places.Autocomplete(await a, {
      componentRestrictions: { country: this.european_country_codes[this.country.value] },
      types: ["geocode"], // 'establishment' / 'address' / 'geocode'
    });
    google.maps.event.addListener(this.autocomplete, "place_changed", () => {
      const place = this.autocomplete.getPlace();
      this.getAddress(place);
    });
  }

  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  get street() {
    return this.taxdetailsForm.get("street");
  }
  get number() {
    return this.taxdetailsForm.get("number");
  }
  get zip() {
    return this.taxdetailsForm.get("zip");
  }
  get city() {
    return this.taxdetailsForm.get("city");
  }
  get birthDate() {
    return this.taxdetailsForm.get("birthDate");
  }
  get mobileBirthDAte() {
    return this.taxdetailsForm.get("mobileBirthDAte");
  }
  get birthplace() {
    return this.taxdetailsForm.get("birthplace");
  }
  get insuranceWork() {
    return this.taxdetailsForm.get("insuranceWork");
  }
  get insurancenr() {
    return this.taxdetailsForm.get("insurancenr");
  }
  get insuranceflag() {
    return this.taxdetailsForm.get("insuranceflag");
  }
  get maritalStatus() {
    return this.taxdetailsForm.get("maritalStatus");
  }
  get handicap() {
    return this.taxdetailsForm.get("handicap");
  }
  get nationality() {
    return this.taxdetailsForm.get("nationality");
  }
  get country() {
    return this.taxdetailsForm.get("country");
  }
  get iban() {
    return this.taxdetailsForm.get("iban");
  }
  get bic() {
    return this.taxdetailsForm.get("bic");
  }
  get bankname() {
    return this.taxdetailsForm.get("bankname");
  }
  get accountHolder() {
    return this.taxdetailsForm.get("accountHolder");
  }
  get highestSchool() {
    return this.taxdetailsForm.get("highestSchool");
  }
  get highestJobSchool() {
    return this.taxdetailsForm.get("highestJobSchool");
  }
  get status() {
    return this.taxdetailsForm.get("status");
  }
  get idnr() {
    return this.taxdetailsForm.get("idnr");
  }
  get idnrflag() {
    return this.taxdetailsForm.get("idnrflag");
  }
  get taxclass() {
    return this.taxdetailsForm.get("taxclass");
  }
  get factor() {
    return this.taxdetailsForm.get("factor");
  }
  get childrenTaxAllowance() {
    return this.taxdetailsForm.get("childrenTaxAllowance");
  }
  get confession() {
    return this.taxdetailsForm.get("confession");
  }
  get healthInsurance() {
    return this.taxdetailsForm.get("healthInsurance");
  }
  get healthInsuranceName() {
    return this.taxdetailsForm.get("healthInsuranceName");
  }
  get healthInsuranceLocation() {
    return this.taxdetailsForm.get("healthInsuranceLocation");
  }
  get furtherJobs() {
    return this.taxdetailsForm.get("furtherJobs");
  }
  get furtherJobObjectsForm() {
    return this.taxdetailsForm.get("furtherJobObjects") as UntypedFormArray;
  }

  submitTaxDetails(taxdetailsForm: UntypedFormGroup): void {
    console.log("🚀 Tax Details before calling checkTax:", JSON.stringify(this.taxdetails, null, 2));
    this.checkTax(false);
    this.taxdetails.initWithObject(taxdetailsForm.value);
    this.taxdetails.furtherJobObjects =
      this.taxdetailsForm.value.furtherJobObjects;
    this.formSubmitted.emit(this.taxdetails);
      }
  saveBirthdate(birthDate: string) {
    this.birthDate.setValue(birthDate.split("T")[0]);
    const formatForDisplay = moment(birthDate, "YYYY-MM-DD").format("DD.MM.YYYY");
    this.birthdateDisplayFormatMobile = formatForDisplay;
      }
  saveFrom(i: number, from: string) {
    this.getTaxJob(i).from.setValue(from.split("T")[0]);
    const formatForDisplay = moment(from, "YYYY-MM-DD").format("DD.MM.YYYY");
    this.fromDisplayFormatMobile[i] = formatForDisplay;
  }
  saveTo(i: number, to: string) {
    this.getTaxJob(i).to.setValue(to.split("T")[0]);
    const formatForDisplay = moment(to, "YYYY-MM-DD").format("DD.MM.YYYY");
    this.toDisplayFormatMobile[i] = formatForDisplay;
  }
  initFurtherJobObjectsForm(a: any[]) {
    a.forEach((tj) => {
      this.addFilledTaxJob(tj);
    });
  }

  addTaxJob() {
    this.addFilledTaxJob(new TaxJob());

    console.log(this.furtherJobObjectsForm);
  }

  addFilledTaxJob(newtj: TaxJob) {
    const tj = this.fb.group({
      from: [newtj.from, [Validators.required, CustomValidators.isDateValid]],
      fromMobile: [newtj.fromMobile, [Validators.required, CustomValidators.isDateValid]],
      to: [newtj.to],
      toMobile: [newtj.toMobile, [Validators.required, CustomValidators.isDateValid]],
      employer: [newtj.employer, [Validators.required]],
      weeklyWorkHours: [
        newtj.weeklyWorkHours,
        [Validators.required, CustomValidators.isGermanNumber],
      ],
      kindOfJob: [newtj.kindOfJob, [Validators.required]],
    });
    this.furtherJobObjectsForm.push(tj);
    console.log(this.furtherJobObjectsForm);
  }

  initJobArray() {
    if (this.furtherJobs.value == "y") {
      this.addTaxJob();
    }
  }

  deleteTaxJob(i) {
    this.furtherJobObjectsForm.removeAt(i);
    if (this.furtherJobObjectsForm.length == 0) {
      this.furtherJobs.setValue("n");
    }
  }

  getTaxJob(i) {
    return (this.furtherJobObjectsForm.controls[i] as UntypedFormGroup).controls;
  }

  // This code saved the Abrechnungsdaten regardless of a correct verif. code or even if the pormpt was cancelled
  async checkTax(aForceWriteTaxDetais: boolean) {
    this.taxdetails.initWithObject(this.taxdetailsForm.value);
    this.taxdetails.furtherJobObjects = this.taxdetailsForm.value.furtherJobObjects;

    if (this.profileService.userProfile.id === this.userid) {
      await this.profileService.updateTaxDetails(this.taxdetails);
    } else {
      this.adminService.updateTaxDetailsForUser(this.userid,this.taxdetails);
    }

    let loading = await this.loadingCtrl.create({
      message:
        "<img src='/assets/icon/Ladeanimation.svg' alt='loading...'></img><p>Daten werden überprüft...<p>",
      translucent: true,
      spinner: null,
      cssClass: "chillload",
      backdropDismiss: true,
    });
    await loading.present();
    console.log("USERID:", this.userid);
    let taxChanges = await this.adminService.getTaxDetailsChanges(this.userid);
    this.adminService
      .requestTaxDetailsCheck(this.userid,false,aForceWriteTaxDetais)
      .then(async (res) => {
        this.answers = res;
        console.log("RESULT: ", res);
        //IF RESULT SIZE IS 0 MEANS TAXDETAILS HAVE 0 ERRROS AND ARE OFFICIALLY IN THE VALIDATED TAX DETAILS
        if (res.size === 0) {
          if(aForceWriteTaxDetais || taxChanges == 0) {
            ToastService.makeToast("Deine Daten wurden übernommen");
            this.formSubmitted.emit(this.taxdetails);
            this.router.navigate(["/profiletabs/showprofile"]);
          } else {
            this.presentTaxSecurePrompt();
          }
        } else {
          ToastService.makeToast("Bitte überprüfe Deine Eingaben");
        }
      })
      .finally(() => {
        loading.dismiss();
      });
  }

  presentTaxSecurePrompt() {
    this.alertCtrl.create({
      header: 'Änderung der Abrechnungsdaten',
      message: 'Bitte verifiziere Dich über den 6-stelligen Code den wir Dir per Mail gesendet haben, um die Änderungen anzuwenden.',
      inputs: [
        {
          name: 'authCode',
          placeholder: 'Verifizierungscode',
          
        },
      ],
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (data: any) => {
            console.log('Canceled', data);
          }
        },
        {
          text: 'Prüfen',
          handler: async (data: any) => {
            console.log('Saved Information', data);
            if(await this.checkTaxSecureCode(data.authCode)) {
              await this.checkTax(true);
            } else {
              ToastService.makeToast("Der eingegebene Auth-Code scheint nicht gültig zu sein.");
            }
          }
        }
      ]
    }).then(res => {
      res.present();
    });
  }

//   async checkTax(aForceWriteTaxDetails: boolean) {
//     this.taxdetails.initWithObject(this.taxdetailsForm.value);
//     this.taxdetails.furtherJobObjects = this.taxdetailsForm.value.furtherJobObjects;

//     // Ensure tax details are updated before validation
//     if (this.profileService.userProfile.id === this.userid) {
//       await this.profileService.updateTaxDetails(this.taxdetails);
//     } else {
//       await this.adminService.updateTaxDetailsForUser(this.userid, this.taxdetails);
//     }

//     let loading = await this.loadingCtrl.create({
//       message: "<img src='/assets/icon/Ladeanimation.svg' alt='loading...'></img><p>Daten werden überprüft...<p>",
//       translucent: true,
//       spinner: null,
//       cssClass: "chillload",
//       backdropDismiss: true,
//     });
//     await loading.present();

//     console.log("USERID:", this.userid);

//     let taxChanges = await this.adminService.getTaxDetailsChanges(this.userid);
    
//     this.adminService
//       .requestTaxDetailsCheck(this.userid, false, aForceWriteTaxDetails)
//       .then(async (res) => {
//         this.answers = res;
//         console.log("RESULT: ", res);

//         // Ensure tax details only get saved after validation or verification
//         if (res.size === 0) {
//           if (aForceWriteTaxDetails || taxChanges == 0) {
//             console.log("No verification required. Saving tax details.");
//             if (this.profileService.userProfile.id === this.userid) {
//               await this.profileService.updateTaxDetails(this.taxdetails);
//             } else {
//               this.adminService.updateTaxDetailsForUser(this.userid, this.taxdetails);
//             }            
//             ToastService.makeToast("Deine Daten wurden übernommen");
//             this.router.navigate(["/profiletabs/showprofile"]);
//           } else {
//             console.log("Verification required. Prompting for secure code.");
//             this.presentTaxSecurePrompt();
//           }
//         } else {
//           console.log("Validation failed. Errors in tax details.");
//           ToastService.makeToast("Bitte überprüfe Deine Eingaben");
//         }
//       })
//       .finally(() => {
//         // Ensure loading dismisses only after the whole validation is completed
//         loading.dismiss();
//       });
//   }

  
//   presentTaxSecurePrompt() {
//     this.alertCtrl.create({
//       header: 'Änderung der Abrechnungsdaten',
//       message: 'Bitte verifiziere Dich über den 6-stelligen Code den wir Dir per Mail gesendet haben, um die Änderungen anzuwenden.',
//       inputs: [
//         {
//           name: 'authCode',
//           placeholder: 'Verifizierungscode',
//         },
//       ],
//       buttons: [
//         {
//           text: 'Abbrechen',
//           role: 'cancel',
//           cssClass: 'secondary',
//           handler: (data: any) => {
//             console.log('Verification canceled', data);
//           },
//         },
//         {
//           text: 'Prüfen',
//           handler: async (data: any) => {
//             console.log('Verification attempt with code:', data.authCode);
//             if (await this.checkTaxSecureCode(data.authCode)) {
//               console.log("Verification successful. Saving tax details.");
//               if (this.profileService.userProfile.id === this.userid) {
//                 await this.profileService.updateTaxDetails(this.taxdetails);
//               } else {
//                 this.adminService.updateTaxDetailsForUser(this.userid, this.taxdetails);
//               }                        
//               ToastService.makeToast("Deine Daten wurden übernommen");
//               this.router.navigate(["/profiletabs/showprofile"]);
//             } else {
//               console.log("Invalid verification code.");
//               ToastService.makeToast("Der eingegebene Auth-Code scheint nicht gültig zu sein.");
//             }
//           },
//         },
//       ],
//     }).then((res) => {
//       res.present();
//     });
//   }
  
//   private async saveTaxDetails() {
//     console.log("🚀 Attempting to save tax details:", this.taxdetails);

//     // ✅ Log whether the function is called for the current user or another user
//     if (this.profileService.userProfile.id === this.userid) {
//         console.log("✅ Updating tax details for current user...");
//         await this.profileService.updateTaxDetails(this.taxdetails);
//     } else {
//         console.log("✅ Updating tax details for another user:", this.userid);
//         await this.adminService.updateTaxDetailsForUser(this.userid, this.taxdetails);
//     }

//     console.log("✅ Tax details saved successfully!");
// }

  

  async checkTaxSecureCode(aTaxSecureCode: string): Promise<boolean> {
    let secureCodeValid = false;
    let backendSecureCode = await this.adminService.getTaxDetailsSecureCode(this.profileService.userProfile.id);
    if(aTaxSecureCode && aTaxSecureCode == backendSecureCode) {
      secureCodeValid = true;
    }
    return secureCodeValid;
  }

  checkTaxLocale() {
    this.answers = UserProfile.areTaxDetailsValid(this.taxdetails);
  }

  getAddress(place: object) {
    console.log("PLACE:", place);
    let adComp = place["address_components"];
    let gotStreet = false;
    adComp.forEach((a) => {
      if (a["types"])
        a["types"].forEach((t) => {
          if (t === "route") {
            console.log("STRAßE", a.long_name);
            this.street.setValue(a.long_name);
            gotStreet = true;
          }
          if (t === "street_number") {
            console.log("nummer", a.long_name);
            this.number.setValue(a.long_name);
          }
          if (t === "postal_code") {
            console.log("postal_code", a.long_name);
            this.zip.setValue(a.long_name);
          }
          if (t === "locality") {
            console.log("STADT", a.long_name);
            this.city.setValue(a.long_name);
          }
        });
    });
    if(!gotStreet)
      this.street.setValue("");
    this.address = place["formatted_address"];
    this.formattedAddress = place["formatted_address"];
    this.zone.run(() => (this.formattedAddress = place["formatted_address"]));
  }

  address: Object;
  establishmentAddress: Object;

  formattedAddress: string;
  formattedEstablishmentAddress: string;

  phone2: string;

  getEuropeanCountries(): string[] {
    let countries: string[] = [];
    for(const country of Object.keys(this.european_country_codes)) {
      countries.push(country);
    }
    return countries;
  }
  european_country_codes: {[key: string]: string} = {
      "Deutschland": "DE",
      "Albanien": "AL",
      "Andorra": "AD",
      "Belarus": "BY",
      "Belgien": "BE",
      "Bosnien und Herzegowina": "BA",
      "Bulgarien": "BG",
      "Dänemark": "DK",
      "Estland": "EE",
      "Finnland": "FI",
      "Frankreich": "FR",
      "Griechenland": "GR",
      "Irland": "IE",
      "Island": "IS",
      "Italien": "IT",
      "Kroatien": "HR",
      "Lettland": "LV",
      "Litauen": "LT",
      "Luxemburg": "LU",
      "Malta": "MT",
      "Moldau": "MD",
      "Montenegro": "ME",
      "Niederlande": "NL",
      "Norwegen": "NO",
      "Österreich": "AT",
      "Polen": "PL",
      "Portugal": "PT",
      "Rumänien": "RO",
      "Russland": "RU",
      "Schweden": "SE",
      "Schweiz": "CH",
      "Serbien": "RS",
      "Slowakei": "SK",
      "Slowenien": "SI",
      "Spanien": "ES",
      "Tschechien": "CZ",
      "Ukraine": "UA",
      "Ungarn": "HU"
  };

  nationalitites = [
    "deutsch",
    "afghanisch",
    "ägyptisch",
    "albanisch",
    "algerisch",
    "andorranisch",
    "angolanisch",
    "antiguanisch",
    "äquatorialguineisch",
    "argentinisch",
    "armenisch",
    "aserbaidschanisch",
    "äthiopisch",
    "australisch",
    "bahamaisch",
    "bahrainisch",
    "bangladeschisch",
    "barbadisch",
    "belarussisch (weißrussisch)",
    "belgisch",
    "belizisch",
    "beninisch",
    "bhutanisch",
    "bolivianisch",
    "bosnisch-herzegowinisch",
    "botsuanisch",
    "brasilianisch",
    "britisch",
    "bruneiisch",
    "bulgarisch",
    "burkinisch",
    "burundisch",
    "chilenisch",
    "chinesisch",
    "costa-ricanisch, costaricanisch",
    "ivorisch",
    "dänisch",
    "dominicanisch",
    "dominikanisch",
    "dschibutisch",
    "ecuadorianisch",
    "salvadorianisch",
    "eritreisch",
    "estnisch",
    "fidschianisch",
    "finnisch",
    "französisch",
    "gabunisch",
    "gambisch",
    "georgisch",
    "ghanaisch",
    "grenadisch",
    "griechisch",
    "guatemaltekisch",
    "guineisch",
    "guinea-bissauisch",
    "guyanisch",
    "haitianisch",
    "honduranisch",
    "indisch",
    "indonesisch",
    "irakisch",
    "iranisch",
    "irisch",
    "isländisch",
    "israelisch",
    "italienisch",
    "jamaikanisch",
    "japanisch",
    "jemenitisch",
    "jordanisch",
    "kambodschanisch",
    "kamerunisch",
    "kanadisch",
    "kapverdisch",
    "kasachisch",
    "katarisch",
    "kenianisch",
    "kirgisisch",
    "kiribatisch",
    "kolumbianisch",
    "komorisch",
    "kongolesisch",
    "kosovarisch",
    "kroatisch",
    "kubanisch",
    "kuwaitisch",
    "laotisch",
    "lesothisch",
    "lettisch",
    "libanesisch",
    "liberianisch",
    "libysch",
    "liechtensteinisch",
    "litauisch",
    "luxemburgisch",
    "madagassisch, madegassisch",
    "malawisch",
    "malaysisch",
    "maledivisch",
    "malisch",
    "maltesisch",
    "marokkanisch",
    "marshallisch",
    "mauretanisch",
    "mauritisch",
    "mexikanisch",
    "mikronesisch",
    "moldauisch",
    "monegassisch",
    "mongolisch",
    "montenegrinisch",
    "mosambikanisch",
    "myanmarisch",
    "namibisch",
    "nauruisch",
    "nepalesisch",
    "neuseeländisch",
    "nicaraguanisch",
    "niederländisch",
    "nigrisch",
    "nigerianisch",
    "niueanisch",
    "nordkoreanisch",
    "nordmazedonisch",
    "norwegisch",
    "omanisch",
    "österreichisch",
    "pakistanisch",
    "palauisch",
    "panamaisch",
    "papua-neuguineisch",
    "paraguayisch",
    "peruanisch",
    "philippinisch",
    "polnisch",
    "portugiesisch",
    "ruandisch",
    "rumänisch",
    "russisch",
    "salomonisch",
    "sambisch",
    "samoanisch",
    "san-marinesisch",
    "são-toméisch",
    "saudi-arabisch",
    "schwedisch",
    "schweizerisch",
    "senegalesisch",
    "serbisch",
    "seychellisch",
    "sierra-leonisch",
    "simbabwisch",
    "singapurisch",
    "slowakisch",
    "slowenisch",
    "somalisch",
    "spanisch",
    "sri-lankisch",
    "St. Kitts und Nevis",
    "lucianisch",
    "vincentisch",
    "südafrikanisch",
    "südkoreanisch",
    "südsudanesisch",
    "sudanesisch",
    "surinamisch",
    "swasiländisch",
    "syrisch",
    "tadschikisch",
    "tansanisch",
    "thailändisch",
    "timoresisch,timorisch",
    "togoisch, togolesisch",
    "tongaisch",
    "Trinidad und Tobago",
    "tschadisch",
    "tschechisch",
    "tunesisch",
    "türkisch",
    "turkmenisch",
    "tuvaluisch",
    "ugandisch",
    "ukrainisch",
    "ungarisch",
    "uruguayisch",
    "usbekisch",
    "vanuatuisch",
    "vatikanisch",
    "venezolanisch",
    "Vereinigte Arabische Emirate",
    "US-amerikanisch, traditionell: amerikanisch",
    "vietnamesisch",
    "zentralafrikanisch",
    "zyprisch, zypriotisch",
  ];

  selectedFurtherJobs() {
    if (
      this.furtherJobs.value === "y" &&
      this.furtherJobObjectsForm.controls.length === 0
    ) {
      this.addTaxJob();
    }
  }
  handleMobileBirthdate(event) {
    if (!this.formattingInProgress) {
      this.formattingInProgress = true;
      const originalDateValue = event.detail.value;
      const parsedDate = moment(originalDateValue, 'YYYY-MM-DD');
      if (parsedDate.isValid()) {
        const formattedDateValue = parsedDate.format('DD.MM.YYYY');
        console.log("dateValuefromEvent: " + originalDateValue);
        console.log("formattedDateValueFromHandler: " + formattedDateValue);
        this.birthDate.setValue(formattedDateValue, { emitEvent: false });
      } else {
        console.error('Something Went Wrong With Handling Mobile Birthdate');
      }
      this.formattingInProgress = false;
    }
  }
  onClear(event){
    const input = event.target;
    input.setSelectionRange(0, 0);
    input.value = "";
  }
  maskImitation(event){
  const input = event.target;
  const inputValue = input.value.replace(/\D/g, '');
  if (inputValue.length <= 2) {
    input.value = inputValue;
  } else if (inputValue.length <= 4) {
    input.value = `${inputValue.slice(0, 2)}.${inputValue.slice(2)}`;
  } else if (inputValue.length < 8) {
    input.value = `${inputValue.slice(0, 2)}.${inputValue.slice(2, 4)}.${inputValue.slice(4)}`;
  } else {
    input.value = `${inputValue.slice(0, 2)}.${inputValue.slice(2, 4)}.${inputValue.slice(4, 8)}`;
    const formatForSetValue = moment(input.value, "DD.MM.YYYY").format("YYYY-MM-DD");
    this.birthDate.setValue(formatForSetValue);
  }
  const cursorPosition = input.value.length;
  input.setSelectionRange(cursorPosition, cursorPosition);
  }
  maskImitationFROM(i: number, event){
    const input = event.target;
    const inputValue = input.value.replace(/\D/g, '');
    if (inputValue.length <= 2) {
      input.value = inputValue;
    } else if (inputValue.length <= 4) {
      input.value = `${inputValue.slice(0, 2)}.${inputValue.slice(2)}`;
    } else if (inputValue.length < 8) {
      input.value = `${inputValue.slice(0, 2)}.${inputValue.slice(2, 4)}.${inputValue.slice(4)}`;
    } else {
      input.value = `${inputValue.slice(0, 2)}.${inputValue.slice(2, 4)}.${inputValue.slice(4, 8)}`;
      const formatForSetValue = moment(input.value, "DD.MM.YYYY").format("YYYY-MM-DD");
      this.getTaxJob(i).from.setValue(formatForSetValue);
    }
    const cursorPosition = input.value.length;
    input.setSelectionRange(cursorPosition, cursorPosition);
    }
    maskImitationTO(i: number, event){
      const input = event.target;
      const inputValue = input.value.replace(/\D/g, '');
      if (inputValue.length <= 2) {
        input.value = inputValue;
      } else if (inputValue.length <= 4) {
        input.value = `${inputValue.slice(0, 2)}.${inputValue.slice(2)}`;
      } else if (inputValue.length < 8) {
        input.value = `${inputValue.slice(0, 2)}.${inputValue.slice(2, 4)}.${inputValue.slice(4)}`;
      } else {
        input.value = `${inputValue.slice(0, 2)}.${inputValue.slice(2, 4)}.${inputValue.slice(4, 8)}`;
        const formatForSetValue = moment(input.value, "DD.MM.YYYY").format("YYYY-MM-DD");
        this.getTaxJob(i).to.setValue(formatForSetValue);
      }
      const cursorPosition = input.value.length;
      input.setSelectionRange(cursorPosition, cursorPosition);
      }

    async presentCountryModal() {
        let countries = this.swshUtils.getEuropeanCountries();
      
        const modal = await this.modalController.create({
          component: SelectModalComponent,
          cssClass: 'full-screen-modal',
          backdropDismiss: true,
          componentProps: {
            items: countries,
            title: "Select Country"
          },
        });
        await modal.present();
      
        const { data } = await modal.onWillDismiss();
      
        if (data && data.selectedItem) {
          this.taxdetailsForm.get('country').setValue(data.selectedItem);
        }
      }

    async presentNationalityModal() {
        let nationalities = this.nationalitiesObjectArray.map(nat => nat.name);
        console.log("logging nationalities", nationalities);
        
        const modal = await this.modalController.create({
          component: SelectModalComponent,
          cssClass: 'full-screen-modal',
          backdropDismiss: true,
          componentProps: {
            items: nationalities,
            title: "Auswählen"
          },
        });
        await modal.present();
      
        const { data } = await modal.onWillDismiss();
      
        if (data && data.selectedItem) {
          this.taxdetailsForm.get('nationality').setValue(data.selectedItem);
          console.log("data setValue", data.selectedItem);
        }
      }
}
