import { Pipe, PipeTransform } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PayrollMonth } from 'src/app/services/admin/admin.service';
import moment from 'moment';

@Component({
  selector: 'app-select-modal',
  templateUrl: './select-modal.component.html',
  styleUrls: ['./select-modal.component.scss']
})
export class SelectModalComponent implements OnInit {
  @Input() title: string;
  @Input() items: string[] = [];
  @Input() showSearchBar: boolean = true;
  @Input() payrollMonths: PayrollMonth[] = [];
  @Input() partnerItems: any[] = [];
  @Input() isPartnerSelection: boolean = false;

  public searchTerm: string = '';
  public filteredItems: string[] = [];
  public filteredPartners: any[] = [];

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    console.log("Partner Selection Mode:", this.isPartnerSelection);
    console.log("Partners:", this.partnerItems);

    this.filteredItems = [...this.items];
    this.filteredPartners = [...this.partnerItems];
  }

  applyFilter() {
    const searchLower = this.searchTerm.trim().toLowerCase();

    if (!searchLower) {
      this.filteredItems = [...this.items];
      this.filteredPartners = [...this.partnerItems];
      return;
    }

    this.filteredItems = this.items.filter(item =>
      item?.toLowerCase().includes(searchLower)
    );

    this.filteredPartners = this.partnerItems.filter(partner => 
      partner.pname?.toLowerCase().includes(searchLower)
    );
  }

  selectModalItem(item: string) {
    this.modalController.dismiss({
      selectedItem: item
    }).catch(err => console.error("Modal Dismiss Error:", err));
  }

  selectPartnerContact(partner: any, contact: any) {
    this.modalController.dismiss({
      selectedPartner: partner,
      selectedContact: contact
    }).catch(err => console.error("Modal Dismiss Error:", err));
  }

  selectPartnerWithoutContact(partner: any) {
    console.log("Partner selected without contacts:", partner);
    
    this.modalController.dismiss({
      selectedPartner: {
        pname: partner.pname,
        partneraddress: partner.partneraddress
      }
    }).catch(err => console.error("Modal Dismiss Error:", err));
}


  closeModal() {
    this.modalController.dismiss().catch(err => console.error("Close Modal Error:", err));
  }

  getMonthClass(aPayrollMonth: PayrollMonth): string {
    if (aPayrollMonth) {
      switch (aPayrollMonth.status) {
        case 'open':
          return 'month-open';
        case 'pending':
          return 'month-pending';
        case 'confirming':
          return 'month-confirming';
        case 'concluded':
          return 'month-concluded';
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  formatDate(date: string): string {
    return moment(date, 'MM/YYYY').format('MMMM YYYY');
  }
}




@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchTerm: string): any[] {
    if (!items || !searchTerm) {
      return items;
    }
    return items.filter(item =>
      item.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
}
