// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label {
  z-index: 10;
}

ion-item {
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  border-color: #ffbe00;
  background-color: white !important;
}

.popover-content {
  width: 457px !important;
}

.showpointer {
  cursor: pointer;
}

.iconBtn:hover {
  background: var(--ion-color-danger-shade);
  color: var(--ion-color-medium-contrast);
  transition: background 0.1s, color 0.1s;
}

.iconBtn {
  border-radius: 1rem;
  color: var(--ion-color-danger);
  transition: background 0.5s, color 0.5s;
}

.errorbackdg {
  background: var(--ion-color-danger-tint) !important;
}

.warningbackdg {
  background: var(--ion-color-primary-tint) !important;
}

.inset {
  border-style: inset;
  border-width: 2px;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

ion-select {
  height: 55px;
  border-width: 3px !important;
}

ion-button {
  max-width: 260px;
  margin-left: auto;
  margin-right: auto;
}

ion-toggle {
  --background: #9c9c9c;
  --background-checked: #00a9bf71;
  --handle-background: white;
  --handle-background-checked: #00a9bf;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/dbjobs/addjob/addjob.page.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AACA;EACE,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,qBAAA;EACA,kCAAA;AAEF;;AAAA;EACE,uBAAA;AAGF;;AADA;EACE,eAAA;AAIF;;AAFA;EACE,yCAAA;EACA,uCAAA;EACA,uCAAA;AAKF;;AAHA;EACE,mBAAA;EACA,8BAAA;EACA,uCAAA;AAMF;;AAJA;EACE,mDAAA;AAOF;;AALA;EACE,oDAAA;AAQF;;AALA;EACE,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;AAQF;;AALA;EACE,YAAA;EACA,4BAAA;AAQF;;AALA;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;AAQF;;AAEA;EACE,qBAAA;EACA,+BAAA;EAEA,0BAAA;EACA,oCAAA;AAAF","sourcesContent":["ion-label {\n  z-index: 10;\n}\nion-item {\n  border-radius: 8px;\n  border-width: 2px;\n  border-style: solid;\n  border-color: #ffbe00;\n  background-color: white !important;\n}\n.popover-content {\n  width: 457px !important;\n}\n.showpointer {\n  cursor: pointer;\n}\n.iconBtn:hover {\n  background: var(--ion-color-danger-shade);\n  color: var(--ion-color-medium-contrast);\n  transition: background 0.1s, color 0.1s;\n}\n.iconBtn {\n  border-radius: 1rem;\n  color: var(--ion-color-danger);\n  transition: background 0.5s, color 0.5s;\n}\n.errorbackdg {\n  background: var(--ion-color-danger-tint) !important;\n}\n.warningbackdg {\n  background: var(--ion-color-primary-tint) !important;\n}\n\n.inset {\n  border-style: inset;\n  border-width: 2px;\n  border-radius: 10px;\n  margin-bottom: 10px;\n  padding: 10px;\n}\n\nion-select {\n  height: 55px;\n  border-width: 3px !important;\n}\n\nion-button {\n  max-width: 260px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n// ion-item {\n//   --ion-item-background: #ffffff !important;\n//   border-width: 2px;\n//   border-style: solid;\n//   border-color: #ffbe00;\n// }\n\nion-toggle {\n  --background: #9c9c9c;\n  --background-checked: #00a9bf71;\n\n  --handle-background: white;\n  --handle-background-checked: #00a9bf;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
