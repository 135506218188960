// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

ion-content {
  --overflow: auto;
  height: 100%;
}

ion-accordion-group {
  width: 100%;
}

ion-list {
  max-height: 70vh;
  overflow-y: auto;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlbGVjdC1tb2RhbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFlBQUE7RUFDQSxnQkFBQTtFQUNBLGFBQUE7RUFDQSxzQkFBQTtBQUNGOztBQUVBO0VBQ0UsZ0JBQUE7RUFDQSxZQUFBO0FBQ0Y7O0FBRUE7RUFDRSxXQUFBO0FBQ0Y7O0FBRUE7RUFDRSxnQkFBQTtFQUNBLGdCQUFBO0FBQ0YiLCJmaWxlIjoic2VsZWN0LW1vZGFsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm1vZGFsLWNvbnRlbnQge1xuICBoZWlnaHQ6IDEwMCU7XG4gIG92ZXJmbG93LXk6IGF1dG87XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG59XG5cbmlvbi1jb250ZW50IHtcbiAgLS1vdmVyZmxvdzogYXV0bztcbiAgaGVpZ2h0OiAxMDAlO1xufVxuXG5pb24tYWNjb3JkaW9uLWdyb3VwIHtcbiAgd2lkdGg6IDEwMCU7XG59XG5cbmlvbi1saXN0IHtcbiAgbWF4LWhlaWdodDogNzB2aDtcbiAgb3ZlcmZsb3cteTogYXV0bztcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/select-modal/select-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,gBAAA;EACA,gBAAA;AACF;AACA,wwBAAwwB","sourcesContent":[".modal-content {\n  height: 100%;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n}\n\nion-content {\n  --overflow: auto;\n  height: 100%;\n}\n\nion-accordion-group {\n  width: 100%;\n}\n\nion-list {\n  max-height: 70vh;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
