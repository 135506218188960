/// <reference types="@types/googlemaps" />
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { Platform, MenuController, NavController, AlertController, LoadingController } from "@ionic/angular";
import { AuthService } from "./services/user/auth.service";
import { ProfileService, UserProfile } from "./services/user/profile.service";
import { AdminService, Application, Job } from "src/app/services/admin/admin.service";
import { ToastService } from "src/app/services/toast/toast.service";
import { FirebaseX } from "@awesome-cordova-plugins/firebase-x/ngx";
import { NavigationExtras } from "@angular/router";
import { register } from 'swiper/element/bundle';

register();

declare let cordova: any;
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})

export class AppComponent implements OnInit {
  public selectedIndex = 0;

  public appPages: AppPage[] = [
    {
      title: "Mein Bereich",
      url: "/profiletabs",
      icon: "assets/icon/meinbereich.svg",
    },
    {
      title: "Einstellungen",
      url: "/settings",
      icon: "settings",
    }
  ];
  public labels = [
    {
      title: "Kontakt",
      url: "https://swift-shift.de/kontakt",
      icon: "assets/icon/Hyperlink.svg",
    },
    {
      title: "Impressum",
      url: "https://swift-shift.de/impressum",
      icon: "assets/icon/Hyperlink.svg",
    },
    {
      title: "Merkblatt LAN",
      // url: "https://swift-shift.de/merkblatt-lan/",
      url: `${window.location.origin}/assets/pdf/Merkblatt_LAN.pdf`,
      icon: "assets/icon/Hyperlink.svg",
    },
  ];

  constructor(
    private platform: Platform,
    private menuCtrl: MenuController,
    private authService: AuthService,
    private navCtrl: NavController,
    public profileService: ProfileService,
    private adminService: AdminService,
    private firebaseX: FirebaseX,
    private alertController: AlertController,
    private loadingCtrl: LoadingController,
    private router: Router
  ) {
    this.initializeApp();

    this.adminService.getNewJobsSubject().subscribe((newJobs: string[]) => {
      const pageIndex = this.appPages.findIndex(
        (page) => page.title === "Jobangebote"
      );
      if(pageIndex > -1) {
        this.appPages[pageIndex].count = newJobs.length;
      }
    });
  }

  goToLink(url: string) {
    if(this.platform.is("ios"))
    {
        cordova.InAppBrowser.open(url, '_blank', 'location=no,fullscreen=no');
    }
    else
    {
       window.open(url, "_blank");
    }
  }

  async addRoleBasedPages(userRole: string) {
    if (userRole == "admin") {
      this.appPages = [
        {
          title: "Mein Bereich",
          url: "/profiletabs",
          icon: "assets/icon/meinbereich.svg",
        },
        {
          title: "Jobangebote",
          url: "/marketoverview",
          icon: "assets/icon/Jobangebote.svg",
        },
        {
          title: "Meine Jobs",
          url: "/chronictabs",
          icon: "assets/icon/Meine Jobs.svg",
        },
        {
          title: "Datenbank Jobs",
          url: "/jobtabs",
          icon: "file-tray",
        },
        {
          title: "Datenbank Personal",
          url: "/userstabs",
          icon: "file-tray",
        },
        {
          title: "Datenbank Partner",
          url: "/partner",
          icon: "file-tray",
        },
        {
          title: "Interview-Manager",
          url: "/phoneappointment",
          icon: "call",
        },
        {
          title: "Telefon-Übersicht",
          url: "/scheduleappointment",
          icon: "call",
        },
        {
          title: "Einstellungen",
          url: "/settings",
          icon: "settings",
        }
      ];
    } else if (userRole == "beginner") {
      this.appPages = [
        {
          title: "Mein Bereich",
          url: "/profiletabs",
          icon: "assets/icon/meinbereich.svg",
        },
        {
          title: "Jobangebote",
          url: "/marketoverview",
          icon: "assets/icon/Jobangebote.svg",
        },
        {
          title: "Meine Jobs",
          url: "/chronictabs",
          icon: "assets/icon/Meine Jobs.svg",
        },
        {
          title: "Einstellungen",
          url: "/settings",
          icon: "settings",
        }
      ];
    }
  }

  async initializeApp() {
    this.platform.ready().then(() => {
      console.log("AppComponents intialized!!!");

      if(this.platform.is('ios') || this.platform.is('android')) {
        this.firebaseX.onTokenRefresh().subscribe(token => {
          console.log(`token refreshed: ${token}`)
          this.adminService.fcmToken = token;
        });
        this.firebaseX.onMessageReceived().subscribe(message => {
          try{
            console.log("onMessageReceived");
            console.dir(message);
            if(message.messageType === "notification"){
              this.handleNotificationMessage(message);
            }else{
              ToastService.makeLowToast(this.handleDataMessage(message));
            }
        }catch(e){
            console.error("Exception in onMessageReceived callback: "+e.message);
        }
        });
      
        this.checkNotificationPermission(false); // Check permission then get token
  
        // if(!environment.production) { //not working smhw. Data is only visible in debugView when debug mode is enabled: https://firebase.google.com/docs/analytics/debugview#enable_debug_mode
        //   let aEnabled = await this.firebaseX.setAnalyticsCollectionEnabled(true);
        //   console.warn("Analytics enabled",aEnabled);
      
        //   await this.firebaseX.logEvent("select_content", {content_type: "page_view", item_id: "swsh_app_component"});
        //   await this.firebaseX.logEvent('page_view', {page: "swsh_app"});
        //   await this.firebaseX.logEvent('page_view', {page: "swsh_app_noIndex"});
        // }
      }
    });





  }

  logOut(): void {
    this.navCtrl.navigateRoot("login");
    this.authService.logoutUser().then(() => {
      this.profileService.userProfile = null;

      window.location.reload();
    });
  }

  async ngOnInit() {
    this.menuCtrl.enable(true);
    const userProf = await this.profileService.initUserProfile();
    if(userProf) {
      this.addRoleBasedPages(this.profileService.userRole);
      console.log("OnInit AppComponent",this.adminService.fcmToken)
      await this.adminService.setDeviceToken(userProf.id, userProf.deviceToken);
      if(this.profileService.userRole == "beginner") {
        await this.adminService.setUserUpdatesObservable(userProf.id);
      }
    }

    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }
  }

  async handleNotificationMessage(message: any){
    var title;
    if(message.title){
        title = message.title;
    }else if(message.notification && message.notification.title){
        title = message.notification.title;
    }else if(message.aps && message.aps.alert && message.aps.alert.title){
        title = message.aps.alert.title;
    } else if(message.notification_title) {
        title = message.notification_title;
    }

    var body;
    if(message.body){
        body = message.body;
    }else if(message.notification && message.notification.body){
        body = message.notification.body;
    }else if(message.aps && message.aps.alert && message.aps.alert.body){
        body = message.aps.alert.body;
    } else if(message.notification_body) {
        body = message.notification_body;
    }

    var jobId;
    if(message.appJobId) {
      jobId = message.appJobId;
    }
    var posIndex;
    if(message.appPosIndex) {
      posIndex = message.appPosIndex;
    }
    var applicationState;
    if(message.appState) {
      applicationState = message.appState;
    }
    var userId;
    if(message.appUserId) {
      userId = message.appUserId;
    }

    var msg = "Notification message received";
    if(message.tap){
        msg += " (tapped in " + message.tap + ")";
    }
    if(title){
        msg += '; title='+title;
    }
    if(body){
        msg += '; body='+body;
    }
    msg  += ": "+ JSON.stringify(message);
    console.log(msg);

    const anyLoadingOverlay = await this.loadingCtrl.getTop();
    if(anyLoadingOverlay) { //Wait for any loading Messages to finish before a new alertOverlay will be presented. (B2C-397)
      await anyLoadingOverlay.onDidDismiss();
    }
    
    let alert: HTMLIonAlertElement;
    if(jobId && posIndex && userId && applicationState && !title.includes("Absage") && !this.router.url.includes("chronicjobview")) {
      let application = new Application();
      application.initApplication(jobId,posIndex,userId,applicationState);

      alert = await this.alertController.create({
        header: title,
        message: body,
        buttons: [
          {
            text: 'Abbrechen',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
            }
          },
          {
            text: 'Zum Job',
            handler: async () => {
              let job = await this.adminService.getJobById(jobId);
              if(job.id.length == 0) {
                ToastService.makeLowToast("Job ungültig!");
              } else {
                if(title.includes("Neue Bewerbung")) {
                  this.navigateToJob(job)
                } else {
                  this.openShowJobPage(job,posIndex,application);
                }
              }
            }
          },
        ]
      });
    } else if(title.includes("Absage")) {
      alert = await this.alertController.create({
        header: title,
        message: body,
        buttons: [
          {
            text: 'Abbrechen',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
            }
          },
          {
            text: 'Jobangebote',
            handler: () => {
              this.navCtrl.navigateForward(["marketoverview"]);
            }
          },
        ]
      });
    } else if(title == "Immatrikulationsbescheid" && !this.router.url.includes("showprofile")) {
      alert = await this.alertController.create({
        header: title,
        message: body,
        buttons: [
          {
            text: 'Abbrechen',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
            }
          },
          {
            text: 'Zum Profil',
            handler: () => {
              let navigationExtras: NavigationExtras = {
                state: { openDokToggler: true },
              };
              this.navCtrl.navigateForward(["profiletabs"],navigationExtras);
            }
          },
        ]
      });
    } else {
      alert = await this.alertController.create({
        header: title,
        message: body,
        buttons: [
          {
            text: 'Ok',
            handler: () => {
              alert.dismiss();
            }
          }
        ]
      });
    }


    await alert.present();
};

handleDataMessage(message: any): string{
  console.log("Data message received: " + JSON.stringify(message));
  return JSON.stringify(message);
};

// Notifications
 async checkNotificationPermission(requested: boolean){
  let hasPermission = await this.firebaseX.hasPermission();
  console.log("Permissions ios",hasPermission);
      if(hasPermission){
        console.log("Remote notifications permission granted");
          // Granted
          localStorage.removeItem("ignorePushNotifications");
          this.getToken();
      }else if(!requested){
          // Request permission
          console.log("Requesting remote notifications permission");
          this.firebaseX.grantPermission().then(aHasPermission => {
            if (!aHasPermission) {
              // logic to display popup every x times	
              var ignorePush = Boolean(localStorage.getItem("ignorePushNotifications"));
              if(!ignorePush) {
                this.presentAlertConfirm().then(() => {
                  console.log('alert controller presented');
                });
              }
            }
          });
      }else{
          // Denied
          console.error("Notifications won't be shown as permission is denied");
      }
};

  async getToken(){
  let token = await this.firebaseX.getToken();
  if (token){
      console.log("Got FCM token: " + token)
  }else {
      console.error("Failed to get FCM token");
  }
};

async presentAlertConfirm() {
	const alert = await this.alertController.create({
		header: 'Fehlende Berechtigung',
		message: 'Um Push Benachrichtigungen empfangen zu können, müssen Mitteilungen für diese App erlaubt sein.',
		buttons: [
			{
				text: 'Ignorieren',
				role: 'cancel',
				cssClass: 'secondary',
				handler: () => {
          localStorage.setItem("ignorePushNotifications", "true");
					console.log('Push ignored...');
				}
			}, {
				text: 'Einstellungen',
				handler: () => {
					cordova.plugins.settings.open('application_details').then(() => {
						console.log('opened native settings ( => application_details)');
					})
				}
			}
		]
	});
	
	await alert.present();
}

openShowJobPage(job: Job, pi: number, application: Application) {
  let navigationExtras: NavigationExtras = {
    state: { job: job, pi: pi, a: application, scrollToBtn: true }
  };
  this.navCtrl.navigateForward(["chronicjobview"], navigationExtras);
}

async navigateToJob(aJob: Job) {
  console.log("Navigate to job", aJob.id);
  let navigationExtras: NavigationExtras = {
    state: { job: aJob, canEdit: "times" },
  };
  this.navCtrl.navigateForward(["showjob"], navigationExtras);
}

}

export interface AppPage {
  title: string;
  url: string;
  icon: string;
  count?: number;
}
