import { Injectable } from "@angular/core";
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor() {}

  static makeToast(msg: string) {
    const toast = document.createElement("ion-toast");
    toast.message = msg;
    toast.duration = 3000;
    toast.position = "middle";
    document.body.appendChild(toast);
    return toast.present();
  }
  static makeLowToast(msg: string) {
    const toast = document.createElement("ion-toast");
    toast.message = msg;
    toast.duration = 3000;
    toast.position = "bottom";
    document.body.appendChild(toast);
    return toast.present();
  }

  static async presentToast(aMessage: string, position: 'top' | 'middle' | 'bottom' = 'bottom') {
    const toastCrtl = new ToastController();
    const toast = await toastCrtl.create({
      message: aMessage,
      duration: 3000,
      position: position,
      cssClass: "ion-text-center"
    });

    await toast.present();
  }
}
