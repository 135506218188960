import { environment } from "src/environments/environment";

export class SedcardNew {
  isProduction = environment.production;
  //1 General Infos
  street: string = "";
  number: string = "";
  zip: string = "";
  city: string = "";
  bigcity: string = ""; //optional
  phone: string = "";
  birthdate: string = "";
  sex: string = "";
  nationality: string = "";
  country: string = "";

  //2 theoretical practical Experience
  highestSchool: string = "";
  highestJobSchool: string = "";
  currentStudy: string = ""; //Studiengang und Universität
  practicalExperience: PracticalExperience[] = []; // type:["Praktikum":{position:"","year: string:"" "}/"Promotion":{"position:"",year:""}]

  //3 Aussehen und Fähigkeiten
  haircolor: string = "";
  piercing: string = ""; //y,n
  tattoo: string = ""; //y,n
  size: string = ""; //Körpergröße in cm
  top: string = ""; //oberteil ["XS",S,M,L,XL,XXL,3XL]
  pants: string = ""; //if female: 34,34/36,36,38,38/40,40,40/42,42,44,44/46,46
  /*male 30/32
  31/32
  32/32
  33/33
  34/32
  36/34
  38/34
  40/34
  42/34
  44/36
  45/36
  46/36*/
  shoes: string = ""; //35,35.5,36,...,52
  //Sprachkenntnisse
  languages: Language[] = []; //lang:string=""[englisch deutsch spanisch,sonstige],niveau: [elementar Fortgeschiritten, Muttersprache]

  //Mobility
  driverslicense: string = ""; //y,n
  classe: string = "";
  car: string = ""; //y,n
  driver: string = ""; //y,n

  //Verfügbarkeiten und einsatzgebiete
  deploymentlength: string = ""; //<3,<5,<7,7+
  limitationLength: string = ""; //unlimited,year,halfyear,month,custom
  limitationReason: string = ""; //1-10
  possibleLocations: string = "";
  gamingAnalog: boolean = false //true,false
  gamingDigital: boolean = false; //true,false
  preferedPositions: string = ""; //Auf- und Abbau, Moderation, Teamleitung

  names = {};
  
  constructor(
  ) {
    fetch("./assets/lang/de.json")
    .then((res) => res.json())
    .then((json) => {
      this.names = json;
    });
  }

  initFromObject(o: any) {
    this.street = o["street"];
    this.number = o["number"];
    this.zip = o["zip"];
    this.city = o["city"];
    this.bigcity = o["bigcity"];
    this.phone = o["phone"];
    this.birthdate = o["birthdate"];
    this.sex = o["sex"];
    this.nationality = o["nationality"];
    this.country = o["country"];
    this.highestSchool = o["highestSchool"];
    this.highestJobSchool = o["highestJobSchool"];
    this.currentStudy = o["currentStudy"];
    this.practicalExperience = o["practicalExperience"];
    this.haircolor = o["haircolor"];
    this.piercing = o["piercing"];
    this.tattoo = o["tattoo"];
    this.size = o["size"];
    this.top = o["top"];
    this.pants = o["pants"];
    this.shoes = o["shoes"];
    this.languages = o["languages"];
    this.driverslicense = o["driverslicense"];
    this.classe = o["classe"];
    this.car = o["car"];
    this.driver = o["driver"];
    this.deploymentlength = o["deploymentlength"];
    this.limitationLength = o["limitationLength"] ? o["limitationLength"] : "";
    this.limitationReason = o["limitationReason"] ? o["limitationReason"] : "";
    this.possibleLocations = o["possibleLocations"];
    this.gamingAnalog = o["gamingAnalog"];
    this.gamingDigital = o["gamingDigital"];
    this.preferedPositions = o["preferedPositions"];
  }

  getData(): any {
    let langs: Array<any> = [];
    if (this.languages)
      this.languages.map((lang, i) => {
        let x = new Language();
        x.initFromObject(lang);
        langs.push(x.getData());
        return;
      });
    let exps: Array<any> = [];
    if (this.practicalExperience)
      this.practicalExperience.map((exp, i) => {
        let x = new PracticalExperience();
        x.initFromObject(exp);
        exps.push(x.getData());
        return;
      });
    console.log("LANG", langs);
    console.log("ECPS", exps);
    return {
      street: this.street || "",
      number: this.number || "",
      zip: this.zip || "",
      city: this.city || "",
      bigcity: this.bigcity || "",
      phone: this.phone || "",
      birthdate: this.birthdate || "",
      sex: this.sex || "",
      nationality: this.nationality || "",
      country: this.country || "",
      highestSchool: this.highestSchool || "",
      highestJobSchool: this.highestJobSchool || "",
      currentStudy: this.currentStudy || "",
      practicalExperience: exps,
      haircolor: this.haircolor || "",
      piercing: this.piercing || "",
      tattoo: this.tattoo || "",
      size: this.size || "",
      top: this.top || "",
      pants: this.pants || "",
      shoes: this.shoes || "",
      languages: langs,
      driverslicense: this.driverslicense || "",
      classe: this.classe || "",
      car: this.car || "",
      driver: this.driver || "",
      deploymentlength: this.deploymentlength || "",
      limitationLength: this.limitationLength || "",
      limitationReason: this.limitationReason || "",
      possibleLocations: this.possibleLocations || "",
      gamingAnalog: this.gamingAnalog,
      gamingDigital: this.gamingDigital,
      preferedPositions: this.preferedPositions || "",
    };
  }

  isValid(): string[] {
    var missingData = [];
    if (this.street === "") {
      missingData.push(this.names["street"])
    }
    if (this.number === "") {
      missingData.push(this.names["number"])
    }
    if (this.zip === "") {
      missingData.push(this.names["zip"])
    }
    if (this.city === "") {
      missingData.push(this.names["city"])
    }
    if (this.phone === "") {
      missingData.push(this.names["phone"])
    }
    if (this.birthdate === "") {
      missingData.push(this.names["birthdate"])
    }
    if (this.currentStudy === "") {
      missingData.push(this.names["currentStudy"])
    }
    if (this.sex === "") {
      missingData.push(this.names["sex"])
    }
    if (this.nationality === "") {
      missingData.push(this.names["nationality"])
    }
    if (this.country === "") {
      missingData.push(this.names["country"])
    }
    if (this.highestSchool === "") {
      missingData.push(this.names["highestSchool"])
    }
    if (this.highestJobSchool === "") {
      missingData.push(this.names["highestJobSchool"])
    }
    if (this.haircolor === "") {
      missingData.push(this.names["haircolor"])
    }
    if (this.piercing === "") {
      missingData.push(this.names["piercing"])
    }
    if (this.tattoo === "") {
      missingData.push(this.names["tattoo"])
    }
    if (this.size === "") {
      missingData.push(this.names["size"])
    }
    if (this.top === "") {
      missingData.push(this.names["top"])
    }
    if (this.pants === "") {
      missingData.push(this.names["pants"])
    }
    if (this.shoes === "") {
      missingData.push(this.names["shoes"])
    }
    if (this.driverslicense === "") {
      missingData.push(this.names["driverslicense"])
    }
    if (this.deploymentlength === "" && this.isProduction) {
      missingData.push(this.names["deploymentlength"])
    }
    if (this.limitationLength === "" && !this.isProduction) {
      missingData.push(this.names["limitationLength"])
    }
    if (this.limitationReason === "" && !this.isProduction) {
      missingData.push(this.names["limitationReason"])
    }

    return missingData;
  }
}

export class PracticalExperience {
  type: string = "Praktikum"; //"Praktikum"/"Promotion"/"Sonsitges"
  company: string = "";
  exprole: string = "";
  startYear: number | undefined; // 2000 - 2020
  endYear: number | undefined; 

  getData() {
    return {
      type: this.type,
      company: this.company,
      exprole: this.exprole,
      startYear: this.startYear,
      endYear: this.endYear,
    };
  }
  initFromObject(o: any) {
    this.type = o["type"];
    this.company = o["company"];
    this.exprole = o["exprole"];
    this.startYear = o["startYear"];
    this.endYear = o["endYear"];
  }
}

export class Language {
  lang: string = "Deutsch";
  niveau: string = "Elementar"; //Elementar, Fortgeschritten, Muttersprache

  initFromObject(o: any) {
    this.lang = o["lang"];
    this.niveau = o["niveau"];
  }
  getData() {
    return {
      lang: this.lang,
      niveau: this.niveau,
    };
  }
}
