import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { defineCustomElements } from "gl-ionic-background-video/dist/loader"; // add this line

if (environment.production) {
  enableProdMode();

  //Block all console.log logs in prod
  window.console.log = () => { }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

defineCustomElements(window); // call the function here
