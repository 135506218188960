// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label {
  z-index: 10;
}

ion-item {
  border-radius: 8px;
  background-color: var(--ion-textinputbg) !important;
}

.errorbackdg {
  background: var(--ion-color-danger-tint) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/sedcard-form/sedcard-form.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AACA;EACE,kBAAA;EACA,mDAAA;AAEF;;AAAA;EACE,mDAAA;AAGF","sourcesContent":["ion-label {\n  z-index: 10;\n}\nion-item {\n  border-radius: 8px;\n  background-color: var(--ion-textinputbg) !important;\n}\n.errorbackdg {\n  background: var(--ion-color-danger-tint) !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
