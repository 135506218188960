// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .month-open {
  color: var(--ion-color-danger) !important;
  font-weight: bold;
}

::ng-deep .month-pending {
  color: #E38E14 !important;
  font-weight: bold;
}

::ng-deep .month-confirming {
  color: var(--ion-color-warning) !important;
  font-weight: bold;
}

::ng-deep .month-concluded {
  color: var(--ion-color-success) !important;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/components/select-modal/select-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,iBAAA;AACF;;AAEA;EACE,yBAAA;EACA,iBAAA;AACF;;AAEA;EACE,0CAAA;EACA,iBAAA;AACF;;AAEA;EACE,0CAAA;EACA,iBAAA;AACF","sourcesContent":["::ng-deep .month-open {\n  color: var(--ion-color-danger) !important;\n  font-weight: bold;\n}\n\n::ng-deep .month-pending {\n  color: #E38E14 !important;\n  font-weight: bold;\n}\n\n::ng-deep .month-confirming {\n  color: var(--ion-color-warning) !important;\n  font-weight: bold;\n}\n\n::ng-deep .month-concluded {\n  color: var(--ion-color-success) !important;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
