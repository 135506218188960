// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDW0y7Eq0YJtnuasKxRkKnaw-QT3uo-hLw",
    authDomain: "swiftshift-test-c3687.firebaseapp.com",
    projectId: "swiftshift-test-c3687",
    storageBucket: "swiftshift-test-c3687.appspot.com",
    messagingSenderId: "421593280066",
    appId: "1:421593280066:web:98420403d6d4abd10f87db",
    measurementId: "G-GSGY29WTZ9"
  },
};

export const cloudFunctionsUrlPrefix = {
  euWest: "https://europe-west1-swiftshift-test-c3687.cloudfunctions.net",
  // euWest: "http://localhost:5001/swiftshift-test-c3687/europe-west1",
  usCentral: "https://us-central1-swiftshift-test-c3687.cloudfunctions.net"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
