import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import {
  BrowserModule,
  //BrowserTransferStateModule,
} from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { Clipboard } from "@awesome-cordova-plugins/clipboard/ngx";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TaxdetailsModule } from "./shared-modules/taxdetails.module";
import { SedcardModule } from "./shared-modules/sedcard/sedcard.module";

import { Calendar } from "@awesome-cordova-plugins/calendar/ngx";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

import { FullCalendarModule } from "@fullcalendar/angular"; // for FullCalendar!
import { CommonModule } from "@angular/common";
import { File } from "@awesome-cordova-plugins/file/ngx";

import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
//import { Ng5SliderModule } from "ng5-slider";
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { IonicSelectableModule } from "ionic-selectable";
import { PreviewAnyFile } from "@awesome-cordova-plugins/preview-any-file/ngx";
import { FirebaseX } from "@awesome-cordova-plugins/firebase-x/ngx";


registerLocaleData(localeDe, localeDeExtra);

import { SelectModalComponent } from './components/select-modal/select-modal.component';
import { CheckboxModalComponent } from "./components/checkbox-modal/checkbox-modal.component";
import { FilterPipe } from './components/select-modal/search-filter/filter.pipe';



@NgModule({ declarations: [AppComponent, SelectModalComponent, CheckboxModalComponent, FilterPipe],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], 
    imports: [
        IonicModule.forRoot({innerHTMLTemplatesEnabled: true}),
        //BrowserTransferStateModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        TaxdetailsModule,
        SedcardModule,
        FullCalendarModule,
        CommonModule,
        //Ng5SliderModule],
        NgxSliderModule],
        providers: [
        StatusBar,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        Clipboard,
        Calendar,
        File,
        PreviewAnyFile,
        FirebaseX,
        IonicSelectableModule,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
