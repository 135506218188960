import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TaxdetailsFormComponent } from "src/app/components/taxdetails-form/taxdetails-form.component";
import { AutocompleteModule } from "./autocomplete/autocomplete.module";
import { IonicSelectableModule } from "ionic-selectable";

@NgModule({
    declarations: [TaxdetailsFormComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        IonicModule,
        AutocompleteModule,
    ],
    providers: [IonicSelectableModule],
    exports: [TaxdetailsFormComponent]
})
export class TaxdetailsModule {}
