import { Injectable } from "@angular/core";
import { fireAuth } from "src/app/shared-modules/initFirebase";
import { User, signInWithEmailAndPassword, sendEmailVerification, UserCredential, createUserWithEmailAndPassword, sendPasswordResetEmail } from "@firebase/auth";
import { AuthCredentials } from "src/app/models/user";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public userId: string;
  constructor() {}

  getUser(): Promise<User> {
    return new Promise((resolve, reject) => {
      fireAuth.onAuthStateChanged(
        (user) => {
          if (user) {
            resolve(user);
          } else {
            reject(null);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async loginUser(
    email: string,
    password: string
  ): Promise<AuthCredentials> {
    console.log("LOGINGIN");

    return  new Promise(async (resolve, reject) => {
      try {
        const firebaseCredential = await signInWithEmailAndPassword(fireAuth, email, password);

        resolve ( {
          uid : firebaseCredential.user.uid
        } )

      } catch (error) {
        reject(error)
      }
    })
  }
    

  async verifyMail(): Promise<void> {
    return sendEmailVerification(fireAuth.currentUser);
  }
  signupUser(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return createUserWithEmailAndPassword(fireAuth, email, password);
  }

  resetPassword(email: string): Promise<void> {
    console.log("send reset", email);
    return sendPasswordResetEmail(fireAuth, email)
      .then((f) => {
        console.log("successfully send pw reset", f);
      })
      .catch((e) => {
        console.error("fail send pw reset", e);
      });
  }

  logoutUser(): Promise<void> {
    return fireAuth.signOut();
  }

  deleteUser(): Promise<void> {
    return fireAuth.currentUser.delete();
  }
}
