import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-checkbox-modal',
  templateUrl: './checkbox-modal.component.html',
  styleUrls: ['./checkbox-modal.component.scss']
})
export class CheckboxModalComponent implements OnInit {
  @Input() items: string[] = [];
  @Input() selectedItems: string[] = [];

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    console.log("logging selectedItems from CheckBoxModal:", this.selectedItems)
  }

  toggleSelection(item: string, event?: Event) {
    // Optional: Prevent checkbox click from affecting parent events
    if (event) {
      event.stopPropagation();
    }

    const index = this.selectedItems.indexOf(item);
    if (index > -1) {
      this.selectedItems.splice(index, 1);
    } else {
      this.selectedItems.push(item);
    }

    console.log("Current selected items:", this.selectedItems);
  }

  closeModal() {
    this.modalController.dismiss({
      selectedItems: this.selectedItems
    }).catch(err => console.error("Close Modal Error:", err));
  }
}
