// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label {
  z-index: 10;
}

ion-item {
  border-radius: 8px;
  background-color: var(--ion-textinputbg) !important;
}

.errorbackdg {
  background: var(--ion-color-danger-tint) !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlZGNhcmQtZm9ybS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFdBQUE7QUFDRjs7QUFDQTtFQUNFLGtCQUFBO0VBQ0EsbURBQUE7QUFFRjs7QUFBQTtFQUNFLG1EQUFBO0FBR0YiLCJmaWxlIjoic2VkY2FyZC1mb3JtLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaW9uLWxhYmVsIHtcbiAgei1pbmRleDogMTA7XG59XG5pb24taXRlbSB7XG4gIGJvcmRlci1yYWRpdXM6IDhweDtcbiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0taW9uLXRleHRpbnB1dGJnKSAhaW1wb3J0YW50O1xufVxuLmVycm9yYmFja2RnIHtcbiAgYmFja2dyb3VuZDogdmFyKC0taW9uLWNvbG9yLWRhbmdlci10aW50KSAhaW1wb3J0YW50O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/components/sedcard-form/sedcard-form.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AACA;EACE,kBAAA;EACA,mDAAA;AAEF;;AAAA;EACE,mDAAA;AAGF;AACA,4lBAA4lB","sourcesContent":["ion-label {\n  z-index: 10;\n}\nion-item {\n  border-radius: 8px;\n  background-color: var(--ion-textinputbg) !important;\n}\n.errorbackdg {\n  background: var(--ion-color-danger-tint) !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
