// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .month-open {
  color: var(--ion-color-danger) !important;
  font-weight: bold;
}

::ng-deep .month-pending {
  color: #E38E14 !important;
  font-weight: bold;
}

::ng-deep .month-confirming {
  color: var(--ion-color-warning) !important;
  font-weight: bold;
}

::ng-deep .month-concluded {
  color: var(--ion-color-success) !important;
  font-weight: bold;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlbGVjdC1tb2RhbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLHlDQUFBO0VBQ0EsaUJBQUE7QUFDRjs7QUFFQTtFQUNFLHlCQUFBO0VBQ0EsaUJBQUE7QUFDRjs7QUFFQTtFQUNFLDBDQUFBO0VBQ0EsaUJBQUE7QUFDRjs7QUFFQTtFQUNFLDBDQUFBO0VBQ0EsaUJBQUE7QUFDRiIsImZpbGUiOiJzZWxlY3QtbW9kYWwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6Om5nLWRlZXAgLm1vbnRoLW9wZW4ge1xuICBjb2xvcjogdmFyKC0taW9uLWNvbG9yLWRhbmdlcikgIWltcG9ydGFudDtcbiAgZm9udC13ZWlnaHQ6IGJvbGQ7XG59XG5cbjo6bmctZGVlcCAubW9udGgtcGVuZGluZyB7XG4gIGNvbG9yOiAjRTM4RTE0ICFpbXBvcnRhbnQ7XG4gIGZvbnQtd2VpZ2h0OiBib2xkO1xufVxuXG46Om5nLWRlZXAgLm1vbnRoLWNvbmZpcm1pbmcge1xuICBjb2xvcjogdmFyKC0taW9uLWNvbG9yLXdhcm5pbmcpICFpbXBvcnRhbnQ7XG4gIGZvbnQtd2VpZ2h0OiBib2xkO1xufVxuXG46Om5nLWRlZXAgLm1vbnRoLWNvbmNsdWRlZCB7XG4gIGNvbG9yOiB2YXIoLS1pb24tY29sb3Itc3VjY2VzcykgIWltcG9ydGFudDtcbiAgZm9udC13ZWlnaHQ6IGJvbGQ7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/select-modal/select-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,iBAAA;AACF;;AAEA;EACE,yBAAA;EACA,iBAAA;AACF;;AAEA;EACE,0CAAA;EACA,iBAAA;AACF;;AAEA;EACE,0CAAA;EACA,iBAAA;AACF;AACA,o6BAAo6B","sourcesContent":["::ng-deep .month-open {\n  color: var(--ion-color-danger) !important;\n  font-weight: bold;\n}\n\n::ng-deep .month-pending {\n  color: #E38E14 !important;\n  font-weight: bold;\n}\n\n::ng-deep .month-confirming {\n  color: var(--ion-color-warning) !important;\n  font-weight: bold;\n}\n\n::ng-deep .month-concluded {\n  color: var(--ion-color-success) !important;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
