export class ContactPerson {
  id?: string;
  name: string;
  phone: string;
  email?: string;
  comment?: string;

  constructor(name: string, phone: string, email?: string, comment?: string, id?: string) {
    this.name = name;
    this.phone = phone;
    this.email = email || "";
    this.comment = comment || "";
    this.id = id || undefined;
  }

  static fromFirebase(o: any): ContactPerson {
    return new ContactPerson(o["name"], o["phone"], o["email"] || "", o["comment"] || "");
  }

  toFirebaseData(): any {
    return {
      id: this.id || "",
      name: this.name,
      phone: this.phone,
      email: this.email,
      comment: this.comment,
    };
  }
}

export class Partner {
  id: string;
  pname: string;
  partneraddress: string;
  contacts: ContactPerson[];

  constructor(id: string, name: string, address: string, contacts: ContactPerson[] = []) {
    this.id = id;
    this.pname = name;
    this.partneraddress = address;
    this.contacts = contacts || [];
  }

  static fromFirebase(o: any): Partner {
    let contacts: ContactPerson[] = [];
    if (o["contacts"]) {
      contacts = o["contacts"].map((c: any) => ContactPerson.fromFirebase(c));
    }
    return new Partner(o["id"], o["pname"], o["partneraddress"], contacts);
  }

  toFirebaseData(): any {
    return {
      id: this.id,
      pname: this.pname,
      partneraddress: this.partneraddress,
      contacts: this.contacts.map((c) => c.toFirebaseData()),
    };
  }
}
