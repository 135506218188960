import { Component, Input, OnInit } from '@angular/core';
import { PopoverController, ModalController } from '@ionic/angular'; // Import ModalController as well
import { Pipe, PipeTransform } from '@angular/core';
import { PayrollMonth } from 'src/app/services/admin/admin.service';
import moment from 'moment';

@Component({
  selector: 'app-select-modal',
  templateUrl: './select-modal.component.html',
  styleUrls: ['./select-modal.component.scss']
})
export class SelectModalComponent implements OnInit{
  @Input() title: string;
  @Input() items: string[] = [];
  @Input() showSearchBar: boolean = true;
  @Input() payrollMonths: PayrollMonth[] = [];
  public searchTerm: string = '';
  isMobile: boolean;

  constructor(
    private modalController: ModalController,
  ) {}

  ngOnInit() {
    console.log("isMobile: ", this.isMobile)
    console.log("Payroll Months:", this.payrollMonths);
  }


  
  selectModalItem(item: string) {
    this.modalController.dismiss({
      selectedItem: item
    }).catch(err => console.error("Modal Dismiss Error:", err));
  }
  
  closeModal() {
    this.modalController.dismiss().catch(err => console.error("Close Modal Error:", err));
  }

  getMonthClass(aPayrollMonth: PayrollMonth): string {
    if(aPayrollMonth) {
      switch (aPayrollMonth.status) {
        case 'open':
          return 'month-open';
        case 'pending':
          return 'month-pending';
        case 'confirming':
          return 'month-confirming';
        case 'concluded':
          return 'month-concluded';
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  formatDate(date: string): string {
    // Umwandlung des Datums im Format MM/YYYY zu einem lesbaren Format
    return moment(date, 'MM/YYYY').format('MMMM YYYY');
  }
  
}

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchTerm: string): any[] {
    if (!items || !searchTerm) {
      return items;
    }
    return items.filter(item =>
      item.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
}
