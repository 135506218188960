import { Injectable } from "@angular/core";
import { firebaseApp, fireDb, fireStorage } from "src/app/shared-modules/initFirebase";
import { AuthService } from "./auth.service";
import * as moment from "moment";
import { SedcardNew } from "src/app/models/sedcardNew";
import {
  AlertController,
  IonicSafeString,
  ModalController,
  NavController,
} from "@ionic/angular";
import { ToastService } from "../toast/toast.service";
import { environment } from "src/environments/environment";
import { doc, getDoc, DocumentReference, DocumentSnapshot, DocumentData, setDoc } from "@firebase/firestore";
import { User, EmailAuthProvider, AuthCredential, reauthenticateWithCredential, updateEmail, updatePassword } from "@firebase/auth"
import { getDownloadURL, listAll, ref, getMetadata } from "@firebase/storage";
import { getAnalytics, setAnalyticsCollectionEnabled, logEvent } from "firebase/analytics";


@Injectable({
  providedIn: "root",
})
export class ProfileService {
  public userProfileRef: DocumentReference;
  public userProfile: UserProfile = new UserProfile();
  public userRole: string;
  public currentUser: User;
  public profilepic: string = "";
  HTMLmsg: string = `<p dir="ltr" style="line-height:1.38;text-align: left;margin-top:0pt;margin-bottom:8pt;"><span style="font-size:12pt;font-family:OpenSans,sans-serif;color:#737373;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">Einwilligung in die Verarbeitung meiner personenbezogenen Daten&nbsp;</span></p>
  <p></p>
  <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
      <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:OpenSans,sans-serif;color:#737373;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 3.3000000000000007pt;">
          <p dir="ltr" style="line-height:1.38;text-align: left;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:OpenSans,sans-serif;color:#737373;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">Ich bin damit einverstanden, dass die von mir in meiner Sedcard und im Rahmen des Interviews angegebenen personenbezogenen Daten, gespeichert und durch Kunst &amp; Skumaj sowie deren Kunden f&uuml;r die Zwecke der eventuellen Begr&uuml;ndung eines Besch&auml;ftigungsverh&auml;ltnisses eingesehen und weiterverarbeitet werden. Mir ist bewusst, dass in diesen Daten besondere Kategorien im Sinne von Art. 9 Abs. 1 DSGVO enthalten sein k&ouml;nnen.</span></p>
      </li><br>

      <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:OpenSans,sans-serif;color:#737373;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 3.3000000000000007pt;">
          <p dir="ltr" style="line-height:1.38;text-align: left;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:OpenSans,sans-serif;color:#737373;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">Ich bin damit einverstanden, dass die Daten von Kunst &amp; Skumaj &uuml;ber die cloudbasierte Anwendung Firebase gespeichert werden.&nbsp;</span></p>
      </li><br>

      <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:OpenSans,sans-serif;color:#737373;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 3.3000000000000007pt;">
          <p dir="ltr" style="line-height:1.38;text-align: left;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:OpenSans,sans-serif;color:#737373;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">Die<a href="https://swift-shift.de/app-datenschutz/" target="_blank"> Datenschutzinformationen</a>&nbsp;</span><span style="font-size:12pt;font-family:OpenSans,sans-serif;color:#737373;background-color:#ffff00;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"></span><span style="font-size:12pt;font-family:OpenSans,sans-serif;color:#737373;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;habe ich zur Kenntnis genommen.</span></p>
      </li><br>

      <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:OpenSanst,sans-serif;color:#737373;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 3.25pt;">
          <p dir="ltr" style="line-height:1.38;text-align: left;margin-top:0pt;margin-bottom:8pt;"><span style="font-size:12pt;font-family:OpenSans,sans-serif;color:#737373;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">Diese Einwilligungserkl&auml;rung kann gem&auml;&szlig; Art. 7 Abs. 3 Satz 1 DSGVO jederzeit mit der Wirkung f&uuml;r die Zukunft formlos gegen&uuml;ber Kunst &amp; Skumaj widerrufen werden. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitung wird von dem Widerruf nicht ber&uuml;hrt.</span></p>
      </li>
  </ol>
  `;

  constructor(
    private authService: AuthService,
    private alertController: AlertController,
    private modalController: ModalController,
    private navController: NavController
  ) {
    if(!environment.production) {
      const fireAnalytics = getAnalytics(firebaseApp);
      setAnalyticsCollectionEnabled(fireAnalytics, true);
      logEvent(fireAnalytics, 'page_view', {page: "swsh_web_app"});
      console.log("analytics:",fireAnalytics);
    }

    this.initAllData();
  }

  navigateToOtherPage() {
    this.navController.navigateForward("consent");
  }

  async initAllData(): Promise<UserProfile> {
    let userProfile = await this.initUserProfile();
    await this.initProfilePic();
    return userProfile;
  }

  getUserProfile(): UserProfile {
    return this.userProfile;
  }

  async getProfilePic(): Promise<string> {
    await this.initProfilePic();
    return this.profilepic;
  }
  async getBodyPic(): Promise<string> {
    if (this.userProfile.id === "") {
      return null;
    }
    var pathReference = ref(fireStorage,
      "uploaddata/" + this.userProfile.id + "/body.png"
    );

    console.log("getBodyPic():", pathReference);
    return getDownloadURL(pathReference)
      .then((url) => {
        return url;
      })
      .catch(() => {
        return null;
      });
  }
  async initUserProfile(): Promise<UserProfile> {
    try {
      const user: User = await this.authService.getUser();
      this.currentUser = user;
      this.userProfileRef = doc(fireDb, `userProfile/${user.uid}`);
      console.log("TEST2");

      return getDoc(this.userProfileRef).then(async (snapShot) => {
        console.log("TEST");
        let dataSnap = snapShot.data();
        this.userProfile = new UserProfile();
        if (dataSnap) {
          this.userProfile = UserProfile.initFromObject(dataSnap);
          this.userRole = await this.getRole();
          localStorage.setItem("userProfile", JSON.stringify(this.userProfile));
          console.log("USERPROFILE: ", this.userProfile);
        }
        return this.userProfile;
      });
    } catch (e) {
      console.error("ERROR INITUSERPROFILE:", e);
      return null;
    }
  }

  async getRole(): Promise<any> {
    if (this.userProfile.id) {
      let roleref = doc(fireDb, "roles/" + this.userProfile.id + "/");
      let roleSnap: DocumentSnapshot<DocumentData>;
      try {
        roleSnap = await getDoc(roleref);
        return roleSnap.data().role;
      } catch (error) {
        console.error("Error getting Role for Id: " + this.userProfile.id + " - Errorcode: " +error);
        return false;
      }
    }
    return false;
  }
  async isRole(str: string): Promise<boolean> {
      const role = await this.getRole();
      if(role == str) {
        return true;
      } else {
        return false;
      }
  }
  async isAdmin(): Promise<boolean> {
    return this.isRole("admin");
  }
  async isNoob(): Promise<boolean> {
    return this.isRole("noob");
  }
  async isBeginner(): Promise<boolean> {
    return this.isRole("beginner");
  }
  updateName(firstName: string, lastName: string): Promise<void> {
    return setDoc(this.userProfileRef,{ firstName, lastName }, { merge: true })
      .then(() => {
        this.userProfile.firstName = firstName;
        this.userProfile.lastName = lastName;
      });
  }

  async updateSedcard(sedcard: SedcardNew, t: TaxDetails): Promise<any> {
    let s = sedcard.getData();
    console.log("SAVINGSEDCARD:", s);
    console.log("SAVINGSEDCARD:", sedcard);
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        cssClass: "text-align-left",
        header: "Einwilligung",
        message: new IonicSafeString(this.HTMLmsg),
        buttons: [
          {
            text: "Abbrechen",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              ToastService.makeToast(
                "Wir können die Änderungen nur mit Deiner Einwilligung vornehmen!"
              );
            },
          },
          {
            text: "Zustimmen",
            handler: async (a) => {
              console.log("ZUSTIMMEN:", a);

              await setDoc(this.userProfileRef,{ sedcard: s }, { merge: true })
                .then(() => {
                  this.updateTaxDetails(t);

                  this.userProfile.sedcard = sedcard;
                });
              ToastService.makeToast("Deine Sedcard wurde gespeichert.");
            },
          },
        ],
      });

      await alert.present();
    });
  }
  async updateSedcardAndLeave(
    sedcard: SedcardNew,
    t: TaxDetails
  ): Promise<any> {
    let s = sedcard.getData();
    console.log("SAVINGSEDCARD:", s);
    console.log("SAVINGSEDCARD:", sedcard);
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        cssClass: "text-align-left",
        header: "Einwilligung",
        message: new IonicSafeString(this.HTMLmsg),
        buttons: [
          {
            text: "Abbrechen",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              ToastService.makeToast(
                "Wir können die Änderungen nur mit Deiner Einwilligung vornehmen!"
              );
            },
          },
          {
            text: "Zustimmen",
            handler: async (a) => {
              console.log("ZUSTIMMEN:", a);

              await setDoc(this.userProfileRef, { sedcard: s }, { merge: true })
                .then(() => {
                  this.updateTaxDetails(t);
                  this.userProfile.sedcard = sedcard;
                });

              this.navController.navigateForward("/profiletabs").then(() => {
                ToastService.makeToast("Deine Sedcard wurde gespeichert.");
              });
            },
          },
        ],
      });

      await alert.present();
    });
  }
  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Alert",
      subHeader: "Subtitle",
      message: "This is an alert message.",
      buttons: ["OK"],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log("onDidDismiss resolved with role", role);
  }

  updateTaxDetails(taxDetails: TaxDetails) {
    return setDoc(this.userProfileRef,{ taxDetails: taxDetails.getTaxDetailsData() }, { merge: true })
      .then(() => {
        this.userProfile.taxDetails = taxDetails;
      });
  }

  async updateEmail(newEmail: string, password: string): Promise<void> {
    try {
      const credential: AuthCredential =
        EmailAuthProvider.credential(
          this.currentUser.email,
          password
        );

      await reauthenticateWithCredential(this.currentUser, credential);
      await updateEmail(this.currentUser, newEmail);
      return setDoc(this.userProfileRef,{ email: newEmail }, { merge: true })
        .then(() => {
          this.userProfile.email = newEmail;
        });
    } catch (error) {
      console.error(error);
    }
  }

  async updatePassword(
    newPassword: string,
    oldPassword: string
  ): Promise<void> {
    try {
      const credential: AuthCredential =
        EmailAuthProvider.credential(
          this.currentUser.email,
          oldPassword
        );

      await reauthenticateWithCredential(this.currentUser, credential);
      return updatePassword(this.currentUser, newPassword);
    } catch (error) {
      console.error(error);
    }
  }

  createKeywords(name) {
    const arrName = [];
    let curName = "";
    name.split("").forEach((letter) => {
      curName += letter;
      arrName.push(curName.toLowerCase());
    });
    return arrName;
  }

  generateKeywords(firstName, lastName) {
    const keywordFull = this.createKeywords(`${firstName} ${lastName}`);
    const keywordLastFirst = this.createKeywords(`${lastName} ${firstName}`);

    return [...new Set(["", ...keywordFull, ...keywordLastFirst])];
  }

  initRole(
    email: string,
    firstname: string,
    lastname: string,
    userid: string
  ): Promise<any> {
    this.userProfileRef = doc(fireDb, `userProfile/${userid}`);
    let us: UserProfile = new UserProfile();
    us.init(
      userid,
      firstname,
      lastname,
      email,
      "",
      new SedcardNew(),
      new TaxDetails(),
      this.generateKeywords(firstname, lastname),
      ""
    );
    let rolesRef = doc(fireDb, `roles/${userid}`);
    setDoc(rolesRef, { role: "noob" });
    return setDoc(this.userProfileRef, us.getUserProfileData(), { merge: true });
  }

  setAcceptMail(userid, flag: boolean): Promise<any> {
    let acceptMailRef = doc(fireDb, `acceptMail/${userid}`);
    let us: UserProfile = new UserProfile();

    return setDoc(acceptMailRef, { accept: flag }, { merge: true });
  }

  initProfilePic(): Promise<string> {
    if (this.userProfile.id === "") {
      return null;
    }
    var pathReference = ref(fireStorage,
      "uploaddata/" + this.userProfile.id + "/profilepicture.png"
    );

    console.log("initProfilepic:", pathReference);
    return getDownloadURL(pathReference)
      .then((url) => {
        this.profilepic = url;
        return url;
      })
      .catch((err) => {
        console.log("initProfilePic() - error:",err)
        this.profilepic = null;
        return null;
      });
  }

  async checkSedcardPics(): Promise<string> {
    let s = await this.getBodyPic();
    if (!s) {
      return "Kein Ganzkörperbild";
    }

    let p = await this.getProfilePic();
    if (!p) {
      return "Kein Profilbild";
    }

    return "true";
  }
}

export class UserProfile {
  id: string = "";
  phoneAppointment: string = "";
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  sedcard: SedcardNew = new SedcardNew();
  taxDetails: TaxDetails = new TaxDetails();
  keywords: String[] = [];
  deviceToken: string = "";
  constructor() {}

  getAdress(): string {
    return (
      this.sedcard.zip +
      " " +
      this.sedcard.city +
      "," +
      this.sedcard.street +
      " " +
      this.sedcard.number
    );
  }
  createKeywords(name) {
    const arrName = [];
    let curName = "";
    name.split("").forEach((letter) => {
      curName += letter;
      arrName.push(curName.toLowerCase());
    });
    return arrName;
  }

  generateKeywords(firstName, lastName) {
    const keywordFull = this.createKeywords(`${firstName} ${lastName}`);
    const keywordLastFirst = this.createKeywords(`${lastName} ${firstName}`);

    return [...new Set(["", ...keywordFull, ...keywordLastFirst])];
  }

  getUserProfileData() {
    let kw = this.generateKeywords(this.firstName, this.lastName);
    console.log("KW:", kw);
    return {
      id: this.id,
      phoneAppointment: this.phoneAppointment,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      sedcard: this.sedcard.getData(),
      taxDetails: this.taxDetails.getTaxDetailsData(),
      keywords: kw
    };
  }

  containsSearchTerm(searchterm: string): boolean {
    for (let k of Object.keys(this)) {
      if (k !== "sedcard" && k !== "taxDetails" && k !== "active") {
        try {
          if (
            this[k] &&
            this[k].toLowerCase().indexOf(searchterm.toLocaleLowerCase()) > -1
          ) {
            return true;
          }
        } catch (e) {
          console.error(this[k]);
          console.error(e);
        }
      }
    }
    for (let k of Object.keys(this.sedcard)) {
      if (typeof k === "string") {
        try {
          if (
            this.sedcard[k] &&
            typeof this.sedcard[k] === "string" &&
            this.sedcard[k]
              .toLowerCase()
              .indexOf(searchterm.toLocaleLowerCase()) > -1
          ) {
            return true;
          }
        } catch (e) {
          console.log("ERROR", this.sedcard[k]);
          console.error("ERROR", k);
          console.error(e);
        }
      }
    }
    return false;
  }

  init(
    id,
    firstName,
    lastName,
    email: string,
    phoneAppointment,
    sedcard,
    taxdetail,
    keywords,
    deviceToken
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email.toLocaleLowerCase();
    this.phoneAppointment = phoneAppointment;
    this.sedcard.initFromObject(sedcard);
    this.taxDetails.initWithObject(taxdetail);
    this.keywords = keywords;
    this.deviceToken = deviceToken;
  }

  static initFromObject(o: Object): UserProfile {
    let u = new UserProfile();
    try {
      u.id = o["id"];
      u.firstName = o["firstName"];
      u.lastName = o["lastName"];
      u.email = o["email"];
      u.phoneAppointment = o["phoneAppointment"];
      u.sedcard.initFromObject(o["sedcard"]);
      u.taxDetails.initWithObject(o["taxDetails"]);
      u.keywords = u.generateKeywords(u.firstName, u.lastName);
      u.deviceToken = o["deviceToken"];
    } catch (error) {
      console.error("ProfileService - initFromObject() - error during initialization: "+JSON.stringify(o))
    }
    return u;
  }

  static isValid(user: UserProfile): Map<string, string> {
    //Definition of a valid job.
    //start time and endtime have valid format
    //start time is before end time
    //contact phone has the correct format +4917657906231
    //no field is empty
    const errors: Map<string, string> = new Map();
    if (this.isEmpty(user.firstName)) {
      errors.set("name", "Mindestens 5 Zeichen");
    }
    return errors;
  }

  static areTaxDetailsValid(t: TaxDetails): Map<string, string> {
    //Definition of a valid job.
    //start time and endtime have valid format
    //start time is before end time
    //contact phone has the correct format +4917657906231
    //no field is empty
    let errors: Map<string, string> = new Map();
    if (this.isLessThan5(t.street)) {
      errors.set("street", "Mindestens 5 Zeichen");
    }

    if (this.isEmpty(t.number)) {
      errors.set("number", "ungültiges Format.");
    }

    if (this.isEmpty(t.zip)) {
      errors.set("zip", "ungültiges Format.");
    }

    if (this.isEmpty(t.city)) {
      errors.set("city", "ungültiges Format.");
    }

    if (this.isEmpty(t.birthDate)) {
      errors.set("birthDate", "ungültiges Format.");
    }
    if (!this.dateIsValidFormat(t.birthDate)) {
      errors.set("birthDate", "Kein gültiges Format");
    }

    if (this.isEmpty(t.birthplace)) {
      errors.set("birthplace", "ungültiges Format.");
    }

    if (this.isEmpty(t.insurancenr)) {
      errors.set("insurancenr", "ungültiges Format.");
    }

    if (this.isEmpty(t.maritalStatus)) {
      errors.set("maritalStatus", "ungültiges Format.");
    }

    if (this.isEmpty(t.handicap)) {
      errors.set("handicap", "ungültiges Format.");
    }

    if (this.isEmpty(t.nationality)) {
      errors.set("nationality", "ungültiges Format.");
    }

    if (this.isEmpty(t.country)) {
      errors.set("country", "ungültiges Format.");
    }

    if (!this.isIBAN(t.iban)) {
      errors.set("iban", "ungültiges Format");
    }

    if (!this.isBIC(t.bic)) {
      errors.set("bic", "ungültiges Format");
    }

    if (this.isEmpty(t.bankname)) {
      errors.set("bankname", "ungültiges Format.");
    }

    if (this.isEmpty(t.accountHolder)) {
      errors.set("accountHolder", "ungültiges Format.");
    }

    if (this.isEmpty(t.highestSchool)) {
      errors.set("highestSchool", "ungültiges Format.");
    }

    if (this.isEmpty(t.highestJobSchool)) {
      errors.set("highestJobSchool", "ungültiges Format.");
    }

    if (this.isEmpty(t.status)) {
      errors.set("status", "ungültiges Format.");
    }

    if (!this.isIDNR(t.idnr)) {
      errors.set("idnr", "ungültig");
    }

    if (this.isEmpty(t.taxclass)) {
      errors.set("taxclass", "ungültiges Format.");
    }

    if (!this.isConfession(t.confession)) {
      errors.set("confession", "keine gültige Konfession");
    }

    if (this.isEmpty(t.healthInsurance)) {
      errors.set("healthInsurance", "ungültiges Format.");
    }

    if (this.isEmpty(t.healthInsuranceName)) {
      errors.set("healthInsuranceName", "ungültiges Format.");
    }
    if (this.isEmpty(t.healthInsuranceLocation)) {
      errors.set("healthInsuranceLocation", "ungültiges Format.");
    }
    if (this.isEmpty(t.furtherJobs) || ["y", "n"].includes(t.furtherJobs)) {
      errors.set("furtherJobs", "ungültig");
    }

    if (t.furtherJobs === "y") {
      console.log("FURTHERJOBS");
      const e = this.validateFurtherJobs(t.furtherJobObjects);
      errors = new Map([...errors, ...e]);
    }

    return errors;
  }

  static validateFurtherJobs(t: TaxJob[]): Map<string, string> {
    const errors: Map<string, string> = new Map();
    for (let i = 0; i < t.length; i++) {
      const tj = t[i];
      if (!this.dateIsValidFormat(tj.from)) {
        errors.set(`furtherJobObjects${i}from`, "ungültig");
      }
      if (!this.dateIsValidFormat(tj.to)) {
        errors.set(`furtherJobObjects${i}to`, "ungültig");
      }

      if (this.dateIsValidFormat(tj.from) && this.dateIsValidFormat(tj.to)) {
        console.log(tj.from);
        console.log(tj.to);
        console.log(
          moment(tj.from, "YYYY-MM-DD").isAfter(moment(tj.to, "YYYY-MM-DD"))
        );
        if (
          moment(tj.from, "YYYY-MM-DD").isAfter(moment(tj.to, "YYYY-MM-DD"))
        ) {
          errors.set(
            `furtherJobObjects${i}to`,
            "Anfangsdatum ist nach Enddatum"
          );
        }
      }
      if (this.isEmpty(tj.employer)) {
        errors.set(`furtherJobObjects${i}employer`, "ungültig");
      }

      if (!(tj.weeklyWorkHours && /^\d+(\,\d+)?$/.test(tj.weeklyWorkHours))) {
        errors.set(
          `furtherJobObjects${i}weeklyWorkHours`,
          "Nur Ganze Zahl oder Zahl mit Nachkommastellen"
        );
      }

      if (
        ![
          "geringfügig entlohnt",
          "nicht geringfügig entlohnt",
          "kurzfristig beschäftigt",
        ].includes(tj.kindOfJob)
      ) {
        errors.set(`furtherJobObjects${i}kindOfJob`, "keine gültige Auswahl");
      }
    }
    return errors;
  }

  static isConfession(c: string): boolean {
    if (
      [
        "Konfessionslos / Keine Kirchensteuerberechnung",
        "ev - Evangelische Kirchensteuer",
        "rk - Römisch-Katholische Kirchensteuer",
        "ak - Altkatholische Kirchensteuer",
        "fa - Freie Religionsgemeinschaft Alzey",
        "fb - Freireligiöse Landesgemeinde Baden",
        "fg - Freireligiöse Landesgemeinde Pfalz",
        "fm - Freireligiöse Gemeinde Mainz",
        "fr - Französisch reformiert (bis 12/2015)",
        "fs - Freireligiöse Gemeinde Offenback/Main",
        "ib - Israelitische Religionsgemeinschaft Baden",
        "ih - Jüdische Kultussteuer",
        "il - Israelitische Kultussteuer der kultusberechtigten Gemeinden",
      ].includes(c)
    ) {
      return true;
    }
    return false;
  }

  static isIDNR(idnr: string): boolean {
    if (idnr === "unknown") {
      return true;
    }
    const idnra = idnr.toString().substring(0, idnr.length - 1);

    function checkcifs(id: any) {
      const ca = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
      for (let i = 0; i < id.length; i++) {
        ca[1 * id.substr(i, 1)]++;
      }
      let ng1 = 0;
      let ng3 = 0;
      for (let i = 0; i < id.length; i++) {
        const n = ca[i];
        if (n > 1) {
          ng1++;
          if (n > 3) {
            ng3++;
          }
        }
      }
      let err = 0;
      if (ng1 < 1) err += 1;
      if (ng1 > 1) err += 2;
      if (ng3 > 0) err += 4;
      return err;
    }

    const t = idnra;
    const r = t.length;
    const chk = checkcifs(t);
    if (r > 9) {
      let sum = 0;
      let prod = 10;
      for (let i = 0; i < 10; i++) {
        sum = (1 * parseInt(t.substr(i, 1)) + prod) % 10;
        if (sum < 1) sum = 10;
        prod = (sum * 2) % 11;
      }
      const tcl = (11 - prod) % 10;
      let fb = "";
      if (chk === 1) {
        fb = "Es dürfen nicht alle Ziffern 0-9 vorkommen.";
      } else if (chk > 3) {
        fb = "Keine Ziffer darf mehr als 3-mal vorkommen.";
      } else if (chk === 2) {
        fb = "Nur eine Ziffer darf mehrfach vorkommen.";
      }
      if (fb === "") {
        console.log("IDNR:", tcl);
        console.log("IDNR:", idnr[idnr.length - 1]);
        if (tcl === parseInt(idnr[idnr.length - 1])) {
          return true;
        } else {
          fb = "Die Identifikationsnummer ist ungültig.";
        }
      }

      return false;
    } else {
      return false;
    }
  }

  static isBIC(bic: string): boolean {
    if (
      bic &&
      /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(bic)
    ) {
      return true;
    }
    return false;
  }

  static isIBAN(iban1: string): boolean {
    const input1 = iban1;
    /*
     * Returns 1 if the IBAN is valid
     * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
     * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
     */
    function isValidIBANNumber(input: any) {
      const CODE_LENGTHS: Map<string, number> = new Map([
        ["AD", 24],
        ["AE", 23],
        ["AT", 20],
        ["AZ", 28],
        ["BA", 20],
        ["BE", 16],
        ["BG", 22],
        ["BH", 22],
        ["BR", 29],
        ["CH", 21],
        ["CR", 21],
        ["CY", 28],
        ["CZ", 24],
        ["DE", 22],
        ["DK", 18],
        ["DO", 28],
        ["EE", 20],
        ["ES", 24],
        ["FI", 18],
        ["FO", 18],
        ["FR", 27],
        ["GB", 22],
        ["GI", 23],
        ["GL", 18],
        ["GR", 27],
        ["GT", 28],
        ["HR", 21],
        ["HU", 28],
        ["IE", 22],
        ["IL", 23],
        ["IS", 26],
        ["IT", 27],
        ["JO", 30],
        ["KW", 30],
        ["KZ", 20],
        ["LB", 28],
        ["LI", 21],
        ["LT", 20],
        ["LU", 20],
        ["LV", 21],
        ["MC", 27],
        ["MD", 24],
        ["ME", 22],
        ["MK", 19],
        ["MR", 27],
        ["MT", 31],
        ["MU", 30],
        ["NL", 18],
        ["NO", 15],
        ["PK", 24],
        ["PL", 28],
        ["PS", 29],
        ["PT", 25],
        ["QA", 29],
        ["RO", 24],
        ["RS", 22],
        ["SA", 24],
        ["SE", 24],
        ["SI", 19],
        ["SK", 24],
        ["SM", 27],
        ["TN", 24],
        ["TR", 26],
      ]);
      const iban = String(input)
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, ""); // keep only alphanumeric characters
      const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
      let digits;
      // check syntax and length
      if (!code || iban.length !== CODE_LENGTHS.get(code[1])) {
        return false;
      }
      // rearrange country code and check digits, and convert chars to ints
      digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) =>
        (letter.charCodeAt(0) - 55).toString()
      );
      // final check
      return mod97(digits);
    }
    function mod97(string: any) {
      let checksum = string.slice(0, 2),
        fragment;
      for (let offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
      }
      return checksum;
    }

    if (isValidIBANNumber(input1) === 1) {
      return true;
    }
    return false;
  }

  static dateIsValidFormat(date: any): boolean {
    if (
      date &&
      /^\d\d\d\d\-\d\d\-\d\d$/.test(date) &&
      moment(date, "YYYY-MM-DD").isValid()
    ) {
      return true;
    }
    return false;
  }

  static isEmpty(s: any): boolean {
    if (s.length < 1 || s === "") {
      return true;
    }
    return false;
  }
  static isLessThan5(s: any): boolean {
    if (s.length < 5) {
      return true;
    }
    return false;
  }
}
export class UserComment {
  id: string = "";
  reference: string = "";
  availability: string = "";
  activity: string = "";
  mobility: string = "";
  languages: string = "";
  studyend: string = "";
  sympathie: number = 0;
  talkin: number = 0;
  confidence: number = 0;
  other: string = "";
  comment: string = "";
  studyendpos: string[] = [
    "WS20/21",
    "SS21",
    "WS21/22",
    "SS22",
    "WS22/23",
    "SS23",
    "WS23/24",
    "SS24",
    "WS24/25",
    "SS25",
    "WS25/26",
    "SS26",
    "WS26/27",
    "SS27",
    "WS27/28",
    "SS28",
    "WS28/29",
    "SS29",
  ];
  constructor() {}

  init(d) {
    this.reference = d["reference"] ?? "";
    this.availability = d["availability"] ?? "";
    this.activity = d["activity"] ?? "";
    this.mobility = d["mobility"] ?? "";
    this.languages = d["languages"] ?? "";
    this.studyend = d["studyend"] ?? "";
    this.sympathie = d["sympathie"] ?? 0;
    this.talkin = d["talkin"] ?? 0;
    this.confidence = d["confidence"] ?? 0;
    this.other = d["other"] ?? "";
    this.comment = d["comment"] ?? "";
  }

  getData() {
    return {
      reference: this.reference,
      availability: this.availability,
      activity: this.activity,
      mobility: this.mobility,
      languages: this.languages,
      studyend: this.studyend,
      sympathie: this.sympathie,
      talkin: this.talkin,
      confidence: this.confidence,
      other: this.other,
      comment: this.comment,
    };
  }
}

export class SedCard {
  street: string = "";
  number: string = "";
  zip: string = "";
  city: string = "";
  bigcity: string = "";
  phone: string = "";
  email: string = "";
  haircolor: string = "";
  size: string = "";
  jeans: string = "";
  piercing: string = "";
  tattoo: string = "";
  confectionsize: string = "";
  shoes: string = "";
  car: string = "";
  driverslicense: string = "";
  classe: string = "";
  sleepcities: string = "";
  school: string = "";
  uni: string = "";
  ausbildung: string = "";
  job: string = "";
  specialskills: string = "";

  emptySetCard(): string[] {
    let m: string[] = [];
    m.push("street");
    m.push("number");
    m.push("zip");
    m.push("city");
    m.push("bigcity");
    m.push("phone");
    m.push("email");
    m.push("haircolor");
    m.push("size");
    m.push("jeans");
    m.push("piercing");
    m.push("tattoo");
    m.push("confectionsize");
    m.push("shoes");
    m.push("car");
    m.push("driverslicense");
    m.push("classe");
    m.push("sleepcities");
    m.push("school");
    m.push("uni");
    m.push("ausbildung");
    m.push("job");
    m.push("specialskills");
    return m;
  }

  constructor() {}

  initWithObject(o: Object) {
    console.log("SEDCARDOBJECTINIT: ", o);
    try {
      this.phone = o["phone"];
      this.street = o["street"];
      this.number = o["number"];
      this.zip = o["zip"];
      this.city = o["city"];
      this.bigcity = o["bigcity"];
      this.email = o["email"];
      this.haircolor = o["haircolor"];
      this.size = o["size"];
      this.jeans = o["jeans"];
      this.piercing = o["piercing"];
      this.tattoo = o["tattoo"];
      this.confectionsize = o["confectionsize"];
      this.shoes = o["shoes"];
      this.car = o["car"];
      this.driverslicense = o["driverslicense"];
      this.classe = o["classe"];
      this.sleepcities = o["sleepcities"];
      this.school = o["school"];
      this.uni = o["uni"];
      this.ausbildung = o["ausbildung"];
      this.job = o["job"];
      this.specialskills = o["specialskills"];
    } catch (e) {
      console.error(e);
    }
  }

  initWithSedCard(s: SedCard) {
    this.init(
      s.street,
      s.number,
      s.zip,
      s.city,
      s.bigcity,
      s.phone,
      s.email,
      s.haircolor,
      s.size,
      s.jeans,
      s.piercing,
      s.tattoo,
      s.confectionsize,
      s.shoes,
      s.car,
      s.driverslicense,
      s.classe,
      s.sleepcities,
      s.school,
      s.uni,
      s.ausbildung,
      s.job,
      s.specialskills
    );
  }

  init(
    street,
    number,
    zip,
    city,
    bigcity,
    phone,
    email,
    haircolor,
    size,
    jeans,
    piercing,
    tattoo,
    confectionsize,
    shoes,
    car,
    driverslicense,
    classe,
    sleepcities,
    school,
    uni,
    ausbildung,
    job,
    specialskills
  ): SedCard {
    this.street = street;
    this.number = number;
    this.zip = zip;
    this.city = city;
    this.bigcity = bigcity;
    this.phone = phone;
    this.email = email;
    this.haircolor = haircolor;
    this.size = size;
    this.jeans = jeans;
    this.piercing = piercing;
    this.tattoo = tattoo;
    this.confectionsize = confectionsize;
    this.shoes = shoes;
    this.car = car;
    this.driverslicense = driverslicense;
    this.classe = classe;
    this.sleepcities = sleepcities;
    this.school = school;
    this.uni = uni;
    this.ausbildung = ausbildung;
    this.job = job;
    this.specialskills = specialskills;
    return this;
  }

  getSedCardData() {
    return {
      street: this.street,
      number: this.number,
      zip: this.zip,
      city: this.city,
      bigcity: this.bigcity,
      phone: this.phone,
      email: this.email,
      haircolor: this.haircolor,
      size: this.size,
      jeans: this.jeans,
      piercing: this.piercing,
      tattoo: this.tattoo,
      confectionsize: this.confectionsize,
      shoes: this.shoes,
      car: this.car,
      driverslicense: this.driverslicense,
      classe: this.classe,
      sleepcities: this.sleepcities,
      school: this.school,
      uni: this.uni,
      ausbildung: this.ausbildung,
      job: this.job,
      specialskills: this.specialskills,
    };
  }
}

export class TaxDetails {
  street: string = "";
  number: string = "";
  zip: string = "";
  city: string = "";
  birthDate: string = "";
  birthplace: string = "";
  insuranceWork: string = "";
  insurancenr: string = "";
  maritalStatus: string = "";
  handicap: string = "";
  nationality: string = "";
  country: string = "";
  iban: string = "";
  bic: string = "";
  bankname: string = "";
  accountHolder: string = "";
  highestSchool: string = "";
  highestJobSchool: string = "";
  status: string = "";
  idnr: string = "";
  taxclass: string = "";
  factor: string = "";
  childrenTaxAllowance: string = "";
  confession: string = "";
  healthInsurance: string = "";
  healthInsuranceName: string = "";
  healthInsuranceLocation: string = "";
  furtherJobs: string = "";
  amountFurtherJobs: string = "";
  furtherJobObjects: TaxJob[] = [];
  entryDate: string = "";
  firstEntryDate: string = "";
  jobTitle: string = "";
  personalNumber: number = 0;

  initWithObject(o: Object) {
    try {
      this.street = o["street"] || "";
      this.number = o["number"] || "";
      this.zip = o["zip"] || "";
      this.city = o["city"] || "";
      this.birthDate = o["birthDate"] || "";
      this.birthplace = o["birthplace"] || "";
      this.insuranceWork = o["insuranceWork"] || "";
      this.insurancenr = o["insurancenr"] || "";
      this.maritalStatus = o["maritalStatus"] || "";
      this.handicap = o["handicap"] || "";
      this.nationality = o["nationality"] || "";
      this.country = o["country"] || "";
      this.iban = o["iban"] || "";
      this.bic = o["bic"] || "";
      this.bankname = o["bankname"] || "";
      this.accountHolder = o["accountHolder"] || "";
      this.highestSchool = o["highestSchool"] || "";
      this.highestJobSchool = o["highestJobSchool"] || "";
      this.status = o["status"] || "";
      this.idnr = o["idnr"] || "";
      this.taxclass = o["taxclass"] || "";
      this.factor = o["factor"] || "";
      this.childrenTaxAllowance = o["childrenTaxAllowance"] || "";
      this.confession = o["confession"] || "";
      this.healthInsurance = o["healthInsurance"] || "";
      this.healthInsuranceName = o["healthInsuranceName"] || "";
      this.healthInsuranceLocation = o["healthInsuranceLocation"] || "";
      this.furtherJobs = o["furtherJobs"] || "";
      this.amountFurtherJobs = o["amountFurtherJobs"] || "";
      this.furtherJobObjects = o["furtherJobObjects"] || [];
      this.entryDate = o["entryDate"] || "";
      this.firstEntryDate = o["firstEntryDate"] || "";
      this.jobTitle = o["jobTitle"] || "";
      this.personalNumber = o["personalNumber"] || 0;
    } catch (e) {
      console.error("TAXDETAILSERROR", o, e);
    }
  }

  emptyTaxDetails() {
    let m: string[] = [];
    m.push("street");
    m.push("number");
    m.push("zip");
    m.push("city");
    m.push("birthDate");
    m.push("birthplace");
    m.push("insuranceWork");
    m.push("insurancenr");
    m.push("maritalStatus");
    m.push("handicap");
    m.push("nationality");
    m.push("country");
    m.push("iban");
    m.push("bic");
    m.push("bankname");
    m.push("accountHolder");
    m.push("highestSchool");
    m.push("highestJobSchool");
    m.push("status");
    m.push("idnr");
    m.push("taxclass");
    m.push("factor");
    m.push("childrenTaxAllowance");
    m.push("confession");
    m.push("healthInsurance");
    m.push("healthInsuranceName");
    m.push("healthInsuranceLocation");
    m.push("furtherJobs");
    m.push("entryDate");
    m.push("firstEntryDate");
    m.push("jobTitle");
    m.push("personalNumber");
    return m;
  }

  init(
    street,
    number,
    zip,
    city,
    birthDate,
    birthplace,
    insuranceWork,
    insurancenr,
    maritalStatus,
    handicap,
    nationality,
    country,
    iban,
    bic,
    bankname,
    accountHolder,
    highestSchool,
    highestJobSchool,
    status,
    idnr,
    taxclass,
    factor,
    childrenTaxAllowance,
    confession,
    healthInsurance,
    healthInsuranceName,
    healthInsuranceLocation,
    furtherJobs,
    entryDate,
    firstEntryDate,
    jobTitle,
    personalNumber
  ) {
    this.street = street;
    this.number = number;
    this.zip = zip;
    this.city = city;
    this.birthDate = birthDate;
    this.birthplace = birthplace;
    this.insuranceWork = insuranceWork;
    this.insurancenr = insurancenr;
    this.maritalStatus = maritalStatus;
    this.handicap = handicap;
    this.nationality = nationality;
    this.country = country;
    this.iban = iban;
    this.bic = bic;
    this.bankname = bankname;
    this.accountHolder = accountHolder;
    this.highestSchool = highestSchool;
    this.highestJobSchool = highestJobSchool;
    this.status = status;
    this.idnr = idnr;
    this.taxclass = taxclass;
    this.factor = factor;
    this.childrenTaxAllowance = childrenTaxAllowance;
    this.confession = confession;
    this.healthInsurance = healthInsurance;
    this.healthInsuranceName = healthInsuranceName;
    this.healthInsuranceLocation = healthInsuranceLocation;
    this.furtherJobs = furtherJobs;
    this.entryDate = entryDate;
    this.firstEntryDate = firstEntryDate;
    this.jobTitle = jobTitle;
    this.personalNumber = personalNumber;
  }

  getTaxDetailsData() {
    return {
      street: this.street,
      number: this.number,
      zip: this.zip,
      city: this.city,
      birthDate: this.birthDate,
      birthplace: this.birthplace,
      insuranceWork: this.insuranceWork,
      insurancenr: this.insurancenr,
      maritalStatus: this.maritalStatus,
      handicap: this.handicap,
      nationality: this.nationality,
      country: this.country,
      iban: this.iban,
      bic: this.bic,
      bankname: this.bankname,
      accountHolder: this.accountHolder,
      highestSchool: this.highestSchool,
      highestJobSchool: this.highestJobSchool,
      status: this.status,
      idnr: this.idnr,
      taxclass: this.taxclass,
      factor: this.factor,
      childrenTaxAllowance: this.childrenTaxAllowance,
      confession: this.confession,
      healthInsurance: this.healthInsurance,
      healthInsuranceName: this.healthInsuranceName,
      healthInsuranceLocation: this.healthInsuranceLocation,
      furtherJobs: this.furtherJobs,
      furtherJobObjects: this.furtherJobObjects,
      amountFurtherJobs: this.amountFurtherJobs,
      entryDate: this.entryDate,
      firstEntryDate: this.firstEntryDate,
      jobTitle: this.jobTitle,
      personalNumber: this.personalNumber,
    };
  }
  async getTaxDetailsDataForHuman() {
    let names = await (await fetch("./assets/lang/de.json")).json();
    console.log("NAMES", names);
    let out = {};
    out[names["street"]] = this.street;
    out[names["number"]] = this.number;
    out[names["zip"]] = this.zip;
    out[names["city"]] = this.city;
    out[names["birthDate"]] = this.birthDate;
    out[names["birthplace"]] = this.birthplace;
    out[names["insuranceWork"]] = this.insuranceWork;
    out[names["insurancenr"]] = this.insurancenr;
    out[names["maritalStatus"]] = this.maritalStatus;
    out[names["handicap"]] = this.handicap;
    out[names["nationality"]] = this.nationality;
    out[names["country"]] = this.country;
    out[names["iban"]] = this.iban;
    out[names["bic"]] = this.bic;
    out[names["bankname"]] = this.bankname;
    out[names["accountHolder"]] = this.accountHolder;
    out[names["highestSchool"]] = this.highestSchool;
    out[names["highestJobSchool"]] = this.highestJobSchool;
    out[names["status"]] = this.status;
    out[names["idnr"]] = this.idnr;
    out[names["taxclass"]] = this.taxclass;
    out[names["factor"]] = this.factor;
    out[names["childrenTaxAllowance"]] = this.childrenTaxAllowance;
    out[names["confession"]] = this.confession;
    out[names["healthInsurance"]] = this.healthInsurance;
    out[names["healthInsuranceName"]] = this.healthInsuranceName;
    out[names["healthInsuranceLocation"]] = this.healthInsuranceLocation;
    out[names["furtherJobs"]] = this.furtherJobs;

    let allJobs = "";
    for (const n of this.furtherJobObjects) {
      let t: TaxJob = new TaxJob();
      t.init(n);
      console.log("NNNN", t.toJSONString());
      allJobs += t.toJSONString() + ",";
    }

    out[names["furtherJobObjects"]] = allJobs;
    out[names["amountFurtherJobs"]] = this.amountFurtherJobs;
    console.log("OUT", out);

    return out;
  }
}

export class TaxJob {
  from: string = "";
  to: string = "";
  employer: string = "";
  weeklyWorkHours: string = "";
  kindOfJob: string = "";
  fromMobile: string = "";
  toMobile: string = "";

  init(o: Object) {
    this.from = o["from"];
    this.to = o["to"];
    this.employer = o["employer"];
    this.weeklyWorkHours = o["weeklyWorkHours"];
    this.kindOfJob = o["kindOfJob"];
  }
  toString(): string {
    let out = "Zeitraum von:  " + this.from + "\n";
    out += "Zeitraum bis:  " + this.to + "\n";
    out += "Arbeitgeber:  " + this.employer + "\n";
    out +=
      "Wöchentliche Arbeitszeit in Stunden:  " + this.weeklyWorkHours + "\n";
    out += "Art der Tätigkeit:  " + this.kindOfJob + "\n";

    return out;
  }

  toJSONString(): string {
    let out = "{'Zeitraum von':  '" + this.from + "',";
    out += "'Zeitraum bis':  '" + this.to + "',";
    out += "'Arbeitgeber':  '" + this.employer + "',";
    out +=
      "'Wöchentliche Arbeitszeit in Stunden':  '" + this.weeklyWorkHours + "',";
    out += "'Art der Tätigkeit':  '" + this.kindOfJob + "'}";

    return out;
  }
}
