import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SwshUtils {
  isEuropeanNationality(aNationality: string): boolean {
    return this.european_nationalities.includes(aNationality);
  }
  getEuropeanCountries(): string[] {
    let countries: string[] = [];
    for(const country of Object.keys(this.european_country_codes)) {
      countries.push(country);
    }
    return countries;
  }

  limitationLengths: {[key: string]: string} = {
    "unlimited": "unbefristet",
    "year": "auf ein Jahr befristet",
    "halfyear": "auf ein Halbjahr befristet",
    "month": "auf einen Monat befristet",
    "custom": "individuelle Befristung"
  }

  limitationReasons: {[key: string]: string[]} = {
    "reason1": [
              `da ich die Arbeitsmöglichkeit bei der SwiftShift GmbH nur als Zwischenlösung zur vorübergehenden (Teil-)Finanzierung meines Lebensunterhalts sehe und langfristig andere Arbeitsmöglichkeiten wahrnehmen möchte.`,
              `Der Arbeitnehmer sieht die Arbeitsmöglichkeit bei der SwiftShift GmbH nur als Zwischenlösung zur vorübergehenden (Teil-)Finanzierung seines Lebensunterhalts und möchte langfristig andere Jobmöglichkeiten wahrnehmen.`],
    "reason2": [
              `da ich als Studierender aufgrund von langfristig nicht feststehenden persönlichen Lebens- und Arbeitsumständen nicht dauerhaft über den Einsatz meiner Arbeitskraft disponieren, sondern bewusst nur vorübergehend planen will.`,
              `Der Arbeitnehmer als Studierender aufgrund von langfristig nicht feststehenden persönlichen Lebens- und Arbeitsumständen nicht dauerhaft über den Einsatz seiner Arbeitskraft disponieren, sondern bewusst nur vorübergehend planen will.`],
    "reason3": [
              `da ich außerhalb der Arbeitsmöglichkeit bei der SwiftShift GmbH einem zeitlich schwankenden Arbeitsaufwand gegenüberstehe und somit nicht langfristig disponieren will.`,
              `Der Arbeitnehmer außerhalb seines Beschäftigungsverhältnisses bei der SwiftShift GmbH, einem zeitlich schwankenden Arbeitsaufwand gegenübersteht und somit nicht langfristig disponieren will.`],
    "reason4": [
              `da ich bevorzuge einsatzbezogen in einem Arbeitsverhältnis mit der SwiftShift GmbH meine Arbeitskraft zur Verfügung zu stellen und mich nicht langfristig an die SwiftShift GmbH als Arbeitgeberin binden möchte.`,
              `Der Arbeitnehmer bevorzugt einsatzbezogen in einem Arbeitsverhältnis mit der SwiftShift GmbH seine Arbeitskraft zur Verfügung stellen will und sich nicht langfristig an die SwiftShift GmbH als Arbeitgeber binden möchte.`],
    "reason5": [
              `da ich vor universitären Klausuren stehe und mich nach Abschluss meiner Arbeitstätigkeit der intensiven Klausurvorbereitung mit meiner vollen Arbeitsleistung widmen will.`,
              `Der Arbeitnehmer vor universitären Klausuren steht und sich nach Abschluss seiner Arbeitstätigkeit der intensiven Klausurvorbereitung mit seiner vollen Arbeitsleistung widmen will.`],
    "reason6": [
              `da ich am Anfang meines Studiums stehe und aufgrund der neuen Lebenssituation noch nicht perspektivisch planen will bzw. kann.`,
              `Der Arbeitnehmer am Anfang seines Studiums steht und aufgrund der neuen Lebenssituation noch nicht perspektivisch planen will bzw. kann.`],
    "reason7": [
              `da ich vor dem Ende meines Studiums stehe und darüber hinaus nicht perspektivisch planen will.`,
              `Der Arbeitnehmer vor dem Ende seines Studiums steht und darüber hinaus nicht perspektivisch planen will.`],
    "reason8": [
              `da ich gegenwärtig einen längeren Urlaub plane und somit nicht länger als bis zu Beginn meiner Abwesenheit disponieren will.`,
              `Der Arbeitnehmer gegenwärtig einen längeren Urlaub plant und somit nicht länger als bis zu Beginn seiner Abwesenheit disponieren will.`],
    "reason9": [
              `da ich aufgrund von persönlichen familiären Umständen begrenzt zeitlich disponieren will.`,
              `Der Arbeitnehmer aufgrund von persönlichen familiären Umständen begrenzt zeitlich disponieren will.`],
    "reason10": [
              `da ich anschließend umziehen werde und mich aufgrund des damit verbundenen Ortswechsels nicht längerfristig durch ein unbefristetes Arbeitsverhältnis an die SwiftShift GmbH als Arbeitgeberin binden möchte.`,
              `Der Arbeitnehmer anschließend umziehen wird und sich aufgrund des damit verbundenen Ortswechsels nicht längerfristig durch ein unbefristetes Arbeitsverhältnis an die SwiftShift GmbH als Arbeitgeber binden möchte.`],
    "customReason": [
            `individueller Befristungsgrund`,
            `individueller Befristungsgrund`],
  };

  nationalitites = [
    "deutsch",
    "afghanisch",
    "ägyptisch",
    "albanisch",
    "algerisch",
    "andorranisch",
    "angolanisch",
    "antiguanisch",
    "äquatorialguineisch",
    "argentinisch",
    "armenisch",
    "aserbaidschanisch",
    "äthiopisch",
    "australisch",
    "bahamaisch",
    "bahrainisch",
    "bangladeschisch",
    "barbadisch",
    "belarussisch (weißrussisch)",
    "belgisch",
    "belizisch",
    "beninisch",
    "bhutanisch",
    "bolivianisch",
    "bosnisch-herzegowinisch",
    "botsuanisch",
    "brasilianisch",
    "britisch",
    "bruneiisch",
    "bulgarisch",
    "burkinisch",
    "burundisch",
    "chilenisch",
    "chinesisch",
    "costa-ricanisch, costaricanisch",
    "ivorisch",
    "dänisch",
    "dominicanisch",
    "dominikanisch",
    "dschibutisch",
    "ecuadorianisch",
    "salvadorianisch",
    "eritreisch",
    "estnisch",
    "fidschianisch",
    "finnisch",
    "französisch",
    "gabunisch",
    "gambisch",
    "georgisch",
    "ghanaisch",
    "grenadisch",
    "griechisch",
    "guatemaltekisch",
    "guineisch",
    "guinea-bissauisch",
    "guyanisch",
    "haitianisch",
    "honduranisch",
    "indisch",
    "indonesisch",
    "irakisch",
    "iranisch",
    "irisch",
    "isländisch",
    "israelisch",
    "italienisch",
    "jamaikanisch",
    "japanisch",
    "jemenitisch",
    "jordanisch",
    "kambodschanisch",
    "kamerunisch",
    "kanadisch",
    "kapverdisch",
    "kasachisch",
    "katarisch",
    "kenianisch",
    "kirgisisch",
    "kiribatisch",
    "kolumbianisch",
    "komorisch",
    "kongolesisch",
    "kosovarisch",
    "kroatisch",
    "kubanisch",
    "kuwaitisch",
    "laotisch",
    "lesothisch",
    "lettisch",
    "libanesisch",
    "liberianisch",
    "libysch",
    "liechtensteinisch",
    "litauisch",
    "luxemburgisch",
    "madagassisch, madegassisch",
    "malawisch",
    "malaysisch",
    "maledivisch",
    "malisch",
    "maltesisch",
    "marokkanisch",
    "marshallisch",
    "mauretanisch",
    "mauritisch",
    "mexikanisch",
    "mikronesisch",
    "moldauisch",
    "monegassisch",
    "mongolisch",
    "montenegrinisch",
    "mosambikanisch",
    "myanmarisch",
    "namibisch",
    "nauruisch",
    "nepalesisch",
    "neuseeländisch",
    "nicaraguanisch",
    "niederländisch",
    "nigrisch",
    "nigerianisch",
    "niueanisch",
    "nordkoreanisch",
    "nordmazedonisch",
    "norwegisch",
    "omanisch",
    "österreichisch",
    "pakistanisch",
    "palauisch",
    "panamaisch",
    "papua-neuguineisch",
    "paraguayisch",
    "peruanisch",
    "philippinisch",
    "polnisch",
    "portugiesisch",
    "ruandisch",
    "rumänisch",
    "russisch",
    "salomonisch",
    "sambisch",
    "samoanisch",
    "san-marinesisch",
    "são-toméisch",
    "saudi-arabisch",
    "schwedisch",
    "schweizerisch",
    "senegalesisch",
    "serbisch",
    "seychellisch",
    "sierra-leonisch",
    "simbabwisch",
    "singapurisch",
    "slowakisch",
    "slowenisch",
    "somalisch",
    "spanisch",
    "sri-lankisch",
    "St. Kitts und Nevis",
    "lucianisch",
    "vincentisch",
    "südafrikanisch",
    "südkoreanisch",
    "südsudanesisch",
    "sudanesisch",
    "surinamisch",
    "swasiländisch",
    "syrisch",
    "tadschikisch",
    "tansanisch",
    "thailändisch",
    "timoresisch,timorisch",
    "togoisch, togolesisch",
    "tongaisch",
    "Trinidad und Tobago",
    "tschadisch",
    "tschechisch",
    "tunesisch",
    "türkisch",
    "turkmenisch",
    "tuvaluisch",
    "ugandisch",
    "ukrainisch",
    "ungarisch",
    "uruguayisch",
    "usbekisch",
    "vanuatuisch",
    "vatikanisch",
    "venezolanisch",
    "Vereinigte Arabische Emirate",
    "US-amerikanisch, traditionell: amerikanisch",
    "vietnamesisch",
    "zentralafrikanisch",
    "zyprisch, zypriotisch",
  ];



  european_country_codes: {[key: string]: string} = {
      "Deutschland": "DE",
      "Albanien": "AL",
      "Andorra": "AD",
      "Belarus": "BY",
      "Belgien": "BE",
      "Bosnien und Herzegowina": "BA",
      "Bulgarien": "BG",
      "Dänemark": "DK",
      "Estland": "EE",
      "Finnland": "FI",
      "Frankreich": "FR",
      "Griechenland": "GR",
      "Irland": "IE",
      "Island": "IS",
      "Italien": "IT",
      "Kroatien": "HR",
      "Lettland": "LV",
      "Litauen": "LT",
      "Luxemburg": "LU",
      "Malta": "MT",
      "Moldau": "MD",
      "Montenegro": "ME",
      "Niederlande": "NL",
      "Norwegen": "NO",
      "Österreich": "AT",
      "Polen": "PL",
      "Portugal": "PT",
      "Rumänien": "RO",
      "Russland": "RU",
      "Schweden": "SE",
      "Schweiz": "CH",
      "Serbien": "RS",
      "Slowakei": "SK",
      "Slowenien": "SI",
      "Spanien": "ES",
      "Tschechien": "CZ",
      "Ukraine": "UA",
      "Ungarn": "HU"
  };

  alllangs = [
    "Afrikaans",
    "Albanisch",
    "Amharisch",
    "Arabisch",
    "Armenisch",
    "Aserbaidschanisch",
    "Bengalisch",
    "Bhutanisch",
    "Birmanisch",
    "Bislama",
    "Bosnisch",
    "Bulgarisch",
    "Chichewa",
    "Chinesisch",
    "Dari",
    "Deutsch",
    "Dänisch",
    "Englisch",
    "Estnisch",
    "Fidschianisch",
    "Filipino (Tagalog)",
    "Finnisch",
    "Französisch",
    "Georgisch",
    "Gilbertesisch",
    "Griechisch",
    "Guaraní",
    "Haitianisch",
    "Hebräisch",
    "Hindi",
    "Indonesisch",
    "Irisch",
    "Isländisch",
    "Italienisch",
    "Japanisch",
    "Kambodschanisch",
    "Kasachisch",
    "Katalanisch",
    "Kirgisisch",
    "Kirundi",
    "Koreanisch",
    "Kroatisch",
    "Kurdisch",
    "Laotisch",
    "Lateinisch",
    "Lettisch",
    "Litauisch",
    "Madagassisch",
    "Malaiisch",
    "Maledivisch",
    "Maltesisch",
    "Maori",
    "Marshallesisch",
    "Mazedonisch",
    "Mongolisch",
    "Montenegrinisch",
    "Nauruisch",
    "Ndebele",
    "Nepalesisch",
    "Niederländisch",
    "Nordsotho",
    "Northern Ireland",
    "Norwegisch",
    "Palauisch",
    "Paschtu",
    "Persisch",
    "Polnisch",
    "Portugiesisch",
    "Republika Srbija",
    "Rumänisch",
    "Russisch",
    "Rätoromanisch",
    "Samoanisch",
    "Sango",
    "Schwedisch",
    "Serbisch",
    "siehe Côte d’Ivoire",
    "Singhalesisch",
    "Slowakisch",
    "Slowenisch",
    "Somali",
    "Sotho",
    "Spanisch",
    "Suaheli",
    "Swasi",
    "Südsotho",
    "Tadschikisch",
    "Tamilisch",
    "Tetum",
    "Thailändisch",
    "Tigrinja",
    "Tok Pisin (Neomelanesisch)",
    "Tongaisch",
    "Tschechisch",
    "Tsonga",
    "Tsuana",
    "Turkmenisch",
    "Tuvalu(isch)",
    "Türkisch",
    "Ukrainisch",
    "Ungarisch",
    "Urdu",
    "Usbekisch",
    "Venda",
    "Vietnamesisch",
    "Weißrussisch",
    "Xhosa",
    "Zulu",
  ];

  european_nationalities = [
    "deutsch",
    "belgisch",
    "bulgarisch",
    "dänisch",
    "estnisch",
    "finnisch",
    "französisch",
    "griechisch",
    "irisch",
    "italienisch",
    "kroatisch",
    "lettisch",
    "litauisch",
    "luxemburgisch",
    "maltesisch",
    "niederländisch",
    "österreichisch",
    "polnisch",
    "portugiesisch",
    "rumänisch",
    "schwedisch",
    "slowakisch",
    "slowenisch",
    "spanisch",
    "tschechisch",
    "ungarisch",
    ];
}